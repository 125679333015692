/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StacksInputData
 */
export interface StacksInputData {
    /**
     * 
     * @type {number}
     * @memberof StacksInputData
     */
    lead?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof StacksInputData
     */
    filterBuyStack?: Array<StacksInputDataFilterBuyStackEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StacksInputData
     */
    filterSellStack?: Array<StacksInputDataFilterSellStackEnum>;
}


/**
 * @export
 */
export const StacksInputDataFilterBuyStackEnum = {
    Biomass: 'BIOMASS',
    Nuclear: 'NUCLEAR',
    Wind: 'WIND',
    Npshyd: 'NPSHYD',
    Ccgt: 'CCGT',
    Coal: 'COAL',
    Ocgt: 'OCGT',
    Ps: 'PS',
    Other: 'OTHER',
    Unknown: 'UNKNOWN',
    Oil: 'OIL',
    Interconnector: 'INTERCONNECTOR',
    Fr: 'FR',
    Ft: 'FT'
} as const;
export type StacksInputDataFilterBuyStackEnum = typeof StacksInputDataFilterBuyStackEnum[keyof typeof StacksInputDataFilterBuyStackEnum];

/**
 * @export
 */
export const StacksInputDataFilterSellStackEnum = {
    Biomass: 'BIOMASS',
    Nuclear: 'NUCLEAR',
    Wind: 'WIND',
    Npshyd: 'NPSHYD',
    Ccgt: 'CCGT',
    Coal: 'COAL',
    Ocgt: 'OCGT',
    Ps: 'PS',
    Other: 'OTHER',
    Unknown: 'UNKNOWN',
    Oil: 'OIL',
    Interconnector: 'INTERCONNECTOR',
    Fr: 'FR',
    Ft: 'FT'
} as const;
export type StacksInputDataFilterSellStackEnum = typeof StacksInputDataFilterSellStackEnum[keyof typeof StacksInputDataFilterSellStackEnum];


/**
 * Check if a given object implements the StacksInputData interface.
 */
export function instanceOfStacksInputData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StacksInputDataFromJSON(json: any): StacksInputData {
    return StacksInputDataFromJSONTyped(json, false);
}

export function StacksInputDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): StacksInputData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lead': !exists(json, 'lead') ? undefined : json['lead'],
        'filterBuyStack': !exists(json, 'filterBuyStack') ? undefined : json['filterBuyStack'],
        'filterSellStack': !exists(json, 'filterSellStack') ? undefined : json['filterSellStack'],
    };
}

export function StacksInputDataToJSON(value?: StacksInputData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lead': value.lead,
        'filterBuyStack': value.filterBuyStack,
        'filterSellStack': value.filterSellStack,
    };
}

