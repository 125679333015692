import { useState, Dispatch, SetStateAction } from "react";
import { useDebouncedValue } from "./useDebouncedValue";

type UseDebouncedIsLoadingOutput = {
	isLoadingDebounced: boolean;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
};

function useDebouncedIsLoading(
  initialLoadingState: boolean = false,
	delay?: number,
): UseDebouncedIsLoadingOutput {
	const [isLoading, setIsLoading] = useState<boolean>(initialLoadingState);

	const isLoadingDebounced = useDebouncedValue(isLoading, delay);

	return {
		isLoadingDebounced,
		setIsLoading,
	};
}

export default useDebouncedIsLoading;
