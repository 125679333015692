import React from "react";
import { Link } from "react-router-dom";
import type { MenuProps } from "antd/es/menu";
import type { DropdownProps } from "antd/es/dropdown";
import {
	ACTIVATIONS_PATH,
	ACTIVATIONS_FORECAST_PATH,
	ACTIVATIONS_PER_BMU_PATH,
	FORWARD_TRADES_PATH,
	FPN_MEL_COMPARISON_PATH,
	FREQUENCY_PATH,
	GENERATION_DEMAND_PATH,
	GENERATION_PER_TYPE_PATH,
	INTERCONNECTORS_PATH,
	NOWCAST_PATH,
	NOWCAST_SUMMARY_PATH,
	TRIPS_UNDERGENERATION_PATH,
	USER_MADE_DEMAND_PATH,
	USER_MADE_WIND_PATH,
	BM_UNIT_INFO_PATH,
	NON_BM_FR_PATH,
	STACKS_FAST_STACKS_PATH,
	STACKS_STACKS_PATH,
	NOTIFICATIONS_PATH,
	SHORT_TERM_FORECAST_PATH,
	PRICE_COMPARISON_PATH,
	WIND_SOLAR_FORECASTS_PATH,
	IMBALANCE_FORECAST_PATH,
	PHANTOM_MODE_PATH,
} from "@/utils/SitePaths";

export const menuItems: MenuProps["items"] = [
	{
		key: "1",
		label: "Activations",
		children: [
			{
				key: "1-1",
				label: <Link to={ACTIVATIONS_PATH}>Activations</Link>,
			},
			{
				key: "1-2",
				label: <Link to={ACTIVATIONS_FORECAST_PATH}>Activations Forecast</Link>,
			},
			{
				key: "1-3",
				label: <Link to={ACTIVATIONS_PER_BMU_PATH}>Activations per BMU</Link>,
			},
		],
	},
	{
		key: "2",
		label: <Link to={FORWARD_TRADES_PATH}>Forward Trades</Link>,
	},
	{
		key: "3",
		label: <Link to={FPN_MEL_COMPARISON_PATH}>FPN/MEL Comparison</Link>,
	},
	{
		key: "4",
		label: <Link to={FREQUENCY_PATH}>Frequency</Link>,
	},
	{
		key: "5",
		label: <Link to={GENERATION_DEMAND_PATH}>Generation / Demand</Link>,
	},
	{
		key: "6",
		label: <Link to={GENERATION_PER_TYPE_PATH}>Generation per Type</Link>,
	},
	{
		key: "7",
		label: <Link to={INTERCONNECTORS_PATH}>Interconnectors</Link>,
	},
	{
		key: "8",
		label: "MIP/RSP Table",
	},
	{
		key: "9",
		label: <Link to={NON_BM_FR_PATH}>Non BM/FR</Link>,
	},
	{
		key: "10",
		label: "Nowcast",
		children: [
			{
				key: "10-1",
				label: <Link to={NOWCAST_PATH}>Nowcast</Link>,
			},
			{
				key: "10-2",
				label: <Link to={NOWCAST_SUMMARY_PATH}>Nowcast Summary</Link>,
			},
		],
	},
	{
		key: "11",
		label: "Positions",
	},
	{
		key: "12",
		label: "Stacks",
		children: [
			{
				key: "12-1",
				label: <Link to={STACKS_FAST_STACKS_PATH}>Fast Stacks</Link>,
			},
			{
				key: "12-2",
				label: <Link to={STACKS_STACKS_PATH}>Stacks</Link>,
			},
		],
	},
	{
		key: "13",
		label: <Link to={TRIPS_UNDERGENERATION_PATH}>Trips / Undergeneration</Link>,
	},
	{
		key: "14",
		label: "User Made Forecasts",
		children: [
			{
				key: "14-1",
				label: <Link to={USER_MADE_DEMAND_PATH}>Demand Forecast Tool</Link>,
			},
			{
				key: "14-2",
				label: <Link to={USER_MADE_WIND_PATH}>Wind Forecast Tool</Link>,
			},
		],
	},
	{
		key: "15",
		label: <Link to={BM_UNIT_INFO_PATH}>BM Unit Info</Link>,
	},
	{
		key: "16",
		label: <Link to={NOTIFICATIONS_PATH}>Notifications</Link>,
	},
	{
		key: "17",
		label: <Link to={SHORT_TERM_FORECAST_PATH}>Short Term Forecast</Link>,
	},
	{
		key: "18",
		label: <Link to={PRICE_COMPARISON_PATH}>Price Comparison</Link>,
	},
	{
		key: "19",
		label: <Link to={WIND_SOLAR_FORECASTS_PATH}>Wind/Solar Forecasts</Link>,
	},
	{
		key: "20",
		label: <Link to={IMBALANCE_FORECAST_PATH}>Imbalance Forecast</Link>,
	},
	{
		key: "21",
		label: <Link to={PHANTOM_MODE_PATH}>Phantom Mode</Link>,
	},
];

export const dropdownProps: DropdownProps = {
	menu: {
		items: menuItems,
		theme: "dark",
	},
	trigger: ["click"],
	overlayStyle: {
		minWidth: "220px",
	},
};
