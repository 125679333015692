/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FpnComparisonData
 */
export interface FpnComparisonData {
    /**
     * 
     * @type {string}
     * @memberof FpnComparisonData
     */
    bmu?: string;
    /**
     * 
     * @type {number}
     * @memberof FpnComparisonData
     */
    oldOffer?: number;
    /**
     * 
     * @type {number}
     * @memberof FpnComparisonData
     */
    newOffer?: number;
    /**
     * 
     * @type {number}
     * @memberof FpnComparisonData
     */
    oldBid?: number;
    /**
     * 
     * @type {number}
     * @memberof FpnComparisonData
     */
    newBid?: number;
    /**
     * 
     * @type {number}
     * @memberof FpnComparisonData
     */
    avgMwChange?: number;
    /**
     * 
     * @type {string}
     * @memberof FpnComparisonData
     */
    coldStart?: string;
    /**
     * 
     * @type {string}
     * @memberof FpnComparisonData
     */
    fuelType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FpnComparisonData
     */
    fpningUp?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FpnComparisonData
     */
    fpningDown?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FpnComparisonData
     */
    bothFpnsLessThenSel?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FpnComparisonData
     */
    noSelForUnit?: boolean;
}

/**
 * Check if a given object implements the FpnComparisonData interface.
 */
export function instanceOfFpnComparisonData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FpnComparisonDataFromJSON(json: any): FpnComparisonData {
    return FpnComparisonDataFromJSONTyped(json, false);
}

export function FpnComparisonDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FpnComparisonData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bmu': !exists(json, 'bmu') ? undefined : json['bmu'],
        'oldOffer': !exists(json, 'oldOffer') ? undefined : json['oldOffer'],
        'newOffer': !exists(json, 'newOffer') ? undefined : json['newOffer'],
        'oldBid': !exists(json, 'oldBid') ? undefined : json['oldBid'],
        'newBid': !exists(json, 'newBid') ? undefined : json['newBid'],
        'avgMwChange': !exists(json, 'avgMwChange') ? undefined : json['avgMwChange'],
        'coldStart': !exists(json, 'coldStart') ? undefined : json['coldStart'],
        'fuelType': !exists(json, 'fuelType') ? undefined : json['fuelType'],
        'fpningUp': !exists(json, 'fpningUp') ? undefined : json['fpningUp'],
        'fpningDown': !exists(json, 'fpningDown') ? undefined : json['fpningDown'],
        'bothFpnsLessThenSel': !exists(json, 'bothFpnsLessThenSel') ? undefined : json['bothFpnsLessThenSel'],
        'noSelForUnit': !exists(json, 'noSelForUnit') ? undefined : json['noSelForUnit'],
    };
}

export function FpnComparisonDataToJSON(value?: FpnComparisonData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bmu': value.bmu,
        'oldOffer': value.oldOffer,
        'newOffer': value.newOffer,
        'oldBid': value.oldBid,
        'newBid': value.newBid,
        'avgMwChange': value.avgMwChange,
        'coldStart': value.coldStart,
        'fuelType': value.fuelType,
        'fpningUp': value.fpningUp,
        'fpningDown': value.fpningDown,
        'bothFpnsLessThenSel': value.bothFpnsLessThenSel,
        'noSelForUnit': value.noSelForUnit,
    };
}

