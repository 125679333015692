import React from "react";
import { NotificationItemStateDataType } from "@/integration/RestClient";
import notification from "antd/es/notification";
import SystemErrorNotification from "@/components/layout/Notification/SystemErrorNotification/SystemErrorNotification";
import {
	ABORTED_REQUEST,
	UNAUTHORIZED_CODE,
	FORBIDDEN_CODE,
	NOT_AUTHORIZED_EVENT,
	EMPTY_STRING,
	ZERO,
} from "../utils/Constants";
import EscapeUtils from "@/utils/EscapeUtils";

class ErrorHandler {
	static isUnAuthorizedStatus = (status: number): boolean => {
		return status === UNAUTHORIZED_CODE || status === FORBIDDEN_CODE;
	};

	static notAuthorized = (): void => {
		window.dispatchEvent(new Event(NOT_AUTHORIZED_EVENT));
	};

	static createGlobalErrorNotification = (message: string, err?: Error): void => {
		notification.error({
			duration: ZERO,
			message: "Error",
			description: <SystemErrorNotification message={message} />,
		});
		console.error({ message, err });
	};

	static getMsgFromEventReason = (event: PromiseRejectionEvent): string => {
		let msg = "Unhandled Rejection Error";
		try {
			if (typeof event.reason === "object") {
				msg = JSON.stringify(
					event.reason?.stack ? event.reason.stack : event.reason?.data,
					null,
					2,
				);
			} else {
				msg = event.reason;
			}
		} catch (e) {}
		return msg;
	};

	public static initErrorsInterceptor = (): void => {
		// NOTE: handle react runtime error popups in dev mode
		window.onerror = null;
		window.onerror = function (msg, url, line, column, err) {
			const message = EscapeUtils.escape(
				ErrorHandler.convertHtmlToText(
					msg +
						"\n\nFile: " +
						url +
						"\n\nLine:" +
						line +
						"\n\nColumn:" +
						column +
						"\n\nStacktrace:" +
						(err && err.stack ? err.stack.toString() : EMPTY_STRING),
				),
			);
			ErrorHandler.createGlobalErrorNotification(message, err);

			return true;
		};
		if ("onunhandledrejection" in window) {
			window.addEventListener("unhandledrejection", function (event) {
				// if it's NOTAUTHORIZED rejection it'll be handled in RestClient so we should not react to it here
				if (
					event.reason === NotificationItemStateDataType.NOTAUTHORIZED ||
					(event.reason?.status && ErrorHandler.isUnAuthorizedStatus(event.reason.status))
				)
					return;
				if (event.reason === ABORTED_REQUEST) {
					event.preventDefault();
					return;
				}
				event.preventDefault();
				const message = ErrorHandler.getMsgFromEventReason(event);
				ErrorHandler.createGlobalErrorNotification(message);
				console.error(event);
			});
		}
	};

	static convertHtmlToText = (text: any): string => {
		if (text == null) return text;
		const bodyContentMatch = /<body[^>]*?>([\s\S]*?)<\/body>/im.exec(text);
		if (bodyContentMatch != null) {
			text = bodyContentMatch[1];
		}
		const jspErrorMessageMatch =
			/<!-- error message start -->\*?([\s\S]*?)\\<!-- error message end -->/gim.exec(text);
		if (jspErrorMessageMatch != null) {
			text = jspErrorMessageMatch[1];
		}
		return text
			.replace(/<style\b[^>]*?>[\s\S]*?<\/style>/gi, "")
			.replace(/<script\b[^>]*?>[\s\S]*?<\/script>/gi, "")
			.replace(/<noscript\b[^>]*?>[\s\S]*?<\/noscript>/gi, "")
			.replace(/<iframe\b[^>]*?>[\s\S]*?<\/iframe>/gi, "")
			.replace(/<object\b[^>]*?>[\s\S]*?<\/object>/gi, "")
			.replace(/<embed\b[^>]*?>[\s\S]*?<\/embed>/gi, "")
			.replace(/<!-{2}[\s\S]*?-{2}>/gi, "")
			.replace(/(<\/?(br|h[1-6]|p|hr|li|dd)\b[^>]*?\/?>)/gi, "\n")
			.replace(/<[^>]+?\/?>/gi, " ")
			.replace(/(^[ \f\t\v​]+|[ \f\t\v​]+$)/gim, "")
			.replace(/\n{2,}/gi, "\n\n");
	};
}

export default ErrorHandler;
