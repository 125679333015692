/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebNotification
 */
export interface WebNotification {
    /**
     * 
     * @type {Date}
     * @memberof WebNotification
     */
    timestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof WebNotification
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof WebNotification
     */
    type?: WebNotificationTypeEnum;
}


/**
 * @export
 */
export const WebNotificationTypeEnum = {
    HighActPrice: 'HIGH_ACT_PRICE',
    LowActPrice: 'LOW_ACT_PRICE',
    HighMainPice: 'HIGH_MAIN_PICE',
    LowMainPrice: 'LOW_MAIN_PRICE',
    NewInstructions: 'NEW_INSTRUCTIONS',
    Trips: 'TRIPS',
    Remit: 'REMIT',
    Fpns: 'FPNS'
} as const;
export type WebNotificationTypeEnum = typeof WebNotificationTypeEnum[keyof typeof WebNotificationTypeEnum];


/**
 * Check if a given object implements the WebNotification interface.
 */
export function instanceOfWebNotification(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebNotificationFromJSON(json: any): WebNotification {
    return WebNotificationFromJSONTyped(json, false);
}

export function WebNotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebNotification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timestamp': !exists(json, 'timestamp') ? undefined : (new Date(json['timestamp'])),
        'message': !exists(json, 'message') ? undefined : json['message'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function WebNotificationToJSON(value?: WebNotification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
        'message': value.message,
        'type': value.type,
    };
}

