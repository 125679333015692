/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebActivationDebugBidLevelRecord } from './WebActivationDebugBidLevelRecord';
import {
    WebActivationDebugBidLevelRecordFromJSON,
    WebActivationDebugBidLevelRecordFromJSONTyped,
    WebActivationDebugBidLevelRecordToJSON,
} from './WebActivationDebugBidLevelRecord';
import type { WebActivationDebugBoalfRecord } from './WebActivationDebugBoalfRecord';
import {
    WebActivationDebugBoalfRecordFromJSON,
    WebActivationDebugBoalfRecordFromJSONTyped,
    WebActivationDebugBoalfRecordToJSON,
} from './WebActivationDebugBoalfRecord';
import type { WebActivationDebugData } from './WebActivationDebugData';
import {
    WebActivationDebugDataFromJSON,
    WebActivationDebugDataFromJSONTyped,
    WebActivationDebugDataToJSON,
} from './WebActivationDebugData';
import type { WebActivationDebugPhysicalNotificationRecord } from './WebActivationDebugPhysicalNotificationRecord';
import {
    WebActivationDebugPhysicalNotificationRecordFromJSON,
    WebActivationDebugPhysicalNotificationRecordFromJSONTyped,
    WebActivationDebugPhysicalNotificationRecordToJSON,
} from './WebActivationDebugPhysicalNotificationRecord';

/**
 * 
 * @export
 * @interface WebActivationsDebugData
 */
export interface WebActivationsDebugData {
    /**
     * 
     * @type {Array<WebActivationDebugPhysicalNotificationRecord>}
     * @memberof WebActivationsDebugData
     */
    pns?: Array<WebActivationDebugPhysicalNotificationRecord>;
    /**
     * 
     * @type {Array<WebActivationDebugBidLevelRecord>}
     * @memberof WebActivationsDebugData
     */
    bids?: Array<WebActivationDebugBidLevelRecord>;
    /**
     * 
     * @type {Array<WebActivationDebugBoalfRecord>}
     * @memberof WebActivationsDebugData
     */
    boalfs?: Array<WebActivationDebugBoalfRecord>;
    /**
     * 
     * @type {Array<WebActivationDebugData>}
     * @memberof WebActivationsDebugData
     */
    activations?: Array<WebActivationDebugData>;
}

/**
 * Check if a given object implements the WebActivationsDebugData interface.
 */
export function instanceOfWebActivationsDebugData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebActivationsDebugDataFromJSON(json: any): WebActivationsDebugData {
    return WebActivationsDebugDataFromJSONTyped(json, false);
}

export function WebActivationsDebugDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebActivationsDebugData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pns': !exists(json, 'pns') ? undefined : ((json['pns'] as Array<any>).map(WebActivationDebugPhysicalNotificationRecordFromJSON)),
        'bids': !exists(json, 'bids') ? undefined : ((json['bids'] as Array<any>).map(WebActivationDebugBidLevelRecordFromJSON)),
        'boalfs': !exists(json, 'boalfs') ? undefined : ((json['boalfs'] as Array<any>).map(WebActivationDebugBoalfRecordFromJSON)),
        'activations': !exists(json, 'activations') ? undefined : ((json['activations'] as Array<any>).map(WebActivationDebugDataFromJSON)),
    };
}

export function WebActivationsDebugDataToJSON(value?: WebActivationsDebugData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pns': value.pns === undefined ? undefined : ((value.pns as Array<any>).map(WebActivationDebugPhysicalNotificationRecordToJSON)),
        'bids': value.bids === undefined ? undefined : ((value.bids as Array<any>).map(WebActivationDebugBidLevelRecordToJSON)),
        'boalfs': value.boalfs === undefined ? undefined : ((value.boalfs as Array<any>).map(WebActivationDebugBoalfRecordToJSON)),
        'activations': value.activations === undefined ? undefined : ((value.activations as Array<any>).map(WebActivationDebugDataToJSON)),
    };
}

