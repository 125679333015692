/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ForceStopInput
 */
export interface ForceStopInput {
    /**
     * 
     * @type {Date}
     * @memberof ForceStopInput
     */
    stopTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof ForceStopInput
     */
    frId?: number;
}

/**
 * Check if a given object implements the ForceStopInput interface.
 */
export function instanceOfForceStopInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ForceStopInputFromJSON(json: any): ForceStopInput {
    return ForceStopInputFromJSONTyped(json, false);
}

export function ForceStopInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForceStopInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stopTime': !exists(json, 'stopTime') ? undefined : (new Date(json['stopTime'])),
        'frId': !exists(json, 'frId') ? undefined : json['frId'],
    };
}

export function ForceStopInputToJSON(value?: ForceStopInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stopTime': value.stopTime === undefined ? undefined : (value.stopTime.toISOString()),
        'frId': value.frId,
    };
}

