/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorMessage,
  WebActivationsForecastOutput,
} from '../models';
import {
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
    WebActivationsForecastOutputFromJSON,
    WebActivationsForecastOutputToJSON,
} from '../models';

export interface ActivationsForecastRestHandlerApiGetActivationsForecastRequest {
    requestedDemand: GetActivationsForecastRequestedDemandEnum;
    requestWind: GetActivationsForecastRequestWindEnum;
    mwPerHz: number;
}

/**
 * 
 */
export class ActivationsForecastRestHandlerApi extends runtime.BaseAPI {

    /**
     */
    async getActivationsForecastRaw(requestParameters: ActivationsForecastRestHandlerApiGetActivationsForecastRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebActivationsForecastOutput>> {
        if (requestParameters.requestedDemand === null || requestParameters.requestedDemand === undefined) {
            throw new runtime.RequiredError('requestedDemand','Required parameter requestParameters.requestedDemand was null or undefined when calling getActivationsForecast.');
        }

        if (requestParameters.requestWind === null || requestParameters.requestWind === undefined) {
            throw new runtime.RequiredError('requestWind','Required parameter requestParameters.requestWind was null or undefined when calling getActivationsForecast.');
        }

        if (requestParameters.mwPerHz === null || requestParameters.mwPerHz === undefined) {
            throw new runtime.RequiredError('mwPerHz','Required parameter requestParameters.mwPerHz was null or undefined when calling getActivationsForecast.');
        }

        const queryParameters: any = {};

        if (requestParameters.requestedDemand !== undefined) {
            queryParameters['requestedDemand'] = requestParameters.requestedDemand;
        }

        if (requestParameters.requestWind !== undefined) {
            queryParameters['requestWind'] = requestParameters.requestWind;
        }

        if (requestParameters.mwPerHz !== undefined) {
            queryParameters['mwPerHz'] = requestParameters.mwPerHz;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/activations-forecast`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebActivationsForecastOutputFromJSON(jsonValue));
    }

    /**
     */
    async getActivationsForecast(requestParameters: ActivationsForecastRestHandlerApiGetActivationsForecastRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebActivationsForecastOutput> {
        const response = await this.getActivationsForecastRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetActivationsForecastRequestedDemandEnum = {
    Tsdf: 'TSDF',
    UserMade: 'USER_MADE',
    TsdfAdj: 'TSDF_ADJ'
} as const;
export type GetActivationsForecastRequestedDemandEnum = typeof GetActivationsForecastRequestedDemandEnum[keyof typeof GetActivationsForecastRequestedDemandEnum];
/**
 * @export
 */
export const GetActivationsForecastRequestWindEnum = {
    UserMade: 'USER_MADE',
    ElexonForecast: 'ELEXON_FORECAST',
    Fpns: 'FPNs'
} as const;
export type GetActivationsForecastRequestWindEnum = typeof GetActivationsForecastRequestWindEnum[keyof typeof GetActivationsForecastRequestWindEnum];
