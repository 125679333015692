import type { RouteProps, PathMatch } from "react-router-dom";
import type { CommonObjectWithAnyData } from "types/common-app-types";

export type MatchType = PathMatch<string> | null;
export type CheckRouteMethodProps = {
	match: MatchType;
	params?: CommonObjectWithAnyData;
};
export type CheckRouteMethodType = (props?: CheckRouteMethodProps) => boolean;

export type AppRouteProps = RouteProps & {
	paramsConverter?: Function;
	path?: string;
};

class RouteUtils {
	static checkRoute = (
		checkRouteMethod: CheckRouteMethodType | CheckRouteMethodType[],
		checkRouteMethodProps?: CheckRouteMethodProps,
	): boolean => {
		if (typeof checkRouteMethod !== "object" && checkRouteMethod(checkRouteMethodProps))
			return true;
		else if (
			typeof checkRouteMethod === "object" &&
			checkRouteMethod.every((cM) => cM(checkRouteMethodProps))
		)
			return true;
		return false;
	};
}

export default RouteUtils;
