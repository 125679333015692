import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import ConfigProvider from "antd/es/config-provider";
import AntdApp from "antd/es/app";
import theme from "antd/es/theme";
import Helmet from "./Helmet/Helmet";
import Layout from "../layout/Layout/Layout";
import { LoginViewRouteProps } from "../Router/RoutesProps";
import LoginProcessor from "./LoginProcessor/LoginProcessor";
import StompClient from "./StompClient/StompClient";
import { AppRoutes } from "../Router/Routes";
import type { AppRouteProps } from "@/utils/RouteUtils";

function App(): JSX.Element {
	return (
		<Helmet>
			<BrowserRouter>
				<LoginProcessor />
				<ConfigProvider
					theme={{
						algorithm: theme.defaultAlgorithm,
						components: {
							Layout: {
								colorBgLayout: "#fff",
								headerColor: "#fff",
							},
						},
					}}
				>
					<AntdApp>
						<StompClient>
							<Routes>
								<Route {...LoginViewRouteProps} />
								<Route element={<Layout />}>
									{AppRoutes.map((route: AppRouteProps, index: number) => {
										return <Route key={index} {...route} />;
									})}
								</Route>
							</Routes>
						</StompClient>
					</AntdApp>
				</ConfigProvider>
			</BrowserRouter>
		</Helmet>
	);
}

export default App;
