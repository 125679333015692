/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HourlyInterconnectorData
 */
export interface HourlyInterconnectorData {
    /**
     * 
     * @type {string}
     * @memberof HourlyInterconnectorData
     */
    startMinute?: string;
    /**
     * 
     * @type {string}
     * @memberof HourlyInterconnectorData
     */
    endMinute?: string;
    /**
     * 
     * @type {number}
     * @memberof HourlyInterconnectorData
     */
    value?: number;
}

/**
 * Check if a given object implements the HourlyInterconnectorData interface.
 */
export function instanceOfHourlyInterconnectorData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HourlyInterconnectorDataFromJSON(json: any): HourlyInterconnectorData {
    return HourlyInterconnectorDataFromJSONTyped(json, false);
}

export function HourlyInterconnectorDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): HourlyInterconnectorData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startMinute': !exists(json, 'startMinute') ? undefined : json['startMinute'],
        'endMinute': !exists(json, 'endMinute') ? undefined : json['endMinute'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function HourlyInterconnectorDataToJSON(value?: HourlyInterconnectorData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startMinute': value.startMinute,
        'endMinute': value.endMinute,
        'value': value.value,
    };
}

