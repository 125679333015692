/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PhantomModeUserConfig
 */
export interface PhantomModeUserConfig {
    /**
     * 
     * @type {boolean}
     * @memberof PhantomModeUserConfig
     */
    upreg?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PhantomModeUserConfig
     */
    type?: PhantomModeUserConfigTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PhantomModeUserConfig
     */
    maxPairNumber?: number;
}


/**
 * @export
 */
export const PhantomModeUserConfigTypeEnum = {
    Biomass: 'BIOMASS',
    Nuclear: 'NUCLEAR',
    Wind: 'WIND',
    Npshyd: 'NPSHYD',
    Ccgt: 'CCGT',
    Coal: 'COAL',
    Ocgt: 'OCGT',
    Ps: 'PS',
    Other: 'OTHER',
    Unknown: 'UNKNOWN',
    Oil: 'OIL',
    All: 'ALL'
} as const;
export type PhantomModeUserConfigTypeEnum = typeof PhantomModeUserConfigTypeEnum[keyof typeof PhantomModeUserConfigTypeEnum];


/**
 * Check if a given object implements the PhantomModeUserConfig interface.
 */
export function instanceOfPhantomModeUserConfig(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PhantomModeUserConfigFromJSON(json: any): PhantomModeUserConfig {
    return PhantomModeUserConfigFromJSONTyped(json, false);
}

export function PhantomModeUserConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhantomModeUserConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'upreg': !exists(json, 'upreg') ? undefined : json['upreg'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'maxPairNumber': !exists(json, 'maxPairNumber') ? undefined : json['maxPairNumber'],
    };
}

export function PhantomModeUserConfigToJSON(value?: PhantomModeUserConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'upreg': value.upreg,
        'type': value.type,
        'maxPairNumber': value.maxPairNumber,
    };
}

