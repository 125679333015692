/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MomentGraphData } from './MomentGraphData';
import {
    MomentGraphDataFromJSON,
    MomentGraphDataFromJSONTyped,
    MomentGraphDataToJSON,
} from './MomentGraphData';

/**
 * 
 * @export
 * @interface WebShortTermFcOutput
 */
export interface WebShortTermFcOutput {
    /**
     * 
     * @type {{ [key: string]: Array<MomentGraphData>; }}
     * @memberof WebShortTermFcOutput
     */
    nivForecast?: { [key: string]: Array<MomentGraphData>; };
    /**
     * 
     * @type {{ [key: string]: Array<MomentGraphData>; }}
     * @memberof WebShortTermFcOutput
     */
    windForecast?: { [key: string]: Array<MomentGraphData>; };
    /**
     * 
     * @type {{ [key: string]: Array<MomentGraphData>; }}
     * @memberof WebShortTermFcOutput
     */
    demandForecast?: { [key: string]: Array<MomentGraphData>; };
    /**
     * 
     * @type {{ [key: string]: Array<MomentGraphData>; }}
     * @memberof WebShortTermFcOutput
     */
    undergenForecast?: { [key: string]: Array<MomentGraphData>; };
    /**
     * 
     * @type {{ [key: string]: Array<MomentGraphData>; }}
     * @memberof WebShortTermFcOutput
     */
    frequencyForecast?: { [key: string]: Array<MomentGraphData>; };
}

/**
 * Check if a given object implements the WebShortTermFcOutput interface.
 */
export function instanceOfWebShortTermFcOutput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebShortTermFcOutputFromJSON(json: any): WebShortTermFcOutput {
    return WebShortTermFcOutputFromJSONTyped(json, false);
}

export function WebShortTermFcOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebShortTermFcOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nivForecast': !exists(json, 'nivForecast') ? undefined : json['nivForecast'],
        'windForecast': !exists(json, 'windForecast') ? undefined : json['windForecast'],
        'demandForecast': !exists(json, 'demandForecast') ? undefined : json['demandForecast'],
        'undergenForecast': !exists(json, 'undergenForecast') ? undefined : json['undergenForecast'],
        'frequencyForecast': !exists(json, 'frequencyForecast') ? undefined : json['frequencyForecast'],
    };
}

export function WebShortTermFcOutputToJSON(value?: WebShortTermFcOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nivForecast': value.nivForecast,
        'windForecast': value.windForecast,
        'demandForecast': value.demandForecast,
        'undergenForecast': value.undergenForecast,
        'frequencyForecast': value.frequencyForecast,
    };
}

