import React, { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import NotFound from "../NotFound/NotFound";
import PageLoadingSkeleton from "../layout/PageLoadingSkeleton/PageLoadingSkeleton";
import ProtectedRoute from "./ProtectedRoute";
import RouteChecker from "./RouteChecker";
import AccessLevelUtils from "@/utils/AccessLevelUtils";
import type { AppRouteProps } from "@/utils/RouteUtils";
import {
	ACTIVATIONS_PATH,
	ACTIVATIONS_PER_BMU_PATH,
	GENERATION_DEMAND_PATH,
	INTERCONNECTORS_PATH,
	NOWCAST_PATH,
	ROOT_PATH,
	TRIPS_UNDERGENERATION_PATH,
	LOGIN_PATH,
	NOWCAST_SUMMARY_PATH,
	GENERATION_PER_TYPE_PATH,
	USER_MADE_DEMAND_PATH,
	ACTIVATIONS_FORECAST_PATH,
	USER_MADE_WIND_PATH,
	BM_UNIT_INFO_PATH,
	NON_BM_FR_PATH,
	FORWARD_TRADES_PATH,
	FPN_MEL_COMPARISON_PATH,
	STACKS_STACKS_PATH,
	NOTIFICATIONS_PATH,
	SHORT_TERM_FORECAST_PATH,
	PRICE_COMPARISON_PATH,
	WIND_SOLAR_FORECASTS_PATH,
	IMBALANCE_FORECAST_PATH,
	PHANTOM_MODE_PATH,
} from "@/utils/SitePaths";

const LoginView = lazy(() => import("../Login/LoginView"));
const ActivationsChartView = lazy(() => import("../charts/ActivationsChart/ActivationsChart"));
const ActivationsForecastChartView = lazy(
	() => import("../charts/ActivationsForecastChart/ActivationsForecastChart"),
);
const ActivationsPerBMUChartView = lazy(
	() => import("../charts/ActivationsPerBMUChart/ActivationsPerBMUChart"),
);
const NowcastChartView = lazy(() => import("../charts/NowcastChart/NowcastChartWithSourceWrapper"));
const NowcastSummaryView = lazy(() => import("../charts/NowcastSummary/NowSumWithFiltersWrapper"));
const TripsUndergenerationChartView = lazy(
	() => import("../charts/TripsUndergenerationChart/TripsUndergChartWithSaveFiltersWrapper"),
);
const InterconnectorsChartView = lazy(
	() => import("../charts/InterconnectorsChart/InterconnectorsChart"),
);
const GenerationDemandChartView = lazy(
	() => import("../charts/GenerationDemandChart/GenerationDemandChart"),
);
const GenerationPerTypeChartView = lazy(
	() => import("@/components/charts/GenerationPerTypeChart/GenerationPerTypeChart"),
);
const UserMadeDemandChartView = lazy(
	() => import("@/components/charts/UserMadeDemandChart/UserMadeDemandChart"),
);
const UserMadeWindChartView = lazy(
	() => import("@/components/charts/UserMadeWindChart/UserMadeWindChart"),
);
const BmUnitInfoChartView = lazy(
	() => import("@/components/charts/BmUnitInfoChart/BmUnitInfoChart"),
);
const NonBmFrView = lazy(() => import("@/components/charts/NonBmFr/NonBmFr"));
const ForwardTradesView = lazy(() => import("@/components/charts/ForwardTrades/ForwardTrades"));
const FpnMelComparisonView = lazy(
	() => import("@/components/charts/FpnMelComparison/FpnMelComparison"),
);
const StacksChartView = lazy(() => import("@/components/charts/StacksChart/StacksChart"));
const NotificationsView = lazy(() => import("@/components/charts/Notifications/Notifications"));
const ShortTermForecastChartView = lazy(
	() => import("@/components/charts/ShortTermForecastChart/ShortTermForecastChart"),
);
const PriceComparisonView = lazy(
	() => import("@/components/charts/PriceComparison/PriceComparison"),
);
const WindNSolarChartView = lazy(
	() => import("@/components/charts/WindNSolarChart/WindNSolarChart"),
);
const ImbalanceForecastChartView = lazy(
	() => import("@/components/charts/ImbalanceForecastChart/ImbalanceForecastChart"),
);
const PhantomModeView = lazy(
	() => import("@/components/charts/PhantomMode/PhantomModeWithSaveWrapper"),
);

/* Nowcast chart is a start page by default */
export const RootPageRouteProps: AppRouteProps = {
	element: <Navigate to={NOWCAST_PATH} replace />,
	path: ROOT_PATH,
};

export const LoginViewRouteProps: AppRouteProps = {
	element: (
		<RouteChecker checkRouteMethod={() => !AccessLevelUtils.isAuthorized()}>
			<Suspense fallback={<PageLoadingSkeleton />}>
				<LoginView />
			</Suspense>
		</RouteChecker>
	),
	path: LOGIN_PATH,
};

export const ActivationsPerBMUChartViewRouteProps: AppRouteProps = {
	element: (
		<ProtectedRoute>
			<Suspense fallback={<PageLoadingSkeleton />}>
				<ActivationsPerBMUChartView />
			</Suspense>
		</ProtectedRoute>
	),
	path: ACTIVATIONS_PER_BMU_PATH,
};

export const ActivationsForecastChartViewRouteProps: AppRouteProps = {
	element: (
		<ProtectedRoute>
			<Suspense fallback={<PageLoadingSkeleton />}>
				<ActivationsForecastChartView />
			</Suspense>
		</ProtectedRoute>
	),
	path: ACTIVATIONS_FORECAST_PATH,
};

export const ActivationsChartViewRouteProps: AppRouteProps = {
	element: (
		<ProtectedRoute>
			<Suspense fallback={<PageLoadingSkeleton />}>
				<ActivationsChartView />
			</Suspense>
		</ProtectedRoute>
	),
	path: ACTIVATIONS_PATH,
};

export const NowcastChartViewRouteProps: AppRouteProps = {
	element: (
		<ProtectedRoute>
			<Suspense fallback={<PageLoadingSkeleton />}>
				<NowcastChartView />
			</Suspense>
		</ProtectedRoute>
	),
	path: NOWCAST_PATH,
};

export const NowcastSummaryViewRouteProps: AppRouteProps = {
	element: (
		<ProtectedRoute>
			<Suspense fallback={<PageLoadingSkeleton />}>
				<NowcastSummaryView />
			</Suspense>
		</ProtectedRoute>
	),
	path: NOWCAST_SUMMARY_PATH,
};

export const TripsUndergenerationChartRouteProps: AppRouteProps = {
	element: (
		<ProtectedRoute>
			<Suspense fallback={<PageLoadingSkeleton />}>
				<TripsUndergenerationChartView />
			</Suspense>
		</ProtectedRoute>
	),
	path: TRIPS_UNDERGENERATION_PATH,
};

export const InterconnectorsChartRouteProps: AppRouteProps = {
	element: (
		<ProtectedRoute>
			<Suspense fallback={<PageLoadingSkeleton />}>
				<InterconnectorsChartView />
			</Suspense>
		</ProtectedRoute>
	),
	path: INTERCONNECTORS_PATH,
};

export const GenerationDemandChartRouteProps: AppRouteProps = {
	element: (
		<ProtectedRoute>
			<Suspense fallback={<PageLoadingSkeleton />}>
				<GenerationDemandChartView />
			</Suspense>
		</ProtectedRoute>
	),
	path: GENERATION_DEMAND_PATH,
};

export const GenerationPerTypeChartRouteProps: AppRouteProps = {
	element: (
		<ProtectedRoute>
			<Suspense fallback={<PageLoadingSkeleton />}>
				<GenerationPerTypeChartView />
			</Suspense>
		</ProtectedRoute>
	),
	path: GENERATION_PER_TYPE_PATH,
};

export const UserMadeDemandChartRouteProps: AppRouteProps = {
	element: (
		<ProtectedRoute>
			<Suspense fallback={<PageLoadingSkeleton />}>
				<UserMadeDemandChartView />
			</Suspense>
		</ProtectedRoute>
	),
	path: USER_MADE_DEMAND_PATH,
};

export const UserMadeWindChartRouteProps: AppRouteProps = {
	element: (
		<ProtectedRoute>
			<Suspense fallback={<PageLoadingSkeleton />}>
				<UserMadeWindChartView />
			</Suspense>
		</ProtectedRoute>
	),
	path: USER_MADE_WIND_PATH,
};

export const BmUnitInfoChartRouteProps: AppRouteProps = {
	element: (
		<ProtectedRoute>
			<Suspense fallback={<PageLoadingSkeleton />}>
				<BmUnitInfoChartView />
			</Suspense>
		</ProtectedRoute>
	),
	path: BM_UNIT_INFO_PATH,
};

export const NonBmFrRouteProps: AppRouteProps = {
	element: (
		<ProtectedRoute>
			<Suspense fallback={<PageLoadingSkeleton />}>
				<NonBmFrView />
			</Suspense>
		</ProtectedRoute>
	),
	path: NON_BM_FR_PATH,
};

export const ForwardTradesProps: AppRouteProps = {
	element: (
		<ProtectedRoute>
			<Suspense fallback={<PageLoadingSkeleton />}>
				<ForwardTradesView />
			</Suspense>
		</ProtectedRoute>
	),
	path: FORWARD_TRADES_PATH,
};

export const FpnMelComparisonProps: AppRouteProps = {
	element: (
		<ProtectedRoute>
			<Suspense fallback={<PageLoadingSkeleton />}>
				<FpnMelComparisonView />
			</Suspense>
		</ProtectedRoute>
	),
	path: FPN_MEL_COMPARISON_PATH,
};

export const StacksChartProps: AppRouteProps = {
	element: (
		<ProtectedRoute>
			<Suspense fallback={<PageLoadingSkeleton />}>
				<StacksChartView />
			</Suspense>
		</ProtectedRoute>
	),
	path: STACKS_STACKS_PATH,
};

export const NotificationsProps: AppRouteProps = {
	element: (
		<ProtectedRoute>
			<Suspense fallback={<PageLoadingSkeleton />}>
				<NotificationsView />
			</Suspense>
		</ProtectedRoute>
	),
	path: NOTIFICATIONS_PATH,
};

export const ShortTermForecastChartProps: AppRouteProps = {
	element: (
		<ProtectedRoute>
			<Suspense fallback={<PageLoadingSkeleton />}>
				<ShortTermForecastChartView />
			</Suspense>
		</ProtectedRoute>
	),
	path: SHORT_TERM_FORECAST_PATH,
};

export const PriceComparisonProps: AppRouteProps = {
	element: (
		<ProtectedRoute>
			<Suspense fallback={<PageLoadingSkeleton />}>
				<PriceComparisonView />
			</Suspense>
		</ProtectedRoute>
	),
	path: PRICE_COMPARISON_PATH,
};

export const WindNSolarChartProps: AppRouteProps = {
	element: (
		<ProtectedRoute>
			<Suspense fallback={<PageLoadingSkeleton />}>
				<WindNSolarChartView />
			</Suspense>
		</ProtectedRoute>
	),
	path: WIND_SOLAR_FORECASTS_PATH,
};

export const ImbalanceForecastChartProps: AppRouteProps = {
	element: (
		<ProtectedRoute>
			<Suspense fallback={<PageLoadingSkeleton />}>
				<ImbalanceForecastChartView />
			</Suspense>
		</ProtectedRoute>
	),
	path: IMBALANCE_FORECAST_PATH,
};

export const PhantomModeProps: AppRouteProps = {
	element: (
		<ProtectedRoute>
			<Suspense fallback={<PageLoadingSkeleton />}>
				<PhantomModeView />
			</Suspense>
		</ProtectedRoute>
	),
	path: PHANTOM_MODE_PATH,
};

export const Route404Props: AppRouteProps = {
	path: "*",
	element: <NotFound />,
};
