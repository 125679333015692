/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PotentialActivation } from './PotentialActivation';
import {
    PotentialActivationFromJSON,
    PotentialActivationFromJSONTyped,
    PotentialActivationToJSON,
} from './PotentialActivation';
import type { StacksOutputGraphData } from './StacksOutputGraphData';
import {
    StacksOutputGraphDataFromJSON,
    StacksOutputGraphDataFromJSONTyped,
    StacksOutputGraphDataToJSON,
} from './StacksOutputGraphData';

/**
 * 
 * @export
 * @interface StacksOutputData
 */
export interface StacksOutputData {
    /**
     * 
     * @type {Array<PotentialActivation>}
     * @memberof StacksOutputData
     */
    upregSeries?: Array<PotentialActivation>;
    /**
     * 
     * @type {Array<PotentialActivation>}
     * @memberof StacksOutputData
     */
    downregSeries?: Array<PotentialActivation>;
    /**
     * 
     * @type {Array<StacksOutputGraphData>}
     * @memberof StacksOutputData
     */
    graphSeries?: Array<StacksOutputGraphData>;
    /**
     * 
     * @type {Array<StacksOutputGraphData>}
     * @memberof StacksOutputData
     */
    confirmedActGraphSeries?: Array<StacksOutputGraphData>;
}

/**
 * Check if a given object implements the StacksOutputData interface.
 */
export function instanceOfStacksOutputData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StacksOutputDataFromJSON(json: any): StacksOutputData {
    return StacksOutputDataFromJSONTyped(json, false);
}

export function StacksOutputDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): StacksOutputData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'upregSeries': !exists(json, 'upregSeries') ? undefined : ((json['upregSeries'] as Array<any>).map(PotentialActivationFromJSON)),
        'downregSeries': !exists(json, 'downregSeries') ? undefined : ((json['downregSeries'] as Array<any>).map(PotentialActivationFromJSON)),
        'graphSeries': !exists(json, 'graphSeries') ? undefined : ((json['graphSeries'] as Array<any>).map(StacksOutputGraphDataFromJSON)),
        'confirmedActGraphSeries': !exists(json, 'confirmedActGraphSeries') ? undefined : ((json['confirmedActGraphSeries'] as Array<any>).map(StacksOutputGraphDataFromJSON)),
    };
}

export function StacksOutputDataToJSON(value?: StacksOutputData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'upregSeries': value.upregSeries === undefined ? undefined : ((value.upregSeries as Array<any>).map(PotentialActivationToJSON)),
        'downregSeries': value.downregSeries === undefined ? undefined : ((value.downregSeries as Array<any>).map(PotentialActivationToJSON)),
        'graphSeries': value.graphSeries === undefined ? undefined : ((value.graphSeries as Array<any>).map(StacksOutputGraphDataToJSON)),
        'confirmedActGraphSeries': value.confirmedActGraphSeries === undefined ? undefined : ((value.confirmedActGraphSeries as Array<any>).map(StacksOutputGraphDataToJSON)),
    };
}

