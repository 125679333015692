/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebPhantomModeBmus
 */
export interface WebPhantomModeBmus {
    /**
     * 
     * @type {Array<string>}
     * @memberof WebPhantomModeBmus
     */
    allBmus?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebPhantomModeBmus
     */
    disabledBmus?: Array<string>;
}

/**
 * Check if a given object implements the WebPhantomModeBmus interface.
 */
export function instanceOfWebPhantomModeBmus(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebPhantomModeBmusFromJSON(json: any): WebPhantomModeBmus {
    return WebPhantomModeBmusFromJSONTyped(json, false);
}

export function WebPhantomModeBmusFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebPhantomModeBmus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allBmus': !exists(json, 'allBmus') ? undefined : json['allBmus'],
        'disabledBmus': !exists(json, 'disabledBmus') ? undefined : json['disabledBmus'],
    };
}

export function WebPhantomModeBmusToJSON(value?: WebPhantomModeBmus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allBmus': value.allBmus,
        'disabledBmus': value.disabledBmus,
    };
}

