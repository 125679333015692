import type { MenuProps } from "antd/es/menu";
import type { DropdownProps } from "antd/es/dropdown";
import { logout } from "@/store/appState";

export const menuItems: MenuProps["items"] = [
	{
		key: "edit",
		label: "Edit Profile",
	},
	{
		key: "logout",
		label: "Logout",
		onClick: logout,
	},
];

export const dropdownProps: DropdownProps = {
	menu: {
		items: menuItems,
		theme: "light",
	},
	trigger: ["click"],
	overlayStyle: {
		minWidth: "220px",
	},
};
