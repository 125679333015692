/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PotentialActivation
 */
export interface PotentialActivation {
    /**
     * 
     * @type {number}
     * @memberof PotentialActivation
     */
    volume?: number;
    /**
     * 
     * @type {number}
     * @memberof PotentialActivation
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof PotentialActivation
     */
    bmu?: string;
    /**
     * 
     * @type {number}
     * @memberof PotentialActivation
     */
    period?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PotentialActivation
     */
    alreadyUsed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PotentialActivation
     */
    fuelType?: PotentialActivationFuelTypeEnum;
}


/**
 * @export
 */
export const PotentialActivationFuelTypeEnum = {
    Biomass: 'BIOMASS',
    Nuclear: 'NUCLEAR',
    Wind: 'WIND',
    Npshyd: 'NPSHYD',
    Ccgt: 'CCGT',
    Coal: 'COAL',
    Ocgt: 'OCGT',
    Ps: 'PS',
    Other: 'OTHER',
    Unknown: 'UNKNOWN',
    Oil: 'OIL',
    Interconnector: 'INTERCONNECTOR',
    Fr: 'FR',
    Ft: 'FT'
} as const;
export type PotentialActivationFuelTypeEnum = typeof PotentialActivationFuelTypeEnum[keyof typeof PotentialActivationFuelTypeEnum];


/**
 * Check if a given object implements the PotentialActivation interface.
 */
export function instanceOfPotentialActivation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PotentialActivationFromJSON(json: any): PotentialActivation {
    return PotentialActivationFromJSONTyped(json, false);
}

export function PotentialActivationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PotentialActivation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'volume': !exists(json, 'volume') ? undefined : json['volume'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'bmu': !exists(json, 'bmu') ? undefined : json['bmu'],
        'period': !exists(json, 'period') ? undefined : json['period'],
        'alreadyUsed': !exists(json, 'alreadyUsed') ? undefined : json['alreadyUsed'],
        'fuelType': !exists(json, 'fuelType') ? undefined : json['fuelType'],
    };
}

export function PotentialActivationToJSON(value?: PotentialActivation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'volume': value.volume,
        'price': value.price,
        'bmu': value.bmu,
        'period': value.period,
        'alreadyUsed': value.alreadyUsed,
        'fuelType': value.fuelType,
    };
}

