import React, { useMemo } from "react";
import { Navigate, useMatch, useLocation, useParams } from "react-router-dom";
import type { DefaultComponentWithChildren } from "types/common-app-types";
import RouteUtils, { CheckRouteMethodType } from "@/utils/RouteUtils";
import { ROOT_PATH } from "@/utils/SitePaths";

type RouteCheckerProps = DefaultComponentWithChildren & {
	checkRouteMethod: CheckRouteMethodType | CheckRouteMethodType[];
	redirectPath?: string;
};

function RouteChecker({
	checkRouteMethod,
	redirectPath = ROOT_PATH,
	children,
}: RouteCheckerProps): JSX.Element {
	const location = useLocation();
	const params = useParams();
	const match = useMatch(location.pathname);

	const canRender = useMemo(() => {
		return RouteUtils.checkRoute(checkRouteMethod, { match, params });
	}, [checkRouteMethod, match, params]);

	if (canRender) return <>{children}</>;

	return <Navigate to={redirectPath} />;
}

export default RouteChecker;
