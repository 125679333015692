import React, { useMemo } from "react";
import { useMatch, useLocation, useParams, Navigate } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import { useAppStore } from "@/store/appState";
import Spin from "antd/es/spin";
import type { DefaultComponentWithChildren } from "types/common-app-types";
import RouteUtils, { CheckRouteMethodType } from "@/utils/RouteUtils";
import { LOGIN_PATH } from "@/utils/SitePaths";

type ProtectedRouteProps = DefaultComponentWithChildren & {
	checkRouteMethod?: CheckRouteMethodType | CheckRouteMethodType[];
	redirectPath?: string;
};

function ProtectedRoute({
	checkRouteMethod,
	redirectPath = LOGIN_PATH,
	children,
}: ProtectedRouteProps): JSX.Element {
	const location = useLocation();
	const params = useParams();
	const match = useMatch(location.pathname);

	const { isLoading, userInfo } = useAppStore(useShallow((store) => store.userData));

	const canRender = useMemo(() => {
		if (userInfo === null) return false;
		if (checkRouteMethod) {
			return RouteUtils.checkRoute(checkRouteMethod, { match, params });
		}
		return true;
	}, [checkRouteMethod, match, params, userInfo]);

	if (isLoading) return <Spin spinning fullscreen />;

	if (canRender) return <>{children}</>;

	return <Navigate to={redirectPath} />;
}

export default ProtectedRoute;
