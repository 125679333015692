import dayjs from "dayjs";
import type { WebHealthCheckData } from "integration-api/models/WebHealthCheckData";
import {
	AlertTypeByTimeDiffConditionType,
	dateDiffFormatter,
	getAlertTypeByTimeDiff,
} from "@/helpers/common/time";
import { DEFAULT_TZ, EMPTY_STRING } from "@/utils/Constants";

export const FETCH_INTERVAL_VALUE = 10000;
export const HEALTH_CHECK_INTERVAL_VALUE = 3000;

const errorCondition: AlertTypeByTimeDiffConditionType = (mins, hours) => {
	return !!(hours || (mins && mins > 15));
};

const warningCondition: AlertTypeByTimeDiffConditionType = (mins) => {
	return !!(mins && mins <= 15 && mins >= 10);
};

const getAlertType = (date?: Date): "success" | "info" | "warning" | "error" => {
	return getAlertTypeByTimeDiff({
		date,
		opts: {
			errorCondition,
			warningCondition,
		},
	});
};

export type GetAlertDataOutput = Record<
	string,
	{ type: "success" | "info" | "warning" | "error"; dateDiff: string }
>;

export const getAlertData = (list: WebHealthCheckData[]): GetAlertDataOutput => {
	return list.reduce<GetAlertDataOutput>(
		(acc, { lastUpdate, name }) => {
			const date = lastUpdate ? dayjs(lastUpdate).tz(DEFAULT_TZ, true).toDate() : undefined;

			if (name) {
				return { ...acc, [name]: { type: getAlertType(date), dateDiff: dateDiffFormatter(date) } };
			}

			return acc;
		},
		{ [EMPTY_STRING]: { type: "error", dateDiff: EMPTY_STRING } },
	);
};
