export const withThrottle = <T extends unknown[]>(
	callback: (...args: T) => void,
	delay: number = 1000,
): ((...args: T) => void) => {
	let timer: ReturnType<typeof setTimeout> | null = null;

	return (...args: T): void => {
		if (timer) {
			return;
		}

		callback(...args);

		timer = setTimeout(() => {
			timer = null;
		}, delay);
	};
};
