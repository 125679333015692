import styles from "./HeaderNavBtn.module.css";
import React, { ButtonHTMLAttributes, MouseEventHandler } from "react";
import classnames from "classnames";
import { EMPTY_STRING } from "@/utils/Constants";

const cx = classnames.bind(styles);

const preventDefault: MouseEventHandler<HTMLButtonElement> = (e) => e.preventDefault();

function HeaderNavBtn({
	onClick = preventDefault,
	className = EMPTY_STRING,
	children,
	...rest
}: ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element {
	return (
		<button type="button" className={cx(styles.btn, className)} onClick={onClick} {...rest}>
			{children}
		</button>
	);
}

export default HeaderNavBtn;
