/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FpnComparisonInput
 */
export interface FpnComparisonInput {
    /**
     * 
     * @type {Date}
     * @memberof FpnComparisonInput
     */
    oldDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof FpnComparisonInput
     */
    oldPeriod?: number;
    /**
     * 
     * @type {Date}
     * @memberof FpnComparisonInput
     */
    newDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof FpnComparisonInput
     */
    newPeriod?: number;
    /**
     * 
     * @type {string}
     * @memberof FpnComparisonInput
     */
    type?: FpnComparisonInputTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof FpnComparisonInput
     */
    threshold?: number;
    /**
     * 
     * @type {number}
     * @memberof FpnComparisonInput
     */
    pair?: number;
}


/**
 * @export
 */
export const FpnComparisonInputTypeEnum = {
    Biomass: 'BIOMASS',
    Nuclear: 'NUCLEAR',
    Wind: 'WIND',
    Npshyd: 'NPSHYD',
    Ccgt: 'CCGT',
    Coal: 'COAL',
    Ocgt: 'OCGT',
    Ps: 'PS',
    Other: 'OTHER',
    Unknown: 'UNKNOWN',
    Undiscovered: 'UNDISCOVERED',
    Oil: 'OIL',
    Interconnector: 'INTERCONNECTOR',
    Intew: 'INTEW',
    Intirl: 'INTIRL',
    Intfr: 'INTFR',
    Intifa2: 'INTIFA2',
    Intelec: 'INTELEC',
    Intned: 'INTNED',
    Intnem: 'INTNEM',
    Intnsl: 'INTNSL',
    Intvkl: 'INTVKL',
    Intirlsum: 'INTIRLSUM'
} as const;
export type FpnComparisonInputTypeEnum = typeof FpnComparisonInputTypeEnum[keyof typeof FpnComparisonInputTypeEnum];


/**
 * Check if a given object implements the FpnComparisonInput interface.
 */
export function instanceOfFpnComparisonInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FpnComparisonInputFromJSON(json: any): FpnComparisonInput {
    return FpnComparisonInputFromJSONTyped(json, false);
}

export function FpnComparisonInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): FpnComparisonInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'oldDate': !exists(json, 'oldDate') ? undefined : (new Date(json['oldDate'])),
        'oldPeriod': !exists(json, 'oldPeriod') ? undefined : json['oldPeriod'],
        'newDate': !exists(json, 'newDate') ? undefined : (new Date(json['newDate'])),
        'newPeriod': !exists(json, 'newPeriod') ? undefined : json['newPeriod'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'threshold': !exists(json, 'threshold') ? undefined : json['threshold'],
        'pair': !exists(json, 'pair') ? undefined : json['pair'],
    };
}

export function FpnComparisonInputToJSON(value?: FpnComparisonInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'oldDate': value.oldDate === undefined ? undefined : (value.oldDate.toISOString().substr(0,10)),
        'oldPeriod': value.oldPeriod,
        'newDate': value.newDate === undefined ? undefined : (value.newDate.toISOString().substr(0,10)),
        'newPeriod': value.newPeriod,
        'type': value.type,
        'threshold': value.threshold,
        'pair': value.pair,
    };
}

