/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MomentGraphData } from './MomentGraphData';
import {
    MomentGraphDataFromJSON,
    MomentGraphDataFromJSONTyped,
    MomentGraphDataToJSON,
} from './MomentGraphData';

/**
 * 
 * @export
 * @interface WebImbalanceForecastData
 */
export interface WebImbalanceForecastData {
    /**
     * 
     * @type {{ [key: string]: Array<MomentGraphData>; }}
     * @memberof WebImbalanceForecastData
     */
    genForecast?: { [key: string]: Array<MomentGraphData>; };
}

/**
 * Check if a given object implements the WebImbalanceForecastData interface.
 */
export function instanceOfWebImbalanceForecastData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebImbalanceForecastDataFromJSON(json: any): WebImbalanceForecastData {
    return WebImbalanceForecastDataFromJSONTyped(json, false);
}

export function WebImbalanceForecastDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebImbalanceForecastData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'genForecast': !exists(json, 'genForecast') ? undefined : json['genForecast'],
    };
}

export function WebImbalanceForecastDataToJSON(value?: WebImbalanceForecastData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'genForecast': value.genForecast,
    };
}

