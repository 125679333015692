import styles from "./HealthCheck.module.css";
import React, { memo, useCallback } from "react";
import Button from "antd/es/button";
import Alert from "antd/es/alert/Alert";
import SpinWrapper from "@/components/layout/Spin/SpinWrapper/SpinWrapper";
import useFetchNUpdateData from "./hooks/useFetchNUpdateData";
import useHealthChartAlert from "./hooks/useHealthChartAlert";
import { EMPTY_STRING } from "@/utils/Constants";

function HealthCheckContent(): JSX.Element {
	const { showErrorMsg, fetchNUpdateData, isLoadingDebounced, rawData } = useFetchNUpdateData();
	const { alertData } = useHealthChartAlert({ rawData });

	const onRefresh = useCallback(() => {
		fetchNUpdateData();
	}, [fetchNUpdateData]);

	return (
		<div className={styles.container}>
			<div className={styles.title}>Health Check</div>
			<SpinWrapper useSpinner spinning={isLoadingDebounced} wrap>
				{showErrorMsg ? (
					<>
						<div className={styles.errorMsg}>
							<Alert message="Error. Please refresh." type="error" showIcon />
						</div>
					</>
				) : (
					<ul className={styles.list}>
						{rawData.map(({ name = EMPTY_STRING }) => {
							const currentAlertData = alertData[name];

							return (
								<li key={name} className={styles.item}>
									<Alert
										className={styles.alert}
										type={currentAlertData?.type}
										description={
											<div className={styles.alertDescription}>
												<div>{name}:</div>
												<div className={styles.lastUpdated}>{currentAlertData?.dateDiff}</div>
											</div>
										}
									/>
								</li>
							);
						})}
					</ul>
				)}
			</SpinWrapper>
			<Button type="primary" onClick={onRefresh}>
				Refresh
			</Button>
		</div>
	);
}

export default memo(HealthCheckContent);
