/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MomentGraphData } from './MomentGraphData';
import {
    MomentGraphDataFromJSON,
    MomentGraphDataFromJSONTyped,
    MomentGraphDataToJSON,
} from './MomentGraphData';

/**
 * 
 * @export
 * @interface WebGenerationPerTypeData
 */
export interface WebGenerationPerTypeData {
    /**
     * 
     * @type {{ [key: string]: Array<MomentGraphData>; }}
     * @memberof WebGenerationPerTypeData
     */
    graphData?: { [key: string]: Array<MomentGraphData>; };
    /**
     * 
     * @type {number}
     * @memberof WebGenerationPerTypeData
     */
    downregCounter?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof WebGenerationPerTypeData
     */
    avgDownregVolume?: { [key: string]: number; };
}

/**
 * Check if a given object implements the WebGenerationPerTypeData interface.
 */
export function instanceOfWebGenerationPerTypeData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebGenerationPerTypeDataFromJSON(json: any): WebGenerationPerTypeData {
    return WebGenerationPerTypeDataFromJSONTyped(json, false);
}

export function WebGenerationPerTypeDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebGenerationPerTypeData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'graphData': !exists(json, 'graphData') ? undefined : json['graphData'],
        'downregCounter': !exists(json, 'downregCounter') ? undefined : json['downregCounter'],
        'avgDownregVolume': !exists(json, 'avgDownregVolume') ? undefined : json['avgDownregVolume'],
    };
}

export function WebGenerationPerTypeDataToJSON(value?: WebGenerationPerTypeData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'graphData': value.graphData,
        'downregCounter': value.downregCounter,
        'avgDownregVolume': value.avgDownregVolume,
    };
}

