import styles from "./Layout.module.css";
import React from "react";
import { Outlet } from "react-router-dom";
import AntdLayout from "antd/es/layout";
import Nav from "../Nav/Nav";

const { Header, Content } = AntdLayout;

function Layout(): JSX.Element {
	return (
		<AntdLayout className={styles.layout}>
			<Header className={styles.header}>
				<Nav />
			</Header>
			<Content className={styles.main}>
				<Outlet />
			</Content>
		</AntdLayout>
	);
}

export default Layout;
