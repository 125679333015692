/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebTripsUndergenAtMinuteData } from './WebTripsUndergenAtMinuteData';
import {
    WebTripsUndergenAtMinuteDataFromJSON,
    WebTripsUndergenAtMinuteDataFromJSONTyped,
    WebTripsUndergenAtMinuteDataToJSON,
} from './WebTripsUndergenAtMinuteData';

/**
 * 
 * @export
 * @interface WebTripsUndergenGroupData
 */
export interface WebTripsUndergenGroupData {
    /**
     * 
     * @type {string}
     * @memberof WebTripsUndergenGroupData
     */
    bmu?: string;
    /**
     * 
     * @type {string}
     * @memberof WebTripsUndergenGroupData
     */
    fuelType?: string;
    /**
     * 
     * @type {Array<WebTripsUndergenAtMinuteData>}
     * @memberof WebTripsUndergenGroupData
     */
    series?: Array<WebTripsUndergenAtMinuteData>;
}

/**
 * Check if a given object implements the WebTripsUndergenGroupData interface.
 */
export function instanceOfWebTripsUndergenGroupData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebTripsUndergenGroupDataFromJSON(json: any): WebTripsUndergenGroupData {
    return WebTripsUndergenGroupDataFromJSONTyped(json, false);
}

export function WebTripsUndergenGroupDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebTripsUndergenGroupData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bmu': !exists(json, 'bmu') ? undefined : json['bmu'],
        'fuelType': !exists(json, 'fuelType') ? undefined : json['fuelType'],
        'series': !exists(json, 'series') ? undefined : ((json['series'] as Array<any>).map(WebTripsUndergenAtMinuteDataFromJSON)),
    };
}

export function WebTripsUndergenGroupDataToJSON(value?: WebTripsUndergenGroupData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bmu': value.bmu,
        'fuelType': value.fuelType,
        'series': value.series === undefined ? undefined : ((value.series as Array<any>).map(WebTripsUndergenAtMinuteDataToJSON)),
    };
}

