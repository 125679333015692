/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebNowcastSummaryData
 */
export interface WebNowcastSummaryData {
    /**
     * 
     * @type {string}
     * @memberof WebNowcastSummaryData
     */
    lastUpdated?: string;
    /**
     * 
     * @type {string}
     * @memberof WebNowcastSummaryData
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof WebNowcastSummaryData
     */
    period?: number;
    /**
     * 
     * @type {number}
     * @memberof WebNowcastSummaryData
     */
    niv?: number;
    /**
     * 
     * @type {string}
     * @memberof WebNowcastSummaryData
     */
    actualNiv?: string;
    /**
     * 
     * @type {number}
     * @memberof WebNowcastSummaryData
     */
    mainPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof WebNowcastSummaryData
     */
    actualPrice?: string;
    /**
     * 
     * @type {string}
     * @memberof WebNowcastSummaryData
     */
    maxPrice?: string;
    /**
     * 
     * @type {string}
     * @memberof WebNowcastSummaryData
     */
    minPrice?: string;
    /**
     * 
     * @type {number}
     * @memberof WebNowcastSummaryData
     */
    mip?: number;
    /**
     * 
     * @type {number}
     * @memberof WebNowcastSummaryData
     */
    maxPotentialLoss?: number;
    /**
     * 
     * @type {number}
     * @memberof WebNowcastSummaryData
     */
    auctionPrice?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WebNowcastSummaryData
     */
    fakeMip?: boolean;
}

/**
 * Check if a given object implements the WebNowcastSummaryData interface.
 */
export function instanceOfWebNowcastSummaryData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebNowcastSummaryDataFromJSON(json: any): WebNowcastSummaryData {
    return WebNowcastSummaryDataFromJSONTyped(json, false);
}

export function WebNowcastSummaryDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebNowcastSummaryData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastUpdated': !exists(json, 'lastUpdated') ? undefined : json['lastUpdated'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'period': !exists(json, 'period') ? undefined : json['period'],
        'niv': !exists(json, 'niv') ? undefined : json['niv'],
        'actualNiv': !exists(json, 'actualNiv') ? undefined : json['actualNiv'],
        'mainPrice': !exists(json, 'mainPrice') ? undefined : json['mainPrice'],
        'actualPrice': !exists(json, 'actualPrice') ? undefined : json['actualPrice'],
        'maxPrice': !exists(json, 'maxPrice') ? undefined : json['maxPrice'],
        'minPrice': !exists(json, 'minPrice') ? undefined : json['minPrice'],
        'mip': !exists(json, 'mip') ? undefined : json['mip'],
        'maxPotentialLoss': !exists(json, 'maxPotentialLoss') ? undefined : json['maxPotentialLoss'],
        'auctionPrice': !exists(json, 'auctionPrice') ? undefined : json['auctionPrice'],
        'fakeMip': !exists(json, 'fakeMip') ? undefined : json['fakeMip'],
    };
}

export function WebNowcastSummaryDataToJSON(value?: WebNowcastSummaryData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastUpdated': value.lastUpdated,
        'date': value.date,
        'period': value.period,
        'niv': value.niv,
        'actualNiv': value.actualNiv,
        'mainPrice': value.mainPrice,
        'actualPrice': value.actualPrice,
        'maxPrice': value.maxPrice,
        'minPrice': value.minPrice,
        'mip': value.mip,
        'maxPotentialLoss': value.maxPotentialLoss,
        'auctionPrice': value.auctionPrice,
        'fakeMip': value.fakeMip,
    };
}

