/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebGraphData } from './WebGraphData';
import {
    WebGraphDataFromJSON,
    WebGraphDataFromJSONTyped,
    WebGraphDataToJSON,
} from './WebGraphData';
import type { WebHealthCheck } from './WebHealthCheck';
import {
    WebHealthCheckFromJSON,
    WebHealthCheckFromJSONTyped,
    WebHealthCheckToJSON,
} from './WebHealthCheck';
import type { WebNowcastHealthCheck } from './WebNowcastHealthCheck';
import {
    WebNowcastHealthCheckFromJSON,
    WebNowcastHealthCheckFromJSONTyped,
    WebNowcastHealthCheckToJSON,
} from './WebNowcastHealthCheck';

/**
 * 
 * @export
 * @interface WebNowcastData
 */
export interface WebNowcastData {
    /**
     * 
     * @type {WebHealthCheck}
     * @memberof WebNowcastData
     */
    elexonHealthCheck?: WebHealthCheck;
    /**
     * 
     * @type {WebHealthCheck}
     * @memberof WebNowcastData
     */
    irisHealthCheck?: WebHealthCheck;
    /**
     * 
     * @type {WebNowcastHealthCheck}
     * @memberof WebNowcastData
     */
    nowcastHealthCheck?: WebNowcastHealthCheck;
    /**
     * 
     * @type {WebGraphData}
     * @memberof WebNowcastData
     */
    graphData?: WebGraphData;
    /**
     * 
     * @type {boolean}
     * @memberof WebNowcastData
     */
    empty?: boolean;
}

/**
 * Check if a given object implements the WebNowcastData interface.
 */
export function instanceOfWebNowcastData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebNowcastDataFromJSON(json: any): WebNowcastData {
    return WebNowcastDataFromJSONTyped(json, false);
}

export function WebNowcastDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebNowcastData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'elexonHealthCheck': !exists(json, 'elexonHealthCheck') ? undefined : WebHealthCheckFromJSON(json['elexonHealthCheck']),
        'irisHealthCheck': !exists(json, 'irisHealthCheck') ? undefined : WebHealthCheckFromJSON(json['irisHealthCheck']),
        'nowcastHealthCheck': !exists(json, 'nowcastHealthCheck') ? undefined : WebNowcastHealthCheckFromJSON(json['nowcastHealthCheck']),
        'graphData': !exists(json, 'graphData') ? undefined : WebGraphDataFromJSON(json['graphData']),
        'empty': !exists(json, 'empty') ? undefined : json['empty'],
    };
}

export function WebNowcastDataToJSON(value?: WebNowcastData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'elexonHealthCheck': WebHealthCheckToJSON(value.elexonHealthCheck),
        'irisHealthCheck': WebHealthCheckToJSON(value.irisHealthCheck),
        'nowcastHealthCheck': WebNowcastHealthCheckToJSON(value.nowcastHealthCheck),
        'graphData': WebGraphDataToJSON(value.graphData),
        'empty': value.empty,
    };
}

