import { createContext } from "react";
import { Client } from "@stomp/stompjs";
import type { StompConfig } from "@stomp/stompjs/esm6/stomp-config";
import notification from "antd/es/notification";
import { withThrottle } from "@/helpers/common/throttle";
import BrowserUtils from "@/utils/BrowserUtils";
// import { ZERO } from "@/utils/Constants";

type StompClientCtxtType = {
	stompClient: Client;
};

export const StompClientCtxt = createContext({} as StompClientCtxtType);

// export const createStompClientInstance = (): Client => {
// 	const stompClient = new Client({
// 		brokerURL: `${BrowserUtils.getWsUrl()}/api/websocket`,
// 		// reconnectDelay: ZERO,
// 	});

// 	stompClient.onConnect = (frame) => {
// 		console.log("Connected: " + frame);
// 	};

// 	stompClient.onWebSocketClose = () => {
// 		console.warn("Socket closed");
// 	};

// 	stompClient.onWebSocketError = (error) => {
// 		const strError = JSON.stringify(error);
// 		console.error("Error with websocket", strError);
// 		notification.error({
// 			message: "Error with websocket: " + strError,
// 		});
// 	};

// 	stompClient.onStompError = (frame) => {
// 		console.error("Broker reported error: " + frame.headers["message"]);
// 		console.error("Additional details: " + frame.body);
// 		notification.error({
// 			message: "Broker reported error: " + frame.headers["message"],
// 			description: "Additional details: " + frame.body,
// 		});
// 	};

// 	stompClient.activate();

// 	return stompClient;
// };

const errorNotificationWithThrottle = withThrottle((strError: string) => {
	console.error("Error with websocket", strError);
	notification.error({
		message: "Error with websocket: " + strError,
	});
}, 5000);

export const stompClientInstanceOptions: StompConfig = {
	brokerURL: `${BrowserUtils.getWsUrl()}/api/websocket`,
	reconnectDelay: 1,

	onConnect: (frame) => {
		const now = new Date().toISOString();
		console.log(`Connected: ${now}\n${frame}`);
	},

	onWebSocketClose: () => {
		const now = new Date().toISOString();
		console.warn(`Socket closed: ${now}`);
	},

	onWebSocketError: (error) => {
		const strError = JSON.stringify(error);
		errorNotificationWithThrottle(strError);
	},

	onStompError: (frame) => {
		console.error("Broker reported error: " + frame.headers["message"]);
		console.error("Additional details: " + frame.body);
		notification.error({
			message: "Broker reported error: " + frame.headers["message"],
			description: "Additional details: " + frame.body,
		});
	},
};
