import { ZERO } from "@/utils/Constants";

class BrowserUtils {
	static getWsUrl = (): string => {
		const defaultPorts = { "http:": 80, "https:": 443 } as {
			[key: string]: number;
		};
		const wsProtocol = { "http:": "ws:", "https:": "wss:" } as {
			[key: string]: string;
		};
		const { location } = window;
		const { protocol, port } = location;
		return (
			wsProtocol[protocol] +
			"//" +
			window.location.hostname +
			(port && `${port}` !== `${defaultPorts[protocol]}` ? ":" + port : "")
		);
	};

	static getVh = (percent: number = 1): number => {
		const h = Math.max(document.documentElement.clientHeight, window.innerHeight || ZERO);
		return (percent * h) / 100;
	};

	static getVw = (percent: number = 1): number => {
		const w = Math.max(document.documentElement.clientWidth, window.innerWidth || ZERO);
		return (percent * w) / 100;
	};
}

export default BrowserUtils;
