import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { LOGIN_PATH } from "@/utils/SitePaths";

type UserInfo = {}; // <- TODO: update !!!
type UserData = {
	isLoading: boolean;
	userInfo: UserInfo | null;
};
export type AppState = {
	userData: UserData;
};

const initialUserData: UserData = {
	isLoading: true,
	userInfo: null,
};

const appStore = (): AppState => ({
	userData: {
		...initialUserData,
	},
});

// Create your store, which includes both state and (optionally) actions
export const useAppStore = create(
	process.env.NODE_ENV === "development" ? devtools(appStore) : appStore,
);

export const setUserDataToStore = (data: Partial<UserData>): void => {
	useAppStore.setState((state: AppState) => ({ userData: { ...state.userData, ...data } }));
};

export const logout = async (): Promise<void> => {
	// await logout();
	useAppStore.setState(() => ({ userData: { isLoading: false, userInfo: null } }));
	window.location.pathname = LOGIN_PATH;
};
