import dayjs, { isDayjs } from "dayjs";
import { DATE_FORMAT, HH_MM_TIME_FORMAT } from "@/utils/Constants";

class Formatter {
	private static FORMAT_SIGN: RegExp = /%s/;

	public static format = (str: string, ...args: string[]): string => {
		let result = str;
		args.forEach((arg: string) => {
			result = result.replace(Formatter.FORMAT_SIGN, arg);
		});
		return result;
	};

	public static formatToDaysJSDate = (obj: any, format?: string): string => {
		if (isDayjs(obj) && "toDate" in obj === false) {
			/*
        This dayjs obj is missing toDate() and format() methods. Suppose it's because of copying it somewhere
        in normalizeFormData(). So to have them back we have to convert
        this 'broken' dayjs obj into valid dayjs object.
      */
			return dayjs(dayjs(obj).toDate()).format(format || DATE_FORMAT);
		}
		return dayjs(obj).format(format || DATE_FORMAT);
	};

	public static formatToDaysJSDateIfObjExists = (obj: any, format?: string): any => {
		if (!obj) return obj;
		return Formatter.formatToDaysJSDate(obj, format);
	};

	public static formatToDaysJSTime = (obj: any, format: string = HH_MM_TIME_FORMAT): string => {
		return Formatter.formatToDaysJSDate(obj, format);
	};

	public static formatToDaysJSTimeIfObjExists = (
		obj: any,
		format: string = HH_MM_TIME_FORMAT,
	): any => {
		if (!obj) return obj;
		return Formatter.formatToDaysJSTime(obj, format);
	};

	public static formatDateISOStrWithoutSeconds = (dateStr: string): string => {
		return dateStr.substr(0, 17) + "00";
	};

	public static formatDateISOStrWithSeconds = (dateStr: string): string => {
		return dateStr.substr(0, 19);
	};

	public static formatDateToISOStrWithoutSeconds = (date: Date): string => {
		return Formatter.formatDateISOStrWithoutSeconds(date.toISOString());
	};
}

export default Formatter;
