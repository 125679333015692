/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorMessage,
  StacksInputData,
  StacksOutputData,
} from '../models';
import {
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
    StacksInputDataFromJSON,
    StacksInputDataToJSON,
    StacksOutputDataFromJSON,
    StacksOutputDataToJSON,
} from '../models';

export interface StacksRestHandlerApiGetStacksRequest {
    stacksInputData: StacksInputData;
}

/**
 * 
 */
export class StacksRestHandlerApi extends runtime.BaseAPI {

    /**
     */
    async getStacksRaw(requestParameters: StacksRestHandlerApiGetStacksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StacksOutputData>> {
        if (requestParameters.stacksInputData === null || requestParameters.stacksInputData === undefined) {
            throw new runtime.RequiredError('stacksInputData','Required parameter requestParameters.stacksInputData was null or undefined when calling getStacks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/stacks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StacksInputDataToJSON(requestParameters.stacksInputData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StacksOutputDataFromJSON(jsonValue));
    }

    /**
     */
    async getStacks(requestParameters: StacksRestHandlerApiGetStacksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StacksOutputData> {
        const response = await this.getStacksRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
