/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MomentGraphData } from './MomentGraphData';
import {
    MomentGraphDataFromJSON,
    MomentGraphDataFromJSONTyped,
    MomentGraphDataToJSON,
} from './MomentGraphData';

/**
 * 
 * @export
 * @interface WebGenerationDemandData
 */
export interface WebGenerationDemandData {
    /**
     * 
     * @type {{ [key: string]: Array<MomentGraphData>; }}
     * @memberof WebGenerationDemandData
     */
    mainGraph?: { [key: string]: Array<MomentGraphData>; };
    /**
     * 
     * @type {{ [key: string]: Array<MomentGraphData>; }}
     * @memberof WebGenerationDemandData
     */
    demandGraph?: { [key: string]: Array<MomentGraphData>; };
}

/**
 * Check if a given object implements the WebGenerationDemandData interface.
 */
export function instanceOfWebGenerationDemandData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebGenerationDemandDataFromJSON(json: any): WebGenerationDemandData {
    return WebGenerationDemandDataFromJSONTyped(json, false);
}

export function WebGenerationDemandDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebGenerationDemandData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mainGraph': !exists(json, 'mainGraph') ? undefined : json['mainGraph'],
        'demandGraph': !exists(json, 'demandGraph') ? undefined : json['demandGraph'],
    };
}

export function WebGenerationDemandDataToJSON(value?: WebGenerationDemandData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mainGraph': value.mainGraph,
        'demandGraph': value.demandGraph,
    };
}

