import { EMPTY_STRING } from "./Constants";

class EscapeUtils {
	static htmlEscapes: { [key: string]: string } = {
		"&": "&amp;",
		"<": "&lt;",
		">": "&gt;",
		'"': "&quot;",
		"'": "&#39;",
	};

	static reUnescapedHtml = /[&<>"']/g;
	static reHasUnescapedHtml = RegExp(EscapeUtils.reUnescapedHtml.source);

	static escape = (string: string): string => {
		return string && EscapeUtils.reHasUnescapedHtml.test(string)
			? string.replace(EscapeUtils.reUnescapedHtml, (chr) => EscapeUtils.htmlEscapes[chr])
			: string || EMPTY_STRING;
	};
}

export default EscapeUtils;
