/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorMessage,
  WebInputWindLines,
  WebUserMadeWindOutput,
} from '../models';
import {
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
    WebInputWindLinesFromJSON,
    WebInputWindLinesToJSON,
    WebUserMadeWindOutputFromJSON,
    WebUserMadeWindOutputToJSON,
} from '../models';

export interface UserMadeWindRestHandlerApiAcceptUserMadeWindRequest {
    webInputWindLines: WebInputWindLines;
}

export interface UserMadeWindRestHandlerApiGetBaseWindRequest {
    lag: number;
    lead: number;
}

/**
 * 
 */
export class UserMadeWindRestHandlerApi extends runtime.BaseAPI {

    /**
     */
    async acceptUserMadeWindRaw(requestParameters: UserMadeWindRestHandlerApiAcceptUserMadeWindRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.webInputWindLines === null || requestParameters.webInputWindLines === undefined) {
            throw new runtime.RequiredError('webInputWindLines','Required parameter requestParameters.webInputWindLines was null or undefined when calling acceptUserMadeWind.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user-made-wind/graph`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebInputWindLinesToJSON(requestParameters.webInputWindLines),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async acceptUserMadeWind(requestParameters: UserMadeWindRestHandlerApiAcceptUserMadeWindRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acceptUserMadeWindRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteUserMadeWindRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user-made-wind/graph`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUserMadeWind(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserMadeWindRaw(initOverrides);
    }

    /**
     */
    async getBaseWindRaw(requestParameters: UserMadeWindRestHandlerApiGetBaseWindRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebUserMadeWindOutput>> {
        if (requestParameters.lag === null || requestParameters.lag === undefined) {
            throw new runtime.RequiredError('lag','Required parameter requestParameters.lag was null or undefined when calling getBaseWind.');
        }

        if (requestParameters.lead === null || requestParameters.lead === undefined) {
            throw new runtime.RequiredError('lead','Required parameter requestParameters.lead was null or undefined when calling getBaseWind.');
        }

        const queryParameters: any = {};

        if (requestParameters.lag !== undefined) {
            queryParameters['lag'] = requestParameters.lag;
        }

        if (requestParameters.lead !== undefined) {
            queryParameters['lead'] = requestParameters.lead;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user-made-wind`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebUserMadeWindOutputFromJSON(jsonValue));
    }

    /**
     */
    async getBaseWind(requestParameters: UserMadeWindRestHandlerApiGetBaseWindRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebUserMadeWindOutput> {
        const response = await this.getBaseWindRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
