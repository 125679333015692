import React, { useLayoutEffect } from "react";
import { useStompClient } from "react-stomp-hooks";
import { useShallow } from "zustand/react/shallow";
import { useAppStore } from "@/store/appState";
import type { DefaultComponentWithChildren } from "types/common-app-types";
import AccessLevelUtils from "@/utils/AccessLevelUtils";

// TODO: update check for auth !!!

function StompClientAuthHandler({ children }: DefaultComponentWithChildren): JSX.Element {
	const isAuthorized = useAppStore(useShallow((store) => AccessLevelUtils.isAuthorized(store)));

	const client = useStompClient();

	useLayoutEffect(() => {
		if (client) {
			if (isAuthorized && !client.active) {
				client.activate();
			}
			if (!isAuthorized && client.active) {
				client.deactivate();
			}
		}
	}, [isAuthorized, client]);

	return <>{children}</>;
}

export default StompClientAuthHandler;
