import React from "react";
import { StompSessionProvider } from "react-stomp-hooks";
import {
	// StompClientCtxt,
	// createStompClientInstance,
	stompClientInstanceOptions,
} from "./StompClientHelpers";
import StompClientAuthHandler from "./StompClientAuthHandler";
import type { DefaultComponentWithChildren } from "types/common-app-types";

function StompClient({ children }: DefaultComponentWithChildren): JSX.Element {
	// const [stompClient] = useState(() => createStompClientInstance());
	// const [stompClient] = useState(() => createStompClientInstance());

	// const ctxtValue = useMemo(() => ({ stompClient }), [stompClient]);

	// return <StompClientCtxt.Provider value={ctxtValue}>{children}</StompClientCtxt.Provider>;
	return (
		<StompSessionProvider
			url={stompClientInstanceOptions.brokerURL as string}
			{...stompClientInstanceOptions}
			//All options supported by @stomp/stompjs can be used here
		>
			<StompClientAuthHandler>{children}</StompClientAuthHandler>
		</StompSessionProvider>
	);
}

export default StompClient;
