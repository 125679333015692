import { useEffect, useState } from "react";
import type { WebHealthCheckData } from "integration-api/models/WebHealthCheckData";
import { getAlertData, GetAlertDataOutput, HEALTH_CHECK_INTERVAL_VALUE } from "../helpers/utils";

interface useHealthChartAlertProps {
	rawData: WebHealthCheckData[];
}

type UseHealthChartAlertOutput = {
	alertData: GetAlertDataOutput;
};

const useHealthChartAlert = ({ rawData }: useHealthChartAlertProps): UseHealthChartAlertOutput => {
	const [alertData, setAlertData] = useState(() => getAlertData(rawData));

	useEffect(() => {
		setAlertData(() => getAlertData(rawData));

		const intervalId = setInterval(() => {
			setAlertData(() => getAlertData(rawData));
		}, HEALTH_CHECK_INTERVAL_VALUE);

		return () => {
			clearInterval(intervalId);
		};
	}, [rawData]);

	return {
		alertData,
	};
};

export default useHealthChartAlert;
