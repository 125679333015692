import styles from "./UserMenu.module.css";
import React from "react";
import Avatar from "antd/es/avatar";
import Dropdown from "antd/es/dropdown";
import UserOutlined from "@ant-design/icons/UserOutlined";
import { dropdownProps } from "./UserMenuHelpers";

function UserMenu(): JSX.Element {
	return (
		<Dropdown {...dropdownProps}>
			<Avatar icon={<UserOutlined />} className={styles.avatar} />
		</Dropdown>
	);
}

export default UserMenu;
