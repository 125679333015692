/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebNowcastActivationData
 */
export interface WebNowcastActivationData {
    /**
     * 
     * @type {Date}
     * @memberof WebNowcastActivationData
     */
    time?: Date;
    /**
     * 
     * @type {Date}
     * @memberof WebNowcastActivationData
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof WebNowcastActivationData
     */
    endTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof WebNowcastActivationData
     */
    unit?: string;
    /**
     * 
     * @type {number}
     * @memberof WebNowcastActivationData
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof WebNowcastActivationData
     */
    pair?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WebNowcastActivationData
     */
    cadl?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WebNowcastActivationData
     */
    so?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WebNowcastActivationData
     */
    stor?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WebNowcastActivationData
     */
    price?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WebNowcastActivationData
     */
    unpriced?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WebNowcastActivationData
     */
    volume?: number;
    /**
     * 
     * @type {number}
     * @memberof WebNowcastActivationData
     */
    dmatAdj?: number;
    /**
     * 
     * @type {number}
     * @memberof WebNowcastActivationData
     */
    arbAdj?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WebNowcastActivationData
     */
    extended?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WebNowcastActivationData
     */
    phantom?: boolean;
}

/**
 * Check if a given object implements the WebNowcastActivationData interface.
 */
export function instanceOfWebNowcastActivationData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebNowcastActivationDataFromJSON(json: any): WebNowcastActivationData {
    return WebNowcastActivationDataFromJSONTyped(json, false);
}

export function WebNowcastActivationDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebNowcastActivationData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'time': !exists(json, 'time') ? undefined : (new Date(json['time'])),
        'startTime': !exists(json, 'startTime') ? undefined : (new Date(json['startTime'])),
        'endTime': !exists(json, 'endTime') ? undefined : (new Date(json['endTime'])),
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'pair': !exists(json, 'pair') ? undefined : json['pair'],
        'cadl': !exists(json, 'cadl') ? undefined : json['cadl'],
        'so': !exists(json, 'so') ? undefined : json['so'],
        'stor': !exists(json, 'stor') ? undefined : json['stor'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'unpriced': !exists(json, 'unpriced') ? undefined : json['unpriced'],
        'volume': !exists(json, 'volume') ? undefined : json['volume'],
        'dmatAdj': !exists(json, 'dmatAdj') ? undefined : json['dmatAdj'],
        'arbAdj': !exists(json, 'arbAdj') ? undefined : json['arbAdj'],
        'extended': !exists(json, 'extended') ? undefined : json['extended'],
        'phantom': !exists(json, 'phantom') ? undefined : json['phantom'],
    };
}

export function WebNowcastActivationDataToJSON(value?: WebNowcastActivationData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'time': value.time === undefined ? undefined : (value.time.toISOString()),
        'startTime': value.startTime === undefined ? undefined : (value.startTime.toISOString()),
        'endTime': value.endTime === undefined ? undefined : (value.endTime.toISOString()),
        'unit': value.unit,
        'id': value.id,
        'pair': value.pair,
        'cadl': value.cadl,
        'so': value.so,
        'stor': value.stor,
        'price': value.price,
        'unpriced': value.unpriced,
        'volume': value.volume,
        'dmatAdj': value.dmatAdj,
        'arbAdj': value.arbAdj,
        'extended': value.extended,
        'phantom': value.phantom,
    };
}

