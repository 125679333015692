/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebActivationDebugPhysicalNotificationRecord
 */
export interface WebActivationDebugPhysicalNotificationRecord {
    /**
     * 
     * @type {string}
     * @memberof WebActivationDebugPhysicalNotificationRecord
     */
    fromTime?: string;
    /**
     * 
     * @type {string}
     * @memberof WebActivationDebugPhysicalNotificationRecord
     */
    toTime?: string;
    /**
     * 
     * @type {number}
     * @memberof WebActivationDebugPhysicalNotificationRecord
     */
    levelFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof WebActivationDebugPhysicalNotificationRecord
     */
    levelTo?: number;
    /**
     * 
     * @type {number}
     * @memberof WebActivationDebugPhysicalNotificationRecord
     */
    currentLevel?: number;
}

/**
 * Check if a given object implements the WebActivationDebugPhysicalNotificationRecord interface.
 */
export function instanceOfWebActivationDebugPhysicalNotificationRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebActivationDebugPhysicalNotificationRecordFromJSON(json: any): WebActivationDebugPhysicalNotificationRecord {
    return WebActivationDebugPhysicalNotificationRecordFromJSONTyped(json, false);
}

export function WebActivationDebugPhysicalNotificationRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebActivationDebugPhysicalNotificationRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fromTime': !exists(json, 'fromTime') ? undefined : json['fromTime'],
        'toTime': !exists(json, 'toTime') ? undefined : json['toTime'],
        'levelFrom': !exists(json, 'levelFrom') ? undefined : json['levelFrom'],
        'levelTo': !exists(json, 'levelTo') ? undefined : json['levelTo'],
        'currentLevel': !exists(json, 'currentLevel') ? undefined : json['currentLevel'],
    };
}

export function WebActivationDebugPhysicalNotificationRecordToJSON(value?: WebActivationDebugPhysicalNotificationRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fromTime': value.fromTime,
        'toTime': value.toTime,
        'levelFrom': value.levelFrom,
        'levelTo': value.levelTo,
        'currentLevel': value.currentLevel,
    };
}

