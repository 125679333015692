/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebPriceComparisonUnit } from './WebPriceComparisonUnit';
import {
    WebPriceComparisonUnitFromJSON,
    WebPriceComparisonUnitFromJSONTyped,
    WebPriceComparisonUnitToJSON,
} from './WebPriceComparisonUnit';

/**
 * 
 * @export
 * @interface WebPriceComparisonData
 */
export interface WebPriceComparisonData {
    /**
     * 
     * @type {Array<WebPriceComparisonUnit>}
     * @memberof WebPriceComparisonData
     */
    upreg?: Array<WebPriceComparisonUnit>;
    /**
     * 
     * @type {Array<WebPriceComparisonUnit>}
     * @memberof WebPriceComparisonData
     */
    downreg?: Array<WebPriceComparisonUnit>;
}

/**
 * Check if a given object implements the WebPriceComparisonData interface.
 */
export function instanceOfWebPriceComparisonData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebPriceComparisonDataFromJSON(json: any): WebPriceComparisonData {
    return WebPriceComparisonDataFromJSONTyped(json, false);
}

export function WebPriceComparisonDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebPriceComparisonData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'upreg': !exists(json, 'upreg') ? undefined : ((json['upreg'] as Array<any>).map(WebPriceComparisonUnitFromJSON)),
        'downreg': !exists(json, 'downreg') ? undefined : ((json['downreg'] as Array<any>).map(WebPriceComparisonUnitFromJSON)),
    };
}

export function WebPriceComparisonDataToJSON(value?: WebPriceComparisonData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'upreg': value.upreg === undefined ? undefined : ((value.upreg as Array<any>).map(WebPriceComparisonUnitToJSON)),
        'downreg': value.downreg === undefined ? undefined : ((value.downreg as Array<any>).map(WebPriceComparisonUnitToJSON)),
    };
}

