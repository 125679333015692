/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BmuPeriodPairsData } from './BmuPeriodPairsData';
import {
    BmuPeriodPairsDataFromJSON,
    BmuPeriodPairsDataFromJSONTyped,
    BmuPeriodPairsDataToJSON,
} from './BmuPeriodPairsData';
import type { HeaderData } from './HeaderData';
import {
    HeaderDataFromJSON,
    HeaderDataFromJSONTyped,
    HeaderDataToJSON,
} from './HeaderData';
import type { MomentGraphData } from './MomentGraphData';
import {
    MomentGraphDataFromJSON,
    MomentGraphDataFromJSONTyped,
    MomentGraphDataToJSON,
} from './MomentGraphData';

/**
 * 
 * @export
 * @interface WebBmuInfoOutput
 */
export interface WebBmuInfoOutput {
    /**
     * 
     * @type {HeaderData}
     * @memberof WebBmuInfoOutput
     */
    headerData?: HeaderData;
    /**
     * 
     * @type {Array<BmuPeriodPairsData>}
     * @memberof WebBmuInfoOutput
     */
    priceData?: Array<BmuPeriodPairsData>;
    /**
     * 
     * @type {{ [key: string]: Array<MomentGraphData>; }}
     * @memberof WebBmuInfoOutput
     */
    graphData?: { [key: string]: Array<MomentGraphData>; };
}

/**
 * Check if a given object implements the WebBmuInfoOutput interface.
 */
export function instanceOfWebBmuInfoOutput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebBmuInfoOutputFromJSON(json: any): WebBmuInfoOutput {
    return WebBmuInfoOutputFromJSONTyped(json, false);
}

export function WebBmuInfoOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebBmuInfoOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'headerData': !exists(json, 'headerData') ? undefined : HeaderDataFromJSON(json['headerData']),
        'priceData': !exists(json, 'priceData') ? undefined : ((json['priceData'] as Array<any>).map(BmuPeriodPairsDataFromJSON)),
        'graphData': !exists(json, 'graphData') ? undefined : json['graphData'],
    };
}

export function WebBmuInfoOutputToJSON(value?: WebBmuInfoOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'headerData': HeaderDataToJSON(value.headerData),
        'priceData': value.priceData === undefined ? undefined : ((value.priceData as Array<any>).map(BmuPeriodPairsDataToJSON)),
        'graphData': value.graphData,
    };
}

