/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HeaderData
 */
export interface HeaderData {
    /**
     * 
     * @type {string}
     * @memberof HeaderData
     */
    party?: string;
    /**
     * 
     * @type {string}
     * @memberof HeaderData
     */
    fuelType?: string;
    /**
     * 
     * @type {string}
     * @memberof HeaderData
     */
    zone?: string;
    /**
     * 
     * @type {string}
     * @memberof HeaderData
     */
    validTime?: string;
    /**
     * 
     * @type {string}
     * @memberof HeaderData
     */
    mzt?: string;
    /**
     * 
     * @type {string}
     * @memberof HeaderData
     */
    mnzt?: string;
    /**
     * 
     * @type {string}
     * @memberof HeaderData
     */
    ndz?: string;
    /**
     * 
     * @type {string}
     * @memberof HeaderData
     */
    mustRunUntil?: string;
    /**
     * 
     * @type {string}
     * @memberof HeaderData
     */
    name?: string;
}

/**
 * Check if a given object implements the HeaderData interface.
 */
export function instanceOfHeaderData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HeaderDataFromJSON(json: any): HeaderData {
    return HeaderDataFromJSONTyped(json, false);
}

export function HeaderDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): HeaderData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'party': !exists(json, 'party') ? undefined : json['party'],
        'fuelType': !exists(json, 'fuelType') ? undefined : json['fuelType'],
        'zone': !exists(json, 'zone') ? undefined : json['zone'],
        'validTime': !exists(json, 'validTime') ? undefined : json['validTime'],
        'mzt': !exists(json, 'mzt') ? undefined : json['mzt'],
        'mnzt': !exists(json, 'mnzt') ? undefined : json['mnzt'],
        'ndz': !exists(json, 'ndz') ? undefined : json['ndz'],
        'mustRunUntil': !exists(json, 'mustRunUntil') ? undefined : json['mustRunUntil'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function HeaderDataToJSON(value?: HeaderData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'party': value.party,
        'fuelType': value.fuelType,
        'zone': value.zone,
        'validTime': value.validTime,
        'mzt': value.mzt,
        'mnzt': value.mnzt,
        'ndz': value.ndz,
        'mustRunUntil': value.mustRunUntil,
        'name': value.name,
    };
}

