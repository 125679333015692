/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FastReserveInstructionsData
 */
export interface FastReserveInstructionsData {
    /**
     * 
     * @type {number}
     * @memberof FastReserveInstructionsData
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FastReserveInstructionsData
     */
    serviceType?: string;
    /**
     * 
     * @type {string}
     * @memberof FastReserveInstructionsData
     */
    startSent?: string;
    /**
     * 
     * @type {string}
     * @memberof FastReserveInstructionsData
     */
    rumpUpBegins?: string;
    /**
     * 
     * @type {string}
     * @memberof FastReserveInstructionsData
     */
    stopSent?: string;
    /**
     * 
     * @type {string}
     * @memberof FastReserveInstructionsData
     */
    rumpDownEnds?: string;
    /**
     * 
     * @type {number}
     * @memberof FastReserveInstructionsData
     */
    ngPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof FastReserveInstructionsData
     */
    elexonPrice?: string;
    /**
     * 
     * @type {number}
     * @memberof FastReserveInstructionsData
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof FastReserveInstructionsData
     */
    mw?: number;
}

/**
 * Check if a given object implements the FastReserveInstructionsData interface.
 */
export function instanceOfFastReserveInstructionsData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FastReserveInstructionsDataFromJSON(json: any): FastReserveInstructionsData {
    return FastReserveInstructionsDataFromJSONTyped(json, false);
}

export function FastReserveInstructionsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FastReserveInstructionsData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'serviceType': !exists(json, 'serviceType') ? undefined : json['serviceType'],
        'startSent': !exists(json, 'startSent') ? undefined : json['startSent'],
        'rumpUpBegins': !exists(json, 'rumpUpBegins') ? undefined : json['rumpUpBegins'],
        'stopSent': !exists(json, 'stopSent') ? undefined : json['stopSent'],
        'rumpDownEnds': !exists(json, 'rumpDownEnds') ? undefined : json['rumpDownEnds'],
        'ngPrice': !exists(json, 'ngPrice') ? undefined : json['ngPrice'],
        'elexonPrice': !exists(json, 'elexonPrice') ? undefined : json['elexonPrice'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'mw': !exists(json, 'mw') ? undefined : json['mw'],
    };
}

export function FastReserveInstructionsDataToJSON(value?: FastReserveInstructionsData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'serviceType': value.serviceType,
        'startSent': value.startSent,
        'rumpUpBegins': value.rumpUpBegins,
        'stopSent': value.stopSent,
        'rumpDownEnds': value.rumpDownEnds,
        'ngPrice': value.ngPrice,
        'elexonPrice': value.elexonPrice,
        'price': value.price,
        'mw': value.mw,
    };
}

