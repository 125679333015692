/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorMessage,
  WebActsVolumeComparisonData,
  WebNowcastData,
} from '../models';
import {
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
    WebActsVolumeComparisonDataFromJSON,
    WebActsVolumeComparisonDataToJSON,
    WebNowcastDataFromJSON,
    WebNowcastDataToJSON,
} from '../models';

export interface NowcastRestHandlerApiGetNowcastRequest {
    settlementPeriod: number;
    priceChangeTreshold: number;
    extendedMode?: boolean;
    phantomMode?: boolean;
}

/**
 * 
 */
export class NowcastRestHandlerApi extends runtime.BaseAPI {

    /**
     */
    async compareSourcesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebActsVolumeComparisonData>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nowcast/comparison`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebActsVolumeComparisonDataFromJSON(jsonValue));
    }

    /**
     */
    async compareSources(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebActsVolumeComparisonData> {
        const response = await this.compareSourcesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getNowcastRaw(requestParameters: NowcastRestHandlerApiGetNowcastRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebNowcastData>> {
        if (requestParameters.settlementPeriod === null || requestParameters.settlementPeriod === undefined) {
            throw new runtime.RequiredError('settlementPeriod','Required parameter requestParameters.settlementPeriod was null or undefined when calling getNowcast.');
        }

        if (requestParameters.priceChangeTreshold === null || requestParameters.priceChangeTreshold === undefined) {
            throw new runtime.RequiredError('priceChangeTreshold','Required parameter requestParameters.priceChangeTreshold was null or undefined when calling getNowcast.');
        }

        const queryParameters: any = {};

        if (requestParameters.settlementPeriod !== undefined) {
            queryParameters['settlementPeriod'] = requestParameters.settlementPeriod;
        }

        if (requestParameters.priceChangeTreshold !== undefined) {
            queryParameters['priceChangeTreshold'] = requestParameters.priceChangeTreshold;
        }

        if (requestParameters.extendedMode !== undefined) {
            queryParameters['extendedMode'] = requestParameters.extendedMode;
        }

        if (requestParameters.phantomMode !== undefined) {
            queryParameters['phantomMode'] = requestParameters.phantomMode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/nowcast`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebNowcastDataFromJSON(jsonValue));
    }

    /**
     */
    async getNowcast(requestParameters: NowcastRestHandlerApiGetNowcastRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebNowcastData> {
        const response = await this.getNowcastRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
