/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebGraphHeaderData } from './WebGraphHeaderData';
import {
    WebGraphHeaderDataFromJSON,
    WebGraphHeaderDataFromJSONTyped,
    WebGraphHeaderDataToJSON,
} from './WebGraphHeaderData';
import type { WebNowcastActivationsPerPeriodData } from './WebNowcastActivationsPerPeriodData';
import {
    WebNowcastActivationsPerPeriodDataFromJSON,
    WebNowcastActivationsPerPeriodDataFromJSONTyped,
    WebNowcastActivationsPerPeriodDataToJSON,
} from './WebNowcastActivationsPerPeriodData';
import type { WebSumActivationsPerPrice } from './WebSumActivationsPerPrice';
import {
    WebSumActivationsPerPriceFromJSON,
    WebSumActivationsPerPriceFromJSONTyped,
    WebSumActivationsPerPriceToJSON,
} from './WebSumActivationsPerPrice';

/**
 * 
 * @export
 * @interface WebGraphData
 */
export interface WebGraphData {
    /**
     * 
     * @type {WebGraphHeaderData}
     * @memberof WebGraphData
     */
    headerData?: WebGraphHeaderData;
    /**
     * 
     * @type {Array<WebSumActivationsPerPrice>}
     * @memberof WebGraphData
     */
    series?: Array<WebSumActivationsPerPrice>;
    /**
     * 
     * @type {WebNowcastActivationsPerPeriodData}
     * @memberof WebGraphData
     */
    sellStack?: WebNowcastActivationsPerPeriodData;
    /**
     * 
     * @type {WebNowcastActivationsPerPeriodData}
     * @memberof WebGraphData
     */
    buyStack?: WebNowcastActivationsPerPeriodData;
}

/**
 * Check if a given object implements the WebGraphData interface.
 */
export function instanceOfWebGraphData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebGraphDataFromJSON(json: any): WebGraphData {
    return WebGraphDataFromJSONTyped(json, false);
}

export function WebGraphDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebGraphData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'headerData': !exists(json, 'headerData') ? undefined : WebGraphHeaderDataFromJSON(json['headerData']),
        'series': !exists(json, 'series') ? undefined : ((json['series'] as Array<any>).map(WebSumActivationsPerPriceFromJSON)),
        'sellStack': !exists(json, 'sellStack') ? undefined : WebNowcastActivationsPerPeriodDataFromJSON(json['sellStack']),
        'buyStack': !exists(json, 'buyStack') ? undefined : WebNowcastActivationsPerPeriodDataFromJSON(json['buyStack']),
    };
}

export function WebGraphDataToJSON(value?: WebGraphData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'headerData': WebGraphHeaderDataToJSON(value.headerData),
        'series': value.series === undefined ? undefined : ((value.series as Array<any>).map(WebSumActivationsPerPriceToJSON)),
        'sellStack': WebNowcastActivationsPerPeriodDataToJSON(value.sellStack),
        'buyStack': WebNowcastActivationsPerPeriodDataToJSON(value.buyStack),
    };
}

