/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebActivationDebugData
 */
export interface WebActivationDebugData {
    /**
     * 
     * @type {string}
     * @memberof WebActivationDebugData
     */
    bmu?: string;
    /**
     * 
     * @type {number}
     * @memberof WebActivationDebugData
     */
    acceptanceNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof WebActivationDebugData
     */
    minute?: string;
    /**
     * 
     * @type {number}
     * @memberof WebActivationDebugData
     */
    difference?: number;
    /**
     * 
     * @type {number}
     * @memberof WebActivationDebugData
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof WebActivationDebugData
     */
    pair?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WebActivationDebugData
     */
    reversal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WebActivationDebugData
     */
    bmuType?: string;
    /**
     * 
     * @type {string}
     * @memberof WebActivationDebugData
     */
    fuelType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WebActivationDebugData
     */
    upreg?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WebActivationDebugData
     */
    so?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WebActivationDebugData
     */
    cadl?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WebActivationDebugData
     */
    extended?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WebActivationDebugData
     */
    startMoment?: string;
    /**
     * 
     * @type {string}
     * @memberof WebActivationDebugData
     */
    endMoment?: string;
    /**
     * 
     * @type {number}
     * @memberof WebActivationDebugData
     */
    startDifference?: number;
    /**
     * 
     * @type {number}
     * @memberof WebActivationDebugData
     */
    endDifference?: number;
}

/**
 * Check if a given object implements the WebActivationDebugData interface.
 */
export function instanceOfWebActivationDebugData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebActivationDebugDataFromJSON(json: any): WebActivationDebugData {
    return WebActivationDebugDataFromJSONTyped(json, false);
}

export function WebActivationDebugDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebActivationDebugData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bmu': !exists(json, 'bmu') ? undefined : json['bmu'],
        'acceptanceNumber': !exists(json, 'acceptanceNumber') ? undefined : json['acceptanceNumber'],
        'minute': !exists(json, 'minute') ? undefined : json['minute'],
        'difference': !exists(json, 'difference') ? undefined : json['difference'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'pair': !exists(json, 'pair') ? undefined : json['pair'],
        'reversal': !exists(json, 'reversal') ? undefined : json['reversal'],
        'bmuType': !exists(json, 'bmuType') ? undefined : json['bmuType'],
        'fuelType': !exists(json, 'fuelType') ? undefined : json['fuelType'],
        'upreg': !exists(json, 'upreg') ? undefined : json['upreg'],
        'so': !exists(json, 'so') ? undefined : json['so'],
        'cadl': !exists(json, 'cadl') ? undefined : json['cadl'],
        'extended': !exists(json, 'extended') ? undefined : json['extended'],
        'startMoment': !exists(json, 'startMoment') ? undefined : json['startMoment'],
        'endMoment': !exists(json, 'endMoment') ? undefined : json['endMoment'],
        'startDifference': !exists(json, 'startDifference') ? undefined : json['startDifference'],
        'endDifference': !exists(json, 'endDifference') ? undefined : json['endDifference'],
    };
}

export function WebActivationDebugDataToJSON(value?: WebActivationDebugData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bmu': value.bmu,
        'acceptanceNumber': value.acceptanceNumber,
        'minute': value.minute,
        'difference': value.difference,
        'price': value.price,
        'pair': value.pair,
        'reversal': value.reversal,
        'bmuType': value.bmuType,
        'fuelType': value.fuelType,
        'upreg': value.upreg,
        'so': value.so,
        'cadl': value.cadl,
        'extended': value.extended,
        'startMoment': value.startMoment,
        'endMoment': value.endMoment,
        'startDifference': value.startDifference,
        'endDifference': value.endDifference,
    };
}

