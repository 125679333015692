import styles from "../Spin.module.css";
import React, { memo, useCallback, useEffect, useRef } from "react";
import classnames from "classnames";
import Spin, { SpinProps } from "../Spin";

export type SpinWrapperProps = SpinProps & {
	useSpinner?: boolean;
	wrap?: boolean;
	wrapOnce?: boolean;
};

const cls = classnames.bind(styles);

function SpinWrapper({
	useSpinner,
	children,
	spinning,
	wrap = false,
	wrapOnce = !wrap,
	...rest
}: SpinWrapperProps): JSX.Element {
	const calledRef = useRef(false);

	const checkSpinWrapCondition = useCallback(() => {
		return wrap || (wrapOnce && !calledRef.current);
	}, [wrap, wrapOnce]);

	useEffect(() => {
		if (!spinning) {
			calledRef.current = true;
		}
	}, [spinning]);

	if (!useSpinner) {
		return <>{children}</>;
	}

	return (
		<>
			{!checkSpinWrapCondition() && (
				<div
					className={cls(styles.spinnerWrapper, {
						[styles.spinnerWrapperVisible]: spinning,
					})}
				>
					<Spin {...rest} spinning />
				</div>
			)}
			<Spin {...rest} spinning={spinning && checkSpinWrapCondition()}>
				{children}
			</Spin>
		</>
	);
}

export default memo(SpinWrapper);
