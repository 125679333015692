/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorMessage,
  WebTripsFilters,
  WebTripsUndergenData,
} from '../models';
import {
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
    WebTripsFiltersFromJSON,
    WebTripsFiltersToJSON,
    WebTripsUndergenDataFromJSON,
    WebTripsUndergenDataToJSON,
} from '../models';

export interface TripsUndergenRestHandlerApiGetUndergenRequest {
    treshold: number;
    lag: number;
    lead: number;
}

export interface TripsUndergenRestHandlerApiSaveUserTripsFiltersRequest {
    webTripsFilters: WebTripsFilters;
}

/**
 * 
 */
export class TripsUndergenRestHandlerApi extends runtime.BaseAPI {

    /**
     */
    async getUndergenRaw(requestParameters: TripsUndergenRestHandlerApiGetUndergenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebTripsUndergenData>> {
        if (requestParameters.treshold === null || requestParameters.treshold === undefined) {
            throw new runtime.RequiredError('treshold','Required parameter requestParameters.treshold was null or undefined when calling getUndergen.');
        }

        if (requestParameters.lag === null || requestParameters.lag === undefined) {
            throw new runtime.RequiredError('lag','Required parameter requestParameters.lag was null or undefined when calling getUndergen.');
        }

        if (requestParameters.lead === null || requestParameters.lead === undefined) {
            throw new runtime.RequiredError('lead','Required parameter requestParameters.lead was null or undefined when calling getUndergen.');
        }

        const queryParameters: any = {};

        if (requestParameters.treshold !== undefined) {
            queryParameters['treshold'] = requestParameters.treshold;
        }

        if (requestParameters.lag !== undefined) {
            queryParameters['lag'] = requestParameters.lag;
        }

        if (requestParameters.lead !== undefined) {
            queryParameters['lead'] = requestParameters.lead;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/undergen`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebTripsUndergenDataFromJSON(jsonValue));
    }

    /**
     */
    async getUndergen(requestParameters: TripsUndergenRestHandlerApiGetUndergenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebTripsUndergenData> {
        const response = await this.getUndergenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserTripsFiltersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebTripsFilters>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/undergen/filters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebTripsFiltersFromJSON(jsonValue));
    }

    /**
     */
    async getUserTripsFilters(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebTripsFilters> {
        const response = await this.getUserTripsFiltersRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async saveUserTripsFiltersRaw(requestParameters: TripsUndergenRestHandlerApiSaveUserTripsFiltersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.webTripsFilters === null || requestParameters.webTripsFilters === undefined) {
            throw new runtime.RequiredError('webTripsFilters','Required parameter requestParameters.webTripsFilters was null or undefined when calling saveUserTripsFilters.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/undergen/filters`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebTripsFiltersToJSON(requestParameters.webTripsFilters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveUserTripsFilters(requestParameters: TripsUndergenRestHandlerApiSaveUserTripsFiltersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveUserTripsFiltersRaw(requestParameters, initOverrides);
    }

}
