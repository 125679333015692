/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebNivAtMinute } from './WebNivAtMinute';
import {
    WebNivAtMinuteFromJSON,
    WebNivAtMinuteFromJSONTyped,
    WebNivAtMinuteToJSON,
} from './WebNivAtMinute';

/**
 * 
 * @export
 * @interface WebActivationsForecastNivs
 */
export interface WebActivationsForecastNivs {
    /**
     * 
     * @type {Array<WebNivAtMinute>}
     * @memberof WebActivationsForecastNivs
     */
    nivs?: Array<WebNivAtMinute>;
}

/**
 * Check if a given object implements the WebActivationsForecastNivs interface.
 */
export function instanceOfWebActivationsForecastNivs(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebActivationsForecastNivsFromJSON(json: any): WebActivationsForecastNivs {
    return WebActivationsForecastNivsFromJSONTyped(json, false);
}

export function WebActivationsForecastNivsFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebActivationsForecastNivs {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nivs': !exists(json, 'nivs') ? undefined : ((json['nivs'] as Array<any>).map(WebNivAtMinuteFromJSON)),
    };
}

export function WebActivationsForecastNivsToJSON(value?: WebActivationsForecastNivs | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nivs': value.nivs === undefined ? undefined : ((value.nivs as Array<any>).map(WebNivAtMinuteToJSON)),
    };
}

