/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebGenerationDemandInput
 */
export interface WebGenerationDemandInput {
    /**
     * 
     * @type {number}
     * @memberof WebGenerationDemandInput
     */
    lag?: number;
    /**
     * 
     * @type {number}
     * @memberof WebGenerationDemandInput
     */
    lead?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebGenerationDemandInput
     */
    dates?: Array<string>;
}

/**
 * Check if a given object implements the WebGenerationDemandInput interface.
 */
export function instanceOfWebGenerationDemandInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebGenerationDemandInputFromJSON(json: any): WebGenerationDemandInput {
    return WebGenerationDemandInputFromJSONTyped(json, false);
}

export function WebGenerationDemandInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebGenerationDemandInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lag': !exists(json, 'lag') ? undefined : json['lag'],
        'lead': !exists(json, 'lead') ? undefined : json['lead'],
        'dates': !exists(json, 'dates') ? undefined : json['dates'],
    };
}

export function WebGenerationDemandInputToJSON(value?: WebGenerationDemandInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lag': value.lag,
        'lead': value.lead,
        'dates': value.dates,
    };
}

