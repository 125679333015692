/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorMessage,
  FpnComparisonData,
  FpnComparisonInput,
} from '../models';
import {
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
    FpnComparisonDataFromJSON,
    FpnComparisonDataToJSON,
    FpnComparisonInputFromJSON,
    FpnComparisonInputToJSON,
} from '../models';

export interface FpnComparisonRestHandlerApiGetFpnComparisonRequest {
    fpnComparisonInput: FpnComparisonInput;
}

/**
 * 
 */
export class FpnComparisonRestHandlerApi extends runtime.BaseAPI {

    /**
     */
    async getFpnComparisonRaw(requestParameters: FpnComparisonRestHandlerApiGetFpnComparisonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FpnComparisonData>>> {
        if (requestParameters.fpnComparisonInput === null || requestParameters.fpnComparisonInput === undefined) {
            throw new runtime.RequiredError('fpnComparisonInput','Required parameter requestParameters.fpnComparisonInput was null or undefined when calling getFpnComparison.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/fpn-comparison/fpn`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FpnComparisonInputToJSON(requestParameters.fpnComparisonInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FpnComparisonDataFromJSON));
    }

    /**
     */
    async getFpnComparison(requestParameters: FpnComparisonRestHandlerApiGetFpnComparisonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FpnComparisonData>> {
        const response = await this.getFpnComparisonRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
