import React from "react";
import Dropdown from "antd/es/dropdown";
import HeaderNavBtn from "../Nav/HeaderNavBtn/HeaderNavBtn";
import { dropdownProps } from "./TradingMenuHelpers";

function TradingMenu(): JSX.Element {
	return (
		<Dropdown {...dropdownProps}>
			<HeaderNavBtn>Trading</HeaderNavBtn>
		</Dropdown>
	);
}

export default TradingMenu;
