/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorMessage,
  FastReserveActsData,
  FastReserveInstructionsData,
  FastReservesHeaderData,
  ForceStopInput,
} from '../models';
import {
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
    FastReserveActsDataFromJSON,
    FastReserveActsDataToJSON,
    FastReserveInstructionsDataFromJSON,
    FastReserveInstructionsDataToJSON,
    FastReservesHeaderDataFromJSON,
    FastReservesHeaderDataToJSON,
    ForceStopInputFromJSON,
    ForceStopInputToJSON,
} from '../models';

export interface FastReserveRestHandlerApiStopFrRequest {
    forceStopInput: ForceStopInput;
}

/**
 * 
 */
export class FastReserveRestHandlerApi extends runtime.BaseAPI {

    /**
     */
    async getFrActsDataRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FastReserveActsData>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fast-reserves/activations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FastReserveActsDataFromJSON));
    }

    /**
     */
    async getFrActsData(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FastReserveActsData>> {
        const response = await this.getFrActsDataRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getFrHeaderRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FastReservesHeaderData>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fast-reserves/header`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FastReservesHeaderDataFromJSON(jsonValue));
    }

    /**
     */
    async getFrHeader(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FastReservesHeaderData> {
        const response = await this.getFrHeaderRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getFrInstrDataRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FastReserveInstructionsData>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fast-reserves/instructions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FastReserveInstructionsDataFromJSON));
    }

    /**
     */
    async getFrInstrData(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FastReserveInstructionsData>> {
        const response = await this.getFrInstrDataRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async stopFrRaw(requestParameters: FastReserveRestHandlerApiStopFrRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.forceStopInput === null || requestParameters.forceStopInput === undefined) {
            throw new runtime.RequiredError('forceStopInput','Required parameter requestParameters.forceStopInput was null or undefined when calling stopFr.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/fast-reserves/force-stop`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForceStopInputToJSON(requestParameters.forceStopInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async stopFr(requestParameters: FastReserveRestHandlerApiStopFrRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.stopFrRaw(requestParameters, initOverrides);
    }

}
