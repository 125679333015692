export const BASE_CABINET_TITLE = "Electrade";

export const DEFAULT_TZ = "Europe/London";

export const ZERO = 0;
export const EMPTY_STRING = "";
export const LONG_DASH = "—";

export const GBP_SYMBOL = "£";

export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_D_MMM_YYYY_FORMAT = "D MMM YYYY";
export const DATE_MMM_D_YYYY_FORMAT = "MMM D, YYYY";
export const TIME_FORMAT = "HH:mm:ss.SSS";
export const TIME_HH_MM_SS_FORMAT = "HH:mm:ss";
export const HH_MM_TIME_FORMAT = "HH:mm";
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${HH_MM_TIME_FORMAT}`;
export const DATE_TIME_FORMAT_WITH_SEC = `${DATE_FORMAT} ${TIME_HH_MM_SS_FORMAT}`;
export const GMT_PLUS_ONE_DATE_ENDING = ".000+01:00";

export const NOT_AUTHORIZED_EVENT = "not_authorized";

// export const BAD_REQUEST_CODE = 400;
export const UNAUTHORIZED_CODE = 401;
export const FORBIDDEN_CODE = 403;
export const NOT_FOUND_CODE = 404;
// export const VALIDATION_CODE = 406;
// export const SERVICE_UNAVAILABLE_CODE = 503;

export const ABORTED_REQUEST = "aborted";
export const ABORTED_REQUEST_NAME = "AbortError";

export const FPN_COMPARISON_LOCALSTORAGE_TRESHOLD_KEY = "fpn_comparison_localstorage_treshold_key";
export const SHORT_TERM_FORECAST_LOCALSTORAGE_SYNC_KEY =
	"short_term_forecast_localstorage_sync_key";

export const DEFAULT_IS_LOADING_DELAY = 500;
