export const ROOT_PATH = "/";

/* Login */

export const LOGIN_PATH = "/login";

/* Users */

export const USERS_PATH = "/users";

/* Activations */

export const ACTIVATIONS_BASE_PATH = "/activations";
export const ACTIVATIONS_PATH = `${ACTIVATIONS_BASE_PATH}${ACTIVATIONS_BASE_PATH}`;
export const ACTIVATIONS_PER_BMU_PATH = `${ACTIVATIONS_BASE_PATH}/per-bmu`;
export const ACTIVATIONS_FORECAST_PATH = `${ACTIVATIONS_BASE_PATH}/forecast`;

/* Forward Trades */

export const FORWARD_TRADES_PATH = "/forward-trades";

/* FPN/MEL Comparison */

export const FPN_MEL_COMPARISON_PATH = "/fpn-mel-comparison";

/* Price Comparison */

export const PRICE_COMPARISON_PATH = "/price-comparison";

/* Frequency */

export const FREQUENCY_PATH = "/frequency";

/* Generation / Demand */

export const GENERATION_DEMAND_PATH = "/generation-demand";

/* Generation per Type */

export const GENERATION_PER_TYPE_PATH = "/generation-per-type";

/* Interconnectors */

export const INTERCONNECTORS_PATH = "/interconnectors";

/* Nowcast */

export const NOWCAST_BASE_PATH = "/nowcast";
export const NOWCAST_PATH = `${NOWCAST_BASE_PATH}${NOWCAST_BASE_PATH}`;
export const NOWCAST_SUMMARY_PATH = `${NOWCAST_BASE_PATH}/summary`;

/* Interconnectors */

export const TRIPS_UNDERGENERATION_PATH = "/trips-undergeneration";

/* User made Forecasts */

export const USER_MADE_FORECASTS_BASE_PATH = "/user-made-forecasts";
export const USER_MADE_DEMAND_PATH = `${USER_MADE_FORECASTS_BASE_PATH}/demand`;
export const USER_MADE_WIND_PATH = `${USER_MADE_FORECASTS_BASE_PATH}/wind`;

/* BM Unit Info */

export const BM_UNIT_INFO_PATH = "/bm-unit-info";

/* Non BM/FR */

export const NON_BM_FR_PATH = "/non-bm-fr";

/* Stacks */

export const STACKS_BASE_PATH = "/stacks";
export const STACKS_FAST_STACKS_PATH = `${STACKS_BASE_PATH}/fast-stacks`;
export const STACKS_STACKS_PATH = `${STACKS_BASE_PATH}/stacks`;

/* Notifications */

export const NOTIFICATIONS_PATH = "/notifications";

/* Short Term Forecast */

export const SHORT_TERM_FORECAST_PATH = "/short-term-forecast";

/* Short Term Forecast */

export const WIND_SOLAR_FORECASTS_PATH = "/wind-solar-forecasts";

/* Imbalance Forecast  */

export const IMBALANCE_FORECAST_PATH = "/imbalance-forecast";

/* Phantom Mode  */

export const PHANTOM_MODE_PATH = "/phantom-mode";
