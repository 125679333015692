/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MomentGraphData } from './MomentGraphData';
import {
    MomentGraphDataFromJSON,
    MomentGraphDataFromJSONTyped,
    MomentGraphDataToJSON,
} from './MomentGraphData';

/**
 * 
 * @export
 * @interface WebWindSolarData
 */
export interface WebWindSolarData {
    /**
     * 
     * @type {{ [key: string]: Array<MomentGraphData>; }}
     * @memberof WebWindSolarData
     */
    solar?: { [key: string]: Array<MomentGraphData>; };
    /**
     * 
     * @type {{ [key: string]: Array<MomentGraphData>; }}
     * @memberof WebWindSolarData
     */
    wind?: { [key: string]: Array<MomentGraphData>; };
    /**
     * 
     * @type {{ [key: string]: Array<MomentGraphData>; }}
     * @memberof WebWindSolarData
     */
    embeddedWind?: { [key: string]: Array<MomentGraphData>; };
}

/**
 * Check if a given object implements the WebWindSolarData interface.
 */
export function instanceOfWebWindSolarData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebWindSolarDataFromJSON(json: any): WebWindSolarData {
    return WebWindSolarDataFromJSONTyped(json, false);
}

export function WebWindSolarDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebWindSolarData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'solar': !exists(json, 'solar') ? undefined : json['solar'],
        'wind': !exists(json, 'wind') ? undefined : json['wind'],
        'embeddedWind': !exists(json, 'embeddedWind') ? undefined : json['embeddedWind'],
    };
}

export function WebWindSolarDataToJSON(value?: WebWindSolarData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'solar': value.solar,
        'wind': value.wind,
        'embeddedWind': value.embeddedWind,
    };
}

