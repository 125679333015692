import { useLayoutEffect, useCallback, useRef } from "react";

/* Experimental feature. Use with caution and be ready to change it to useCallback(). */

export type DefaultFuncType = (...args: any[]) => any;
export type UseEventHandlerFunc<T extends DefaultFuncType = DefaultFuncType> = (
	...args: Parameters<T>
) => ReturnType<T>;

const useEvent = <T extends (...args: any[]) => any>(handler: T): UseEventHandlerFunc<T> => {
	const handlerRef = useRef(handler);
	// handlerRef.current = handler;
	// NOTE: check if useLayoutEffect() needs to be enabled
	useLayoutEffect(() => {
		handlerRef.current = handler;
	});
	return useCallback((...args) => {
		const fn = handlerRef.current;
		return fn(...args);
	}, []);
};

export default useEvent;
