/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorMessage,
  WebGenerationPerTypeData,
} from '../models';
import {
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
    WebGenerationPerTypeDataFromJSON,
    WebGenerationPerTypeDataToJSON,
} from '../models';

export interface GenerationPerTypeRestHandlerApiGetAllGenerationPerTypeRequest {
    lag: number;
    lead: number;
    type: GetAllGenerationPerTypeTypeEnum;
}

export interface GenerationPerTypeRestHandlerApiGetBmusPerFuelTypeRequest {
    type: GetBmusPerFuelTypeTypeEnum;
}

/**
 * 
 */
export class GenerationPerTypeRestHandlerApi extends runtime.BaseAPI {

    /**
     */
    async getAllGenerationPerTypeRaw(requestParameters: GenerationPerTypeRestHandlerApiGetAllGenerationPerTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebGenerationPerTypeData>> {
        if (requestParameters.lag === null || requestParameters.lag === undefined) {
            throw new runtime.RequiredError('lag','Required parameter requestParameters.lag was null or undefined when calling getAllGenerationPerType.');
        }

        if (requestParameters.lead === null || requestParameters.lead === undefined) {
            throw new runtime.RequiredError('lead','Required parameter requestParameters.lead was null or undefined when calling getAllGenerationPerType.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getAllGenerationPerType.');
        }

        const queryParameters: any = {};

        if (requestParameters.lag !== undefined) {
            queryParameters['lag'] = requestParameters.lag;
        }

        if (requestParameters.lead !== undefined) {
            queryParameters['lead'] = requestParameters.lead;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/generation-per-type`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebGenerationPerTypeDataFromJSON(jsonValue));
    }

    /**
     */
    async getAllGenerationPerType(requestParameters: GenerationPerTypeRestHandlerApiGetAllGenerationPerTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebGenerationPerTypeData> {
        const response = await this.getAllGenerationPerTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBmusPerFuelTypeRaw(requestParameters: GenerationPerTypeRestHandlerApiGetBmusPerFuelTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getBmusPerFuelType.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/generation-per-type/bmus`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getBmusPerFuelType(requestParameters: GenerationPerTypeRestHandlerApiGetBmusPerFuelTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getBmusPerFuelTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetAllGenerationPerTypeTypeEnum = {
    Biomass: 'BIOMASS',
    Nuclear: 'NUCLEAR',
    Wind: 'WIND',
    Npshyd: 'NPSHYD',
    Ccgt: 'CCGT',
    Coal: 'COAL',
    Ocgt: 'OCGT',
    Ps: 'PS',
    Other: 'OTHER',
    Unknown: 'UNKNOWN',
    Undiscovered: 'UNDISCOVERED',
    Oil: 'OIL',
    Interconnector: 'INTERCONNECTOR',
    Intew: 'INTEW',
    Intirl: 'INTIRL',
    Intfr: 'INTFR',
    Intifa2: 'INTIFA2',
    Intelec: 'INTELEC',
    Intned: 'INTNED',
    Intnem: 'INTNEM',
    Intnsl: 'INTNSL',
    Intvkl: 'INTVKL',
    Intirlsum: 'INTIRLSUM'
} as const;
export type GetAllGenerationPerTypeTypeEnum = typeof GetAllGenerationPerTypeTypeEnum[keyof typeof GetAllGenerationPerTypeTypeEnum];
/**
 * @export
 */
export const GetBmusPerFuelTypeTypeEnum = {
    Biomass: 'BIOMASS',
    Nuclear: 'NUCLEAR',
    Wind: 'WIND',
    Npshyd: 'NPSHYD',
    Ccgt: 'CCGT',
    Coal: 'COAL',
    Ocgt: 'OCGT',
    Ps: 'PS',
    Other: 'OTHER',
    Unknown: 'UNKNOWN',
    Undiscovered: 'UNDISCOVERED',
    Oil: 'OIL',
    Interconnector: 'INTERCONNECTOR',
    Intew: 'INTEW',
    Intirl: 'INTIRL',
    Intfr: 'INTFR',
    Intifa2: 'INTIFA2',
    Intelec: 'INTELEC',
    Intned: 'INTNED',
    Intnem: 'INTNEM',
    Intnsl: 'INTNSL',
    Intvkl: 'INTVKL',
    Intirlsum: 'INTIRLSUM'
} as const;
export type GetBmusPerFuelTypeTypeEnum = typeof GetBmusPerFuelTypeTypeEnum[keyof typeof GetBmusPerFuelTypeTypeEnum];
