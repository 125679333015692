/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebActivationData
 */
export interface WebActivationData {
    /**
     * 
     * @type {string}
     * @memberof WebActivationData
     */
    bmu?: string;
    /**
     * 
     * @type {number}
     * @memberof WebActivationData
     */
    acceptanceNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof WebActivationData
     */
    minute?: string;
    /**
     * 
     * @type {number}
     * @memberof WebActivationData
     */
    difference?: number;
    /**
     * 
     * @type {number}
     * @memberof WebActivationData
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof WebActivationData
     */
    pair?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WebActivationData
     */
    reversal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WebActivationData
     */
    bmuType?: string;
    /**
     * 
     * @type {string}
     * @memberof WebActivationData
     */
    fuelType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WebActivationData
     */
    upreg?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WebActivationData
     */
    so?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WebActivationData
     */
    cadl?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WebActivationData
     */
    extended?: boolean;
}

/**
 * Check if a given object implements the WebActivationData interface.
 */
export function instanceOfWebActivationData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebActivationDataFromJSON(json: any): WebActivationData {
    return WebActivationDataFromJSONTyped(json, false);
}

export function WebActivationDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebActivationData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bmu': !exists(json, 'bmu') ? undefined : json['bmu'],
        'acceptanceNumber': !exists(json, 'acceptanceNumber') ? undefined : json['acceptanceNumber'],
        'minute': !exists(json, 'minute') ? undefined : json['minute'],
        'difference': !exists(json, 'difference') ? undefined : json['difference'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'pair': !exists(json, 'pair') ? undefined : json['pair'],
        'reversal': !exists(json, 'reversal') ? undefined : json['reversal'],
        'bmuType': !exists(json, 'bmuType') ? undefined : json['bmuType'],
        'fuelType': !exists(json, 'fuelType') ? undefined : json['fuelType'],
        'upreg': !exists(json, 'upreg') ? undefined : json['upreg'],
        'so': !exists(json, 'so') ? undefined : json['so'],
        'cadl': !exists(json, 'cadl') ? undefined : json['cadl'],
        'extended': !exists(json, 'extended') ? undefined : json['extended'],
    };
}

export function WebActivationDataToJSON(value?: WebActivationData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bmu': value.bmu,
        'acceptanceNumber': value.acceptanceNumber,
        'minute': value.minute,
        'difference': value.difference,
        'price': value.price,
        'pair': value.pair,
        'reversal': value.reversal,
        'bmuType': value.bmuType,
        'fuelType': value.fuelType,
        'upreg': value.upreg,
        'so': value.so,
        'cadl': value.cadl,
        'extended': value.extended,
    };
}

