/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebActivationsPeriodData
 */
export interface WebActivationsPeriodData {
    /**
     * 
     * @type {number}
     * @memberof WebActivationsPeriodData
     */
    totalUpreg?: number;
    /**
     * 
     * @type {number}
     * @memberof WebActivationsPeriodData
     */
    totalDownreg?: number;
    /**
     * 
     * @type {number}
     * @memberof WebActivationsPeriodData
     */
    indicatedNiv?: number;
    /**
     * 
     * @type {number}
     * @memberof WebActivationsPeriodData
     */
    extendedUpreg?: number;
    /**
     * 
     * @type {string}
     * @memberof WebActivationsPeriodData
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof WebActivationsPeriodData
     */
    endTime?: string;
}

/**
 * Check if a given object implements the WebActivationsPeriodData interface.
 */
export function instanceOfWebActivationsPeriodData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebActivationsPeriodDataFromJSON(json: any): WebActivationsPeriodData {
    return WebActivationsPeriodDataFromJSONTyped(json, false);
}

export function WebActivationsPeriodDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebActivationsPeriodData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalUpreg': !exists(json, 'totalUpreg') ? undefined : json['totalUpreg'],
        'totalDownreg': !exists(json, 'totalDownreg') ? undefined : json['totalDownreg'],
        'indicatedNiv': !exists(json, 'indicatedNiv') ? undefined : json['indicatedNiv'],
        'extendedUpreg': !exists(json, 'extendedUpreg') ? undefined : json['extendedUpreg'],
        'startTime': !exists(json, 'startTime') ? undefined : json['startTime'],
        'endTime': !exists(json, 'endTime') ? undefined : json['endTime'],
    };
}

export function WebActivationsPeriodDataToJSON(value?: WebActivationsPeriodData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalUpreg': value.totalUpreg,
        'totalDownreg': value.totalDownreg,
        'indicatedNiv': value.indicatedNiv,
        'extendedUpreg': value.extendedUpreg,
        'startTime': value.startTime,
        'endTime': value.endTime,
    };
}

