/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorMessage,
  ForwardTradesIcPeriodData,
  ForwardTradesPeriodData,
} from '../models';
import {
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
    ForwardTradesIcPeriodDataFromJSON,
    ForwardTradesIcPeriodDataToJSON,
    ForwardTradesPeriodDataFromJSON,
    ForwardTradesPeriodDataToJSON,
} from '../models';

/**
 * 
 */
export class ForwardTradeRestHandlerApi extends runtime.BaseAPI {

    /**
     */
    async getFtInterconnectorsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ForwardTradesIcPeriodData>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/forward-trades/interconnectors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ForwardTradesIcPeriodDataFromJSON));
    }

    /**
     */
    async getFtInterconnectors(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ForwardTradesIcPeriodData>> {
        const response = await this.getFtInterconnectorsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getFtSummaryRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ForwardTradesPeriodData>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/forward-trades/summary`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ForwardTradesPeriodDataFromJSON));
    }

    /**
     */
    async getFtSummary(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ForwardTradesPeriodData>> {
        const response = await this.getFtSummaryRaw(initOverrides);
        return await response.value();
    }

}
