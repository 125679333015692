/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebActivationDebugBidLevelRecord
 */
export interface WebActivationDebugBidLevelRecord {
    /**
     * 
     * @type {string}
     * @memberof WebActivationDebugBidLevelRecord
     */
    fromTime?: string;
    /**
     * 
     * @type {string}
     * @memberof WebActivationDebugBidLevelRecord
     */
    toTime?: string;
    /**
     * 
     * @type {number}
     * @memberof WebActivationDebugBidLevelRecord
     */
    pairNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof WebActivationDebugBidLevelRecord
     */
    levelFrom?: number;
    /**
     * 
     * @type {number}
     * @memberof WebActivationDebugBidLevelRecord
     */
    levelTo?: number;
    /**
     * 
     * @type {number}
     * @memberof WebActivationDebugBidLevelRecord
     */
    offerPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof WebActivationDebugBidLevelRecord
     */
    bidPrice?: number;
}

/**
 * Check if a given object implements the WebActivationDebugBidLevelRecord interface.
 */
export function instanceOfWebActivationDebugBidLevelRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebActivationDebugBidLevelRecordFromJSON(json: any): WebActivationDebugBidLevelRecord {
    return WebActivationDebugBidLevelRecordFromJSONTyped(json, false);
}

export function WebActivationDebugBidLevelRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebActivationDebugBidLevelRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fromTime': !exists(json, 'fromTime') ? undefined : json['fromTime'],
        'toTime': !exists(json, 'toTime') ? undefined : json['toTime'],
        'pairNumber': !exists(json, 'pairNumber') ? undefined : json['pairNumber'],
        'levelFrom': !exists(json, 'levelFrom') ? undefined : json['levelFrom'],
        'levelTo': !exists(json, 'levelTo') ? undefined : json['levelTo'],
        'offerPrice': !exists(json, 'offerPrice') ? undefined : json['offerPrice'],
        'bidPrice': !exists(json, 'bidPrice') ? undefined : json['bidPrice'],
    };
}

export function WebActivationDebugBidLevelRecordToJSON(value?: WebActivationDebugBidLevelRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fromTime': value.fromTime,
        'toTime': value.toTime,
        'pairNumber': value.pairNumber,
        'levelFrom': value.levelFrom,
        'levelTo': value.levelTo,
        'offerPrice': value.offerPrice,
        'bidPrice': value.bidPrice,
    };
}

