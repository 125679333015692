/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ForwardTradesIcPeriodData
 */
export interface ForwardTradesIcPeriodData {
    /**
     * 
     * @type {string}
     * @memberof ForwardTradesIcPeriodData
     */
    settlementDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ForwardTradesIcPeriodData
     */
    settlementPeriods?: string;
    /**
     * 
     * @type {string}
     * @memberof ForwardTradesIcPeriodData
     */
    side?: string;
    /**
     * 
     * @type {string}
     * @memberof ForwardTradesIcPeriodData
     */
    quantity?: string;
    /**
     * 
     * @type {string}
     * @memberof ForwardTradesIcPeriodData
     */
    deadline?: string;
    /**
     * 
     * @type {string}
     * @memberof ForwardTradesIcPeriodData
     */
    defaultPrice?: string;
    /**
     * 
     * @type {string}
     * @memberof ForwardTradesIcPeriodData
     */
    clearingPrice?: string;
    /**
     * 
     * @type {string}
     * @memberof ForwardTradesIcPeriodData
     */
    vwaPrice?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ForwardTradesIcPeriodData
     */
    ics?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ForwardTradesIcPeriodData
     */
    icsWithVolume?: { [key: string]: number; };
}

/**
 * Check if a given object implements the ForwardTradesIcPeriodData interface.
 */
export function instanceOfForwardTradesIcPeriodData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ForwardTradesIcPeriodDataFromJSON(json: any): ForwardTradesIcPeriodData {
    return ForwardTradesIcPeriodDataFromJSONTyped(json, false);
}

export function ForwardTradesIcPeriodDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForwardTradesIcPeriodData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'settlementDate': !exists(json, 'settlementDate') ? undefined : json['settlementDate'],
        'settlementPeriods': !exists(json, 'settlementPeriods') ? undefined : json['settlementPeriods'],
        'side': !exists(json, 'side') ? undefined : json['side'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'deadline': !exists(json, 'deadline') ? undefined : json['deadline'],
        'defaultPrice': !exists(json, 'defaultPrice') ? undefined : json['defaultPrice'],
        'clearingPrice': !exists(json, 'clearingPrice') ? undefined : json['clearingPrice'],
        'vwaPrice': !exists(json, 'vwaPrice') ? undefined : json['vwaPrice'],
        'ics': !exists(json, 'ics') ? undefined : json['ics'],
        'icsWithVolume': !exists(json, 'icsWithVolume') ? undefined : json['icsWithVolume'],
    };
}

export function ForwardTradesIcPeriodDataToJSON(value?: ForwardTradesIcPeriodData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'settlementDate': value.settlementDate,
        'settlementPeriods': value.settlementPeriods,
        'side': value.side,
        'quantity': value.quantity,
        'deadline': value.deadline,
        'defaultPrice': value.defaultPrice,
        'clearingPrice': value.clearingPrice,
        'vwaPrice': value.vwaPrice,
        'ics': value.ics,
        'icsWithVolume': value.icsWithVolume,
    };
}

