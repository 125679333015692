/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebExtendedBmusLists
 */
export interface WebExtendedBmusLists {
    /**
     * 
     * @type {Set<string>}
     * @memberof WebExtendedBmusLists
     */
    allBmus?: Set<string>;
    /**
     * 
     * @type {Set<string>}
     * @memberof WebExtendedBmusLists
     */
    disabledBmus?: Set<string>;
}

/**
 * Check if a given object implements the WebExtendedBmusLists interface.
 */
export function instanceOfWebExtendedBmusLists(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebExtendedBmusListsFromJSON(json: any): WebExtendedBmusLists {
    return WebExtendedBmusListsFromJSONTyped(json, false);
}

export function WebExtendedBmusListsFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebExtendedBmusLists {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allBmus': !exists(json, 'allBmus') ? undefined : json['allBmus'],
        'disabledBmus': !exists(json, 'disabledBmus') ? undefined : json['disabledBmus'],
    };
}

export function WebExtendedBmusListsToJSON(value?: WebExtendedBmusLists | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allBmus': value.allBmus === undefined ? undefined : Array.from(value.allBmus as Set<any>),
        'disabledBmus': value.disabledBmus === undefined ? undefined : Array.from(value.disabledBmus as Set<any>),
    };
}

