/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorMessage,
  WebImbalanceForecastData,
} from '../models';
import {
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
    WebImbalanceForecastDataFromJSON,
    WebImbalanceForecastDataToJSON,
} from '../models';

export interface ImbalanceForecastRestHandlerApiGetImbalanceForecastRequest {
    lag: number;
    lead: number;
}

/**
 * 
 */
export class ImbalanceForecastRestHandlerApi extends runtime.BaseAPI {

    /**
     */
    async getImbalanceForecastRaw(requestParameters: ImbalanceForecastRestHandlerApiGetImbalanceForecastRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebImbalanceForecastData>> {
        if (requestParameters.lag === null || requestParameters.lag === undefined) {
            throw new runtime.RequiredError('lag','Required parameter requestParameters.lag was null or undefined when calling getImbalanceForecast.');
        }

        if (requestParameters.lead === null || requestParameters.lead === undefined) {
            throw new runtime.RequiredError('lead','Required parameter requestParameters.lead was null or undefined when calling getImbalanceForecast.');
        }

        const queryParameters: any = {};

        if (requestParameters.lag !== undefined) {
            queryParameters['lag'] = requestParameters.lag;
        }

        if (requestParameters.lead !== undefined) {
            queryParameters['lead'] = requestParameters.lead;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/imbalance-forecast`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebImbalanceForecastDataFromJSON(jsonValue));
    }

    /**
     */
    async getImbalanceForecast(requestParameters: ImbalanceForecastRestHandlerApiGetImbalanceForecastRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebImbalanceForecastData> {
        const response = await this.getImbalanceForecastRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
