import React, { memo } from "react";
import Popover from "antd/es/popover";
import HealthCheckMenuContent from "./HealthCheckContent";
import HeaderNavBtn from "@/components/layout/Nav/HeaderNavBtn/HeaderNavBtn";

function HealthCheck(): JSX.Element {
	return (
		<Popover
			arrow={false}
			destroyTooltipOnHide
			trigger="click"
			placement="bottomLeft"
			content={<HealthCheckMenuContent />}
		>
			<HeaderNavBtn>Health Check</HeaderNavBtn>
		</Popover>
	);
}

export default memo(HealthCheck);
