import styles from "./Nav.module.css";
import React from "react";
import TradingMenu from "../TradingMenu/TradingMenu";
import UserMenu from "../UserMenu/UserMenu";
import HealthCheckMenu from "../HealthCheck/HealthCheck";
// import HeaderNavLink from "./HeaderNavLink/HeaderNavLink";
// import { USERS_PATH } from "@/utils/SitePaths";

function Nav(): JSX.Element {
	return (
		<nav className={styles.nav}>
			<div className={styles.navLeftContainer}>
				<div className={styles.appTitle}>BnB</div>
				<TradingMenu />
				<HealthCheckMenu />
				{/* <HeaderNavLink to={USERS_PATH}>Users</HeaderNavLink> */}
			</div>
			<div className={styles.navRightContainer}>
				<UserMenu />
			</div>
		</nav>
	);
}

export default Nav;
