import { v4 as uuidv4 } from "uuid";
import { Configuration, RequestOpts, InitOverrideFunction } from "../integration-api/runtime";
import {
	UserDataSourceRestHandlerApi,
	ActivationsRestHandlerApi,
	ActivationsForecastRestHandlerApi,
	ActivationsPerBmuRestHandlerApi,
	NowcastRestHandlerApi,
	NowcastSummaryRestHandlerApi,
	TripsUndergenRestHandlerApi,
	InterconnectorRestHandlerApi,
	GenerationDemandRestHandlerApi,
	GenerationPerTypeRestHandlerApi,
	UserMadeDemandRestHandlerApi,
	UserMadeWindRestHandlerApi,
	BmUnitInfoRestHandlerApi,
	FastReserveRestHandlerApi,
	ForwardTradeRestHandlerApi,
	FpnComparisonRestHandlerApi,
	HealthCheckRestHandlerApi,
	StacksRestHandlerApi,
	NotificationsRestHandlerApi,
	ShortTermFcRestHandlerApi,
	PriceComparisonRestHandlerApi,
	WindSolarDemandRestHandlerApi,
	ImbalanceForecastRestHandlerApi,
	PhantomModeRestHandlerApi,
} from "../integration-api/apis";
import ErrorHandler from "@/handlers/ErrorHandler";
import { ABORTED_REQUEST } from "@/utils/Constants";
// import RestMiddleware from './RestMiddleware/RestMiddleware';

export enum NotificationItemStateDataType {
	VALIDATION = "validation",
	NOTIFICATION = "nofitication",
	UNHANDLED = "Unhandled server error",
	NOTAUTHORIZED = "Not authorized",
}

export interface RestClientOptions {
	requestId?: string;
}
interface RequestOptsExtra extends RequestOpts {
	requestId: string;
}
type RequestsOptions = {
	[key: string]: {
		abortController: AbortController;
	};
};
type Constructor<T> = new (...args: any[]) => T;

function ServerApiWrapper<T extends Constructor<{}>>(Base: T) {
	return class extends Base {
		_requestId?: string;
		requestsOptions: RequestsOptions = {};

		constructor(...args: any[]) {
			super(...args);
		}

		withOptions(options: RestClientOptions = {}): this {
			const { requestId } = options;
			this._requestId = requestId;
			return this;
		}

		abortRequestById(requestId: string): void {
			const requestOptions = this.requestsOptions[requestId];
			requestOptions.abortController.abort();
		}

		handleAbortedRequest(requestId: string): void {
			const requestOptions = this.requestsOptions[requestId];
			if (requestOptions.abortController.signal.aborted) {
				this.handleRequestOptionsAfter(requestId);
				throw new Error(ABORTED_REQUEST);
			}
		}

		protected handleRequestOptionsBefore(context: RequestOptsExtra): void {
			// assign current request unique ID
			let requestId;
			if (this._requestId) {
				requestId = this._requestId;
				this._requestId = undefined; // <- clear id
			} else {
				requestId = uuidv4();
			}
			context.requestId = requestId;

			const abortController = new AbortController();

			// add request options to map
			this.requestsOptions[requestId] = { abortController };
		}

		// protected handleRequestOptionsAfter(context: RequestOptsExtra): void {
		protected handleRequestOptionsAfter(requestId: string): void {
			delete this.requestsOptions[requestId];
		}

		protected async handleError(response: any): Promise<void> {
			if (ErrorHandler.isUnAuthorizedStatus(response.status)) {
				ErrorHandler.notAuthorized();
				Promise.reject(NotificationItemStateDataType.NOTAUTHORIZED);
			}

			if (response.status === 500) {
				// NOTE: we can split globalErrors from local (by requests) if needed.
				let errorText = "Unhandled server error";
				try {
					errorText = JSON.stringify(await response.json(), null, 2);
				} catch (e) {}
				ErrorHandler.createGlobalErrorNotification(errorText);
				// NotificationStoreHelper.abortRequests();
			}
		}

		protected async request(
			context: RequestOptsExtra,
			initOverrides: RequestInit | InitOverrideFunction = {},
		): Promise<Response> {
			this.handleRequestOptionsBefore(context);

			// get options for current request
			const requestOptions = this.requestsOptions[context.requestId] || {};

			const { url, init = {} } = await (this as any).createFetchParams(context, {
				...initOverrides,
				signal: requestOptions.abortController.signal,
			});
			let response;

			this.handleAbortedRequest(context.requestId);

			try {
				response = await (this as any).fetchApi(url, init);
				this.handleAbortedRequest(context.requestId);
			} catch (e) {
				// RequestsActions.requestStopped((init as any)._pseudoAbortController);
				this.handleAbortedRequest(context.requestId);
				if ((e as any)?.response) {
					this.handleError((e as any).response);
				}
				// if (e?.response?.status && !ErrorHandler.isUnAuthorizedStatus(e.response.status)) {
				throw (e as any).response;
			}

			if (response.status >= 200 && response.status < 300) {
				if (response.status == 204) {
					response.json = () => {
						return {};
					};
				}
				return response;
			}

			this.handleError(response);

			this.handleRequestOptionsAfter(context.requestId);

			// RequestsActions.requestStopped((init as any)._pseudoAbortController);
			throw response;
		}
	};
}

type ServerApiExtended<T> = {
	abortRequestById(requestId: string): void;
	handleAbortedRequest(requestId: string): void;
	withOptions(options: RestClientOptions): T;
};

class ServerApi {
	userDataSource: ServerApiExtended<UserDataSourceRestHandlerApi> & UserDataSourceRestHandlerApi;
	activationsPerBmu: ServerApiExtended<ActivationsPerBmuRestHandlerApi> &
		ActivationsPerBmuRestHandlerApi;
	activations: ServerApiExtended<ActivationsRestHandlerApi> & ActivationsRestHandlerApi;
	activationsForecast: ServerApiExtended<ActivationsForecastRestHandlerApi> &
		ActivationsForecastRestHandlerApi;
	nowcast: ServerApiExtended<NowcastRestHandlerApi> & NowcastRestHandlerApi;
	nowcastSummary: ServerApiExtended<NowcastSummaryRestHandlerApi> & NowcastSummaryRestHandlerApi;
	tripsUndergen: ServerApiExtended<TripsUndergenRestHandlerApi> & TripsUndergenRestHandlerApi;
	interconnector: ServerApiExtended<InterconnectorRestHandlerApi> & InterconnectorRestHandlerApi;
	generationDemand: ServerApiExtended<GenerationDemandRestHandlerApi> &
		GenerationDemandRestHandlerApi;
	generationPerType: ServerApiExtended<GenerationPerTypeRestHandlerApi> &
		GenerationPerTypeRestHandlerApi;
	userMadeDemand: ServerApiExtended<UserMadeDemandRestHandlerApi> & UserMadeDemandRestHandlerApi;
	userMadeWind: ServerApiExtended<UserMadeWindRestHandlerApi> & UserMadeWindRestHandlerApi;
	bmUnitInfo: ServerApiExtended<BmUnitInfoRestHandlerApi> & BmUnitInfoRestHandlerApi;
	fastReserve: ServerApiExtended<FastReserveRestHandlerApi> & FastReserveRestHandlerApi;
	forwardTrades: ServerApiExtended<ForwardTradeRestHandlerApi> & ForwardTradeRestHandlerApi;
	fpnComparison: ServerApiExtended<FpnComparisonRestHandlerApi> & FpnComparisonRestHandlerApi;
	healthCheck: ServerApiExtended<HealthCheckRestHandlerApi> & HealthCheckRestHandlerApi;
	stacks: ServerApiExtended<StacksRestHandlerApi> & StacksRestHandlerApi;
	notifications: ServerApiExtended<NotificationsRestHandlerApi> & NotificationsRestHandlerApi;
	shortTermFc: ServerApiExtended<ShortTermFcRestHandlerApi> & ShortTermFcRestHandlerApi;
	priceComparison: ServerApiExtended<PriceComparisonRestHandlerApi> & PriceComparisonRestHandlerApi;
	windSolarDemand: ServerApiExtended<WindSolarDemandRestHandlerApi> & WindSolarDemandRestHandlerApi;
	imbalanceForecast: ServerApiExtended<ImbalanceForecastRestHandlerApi> &
		ImbalanceForecastRestHandlerApi;
	phantomMode: ServerApiExtended<PhantomModeRestHandlerApi> & PhantomModeRestHandlerApi;

	constructor() {
		const configuration: Configuration = new Configuration({
			basePath: `/api`,
			// middleware: [new RestMiddleware()],
			// fetchApi: axiosFetchApi,
		});

		this.userDataSource = new (ServerApiWrapper(UserDataSourceRestHandlerApi))(configuration);
		this.activationsPerBmu = new (ServerApiWrapper(ActivationsPerBmuRestHandlerApi))(configuration);
		this.activations = new (ServerApiWrapper(ActivationsRestHandlerApi))(configuration);
		this.activationsForecast = new (ServerApiWrapper(ActivationsForecastRestHandlerApi))(
			configuration,
		);
		this.nowcast = new (ServerApiWrapper(NowcastRestHandlerApi))(configuration);
		this.nowcastSummary = new (ServerApiWrapper(NowcastSummaryRestHandlerApi))(configuration);
		this.tripsUndergen = new (ServerApiWrapper(TripsUndergenRestHandlerApi))(configuration);
		this.interconnector = new (ServerApiWrapper(InterconnectorRestHandlerApi))(configuration);
		this.generationDemand = new (ServerApiWrapper(GenerationDemandRestHandlerApi))(configuration);
		this.generationPerType = new (ServerApiWrapper(GenerationPerTypeRestHandlerApi))(configuration);
		this.userMadeDemand = new (ServerApiWrapper(UserMadeDemandRestHandlerApi))(configuration);
		this.userMadeWind = new (ServerApiWrapper(UserMadeWindRestHandlerApi))(configuration);
		this.bmUnitInfo = new (ServerApiWrapper(BmUnitInfoRestHandlerApi))(configuration);
		this.fastReserve = new (ServerApiWrapper(FastReserveRestHandlerApi))(configuration);
		this.forwardTrades = new (ServerApiWrapper(ForwardTradeRestHandlerApi))(configuration);
		this.fpnComparison = new (ServerApiWrapper(FpnComparisonRestHandlerApi))(configuration);
		this.healthCheck = new (ServerApiWrapper(HealthCheckRestHandlerApi))(configuration);
		this.stacks = new (ServerApiWrapper(StacksRestHandlerApi))(configuration);
		this.notifications = new (ServerApiWrapper(NotificationsRestHandlerApi))(configuration);
		this.shortTermFc = new (ServerApiWrapper(ShortTermFcRestHandlerApi))(configuration);
		this.priceComparison = new (ServerApiWrapper(PriceComparisonRestHandlerApi))(configuration);
		this.windSolarDemand = new (ServerApiWrapper(WindSolarDemandRestHandlerApi))(configuration);
		this.imbalanceForecast = new (ServerApiWrapper(ImbalanceForecastRestHandlerApi))(configuration);
		this.phantomMode = new (ServerApiWrapper(PhantomModeRestHandlerApi))(configuration);
	}
}

const RestClient: ServerApi = new ServerApi();

export default RestClient;
