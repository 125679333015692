/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebInputPoint } from './WebInputPoint';
import {
    WebInputPointFromJSON,
    WebInputPointFromJSONTyped,
    WebInputPointToJSON,
} from './WebInputPoint';

/**
 * 
 * @export
 * @interface WebInputWindLines
 */
export interface WebInputWindLines {
    /**
     * 
     * @type {Array<WebInputPoint>}
     * @memberof WebInputWindLines
     */
    allPoints?: Array<WebInputPoint>;
}

/**
 * Check if a given object implements the WebInputWindLines interface.
 */
export function instanceOfWebInputWindLines(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebInputWindLinesFromJSON(json: any): WebInputWindLines {
    return WebInputWindLinesFromJSONTyped(json, false);
}

export function WebInputWindLinesFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebInputWindLines {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allPoints': !exists(json, 'allPoints') ? undefined : ((json['allPoints'] as Array<any>).map(WebInputPointFromJSON)),
    };
}

export function WebInputWindLinesToJSON(value?: WebInputWindLines | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allPoints': value.allPoints === undefined ? undefined : ((value.allPoints as Array<any>).map(WebInputPointToJSON)),
    };
}

