import dayjs, { Dayjs } from "dayjs";
import type { DisabledTimes } from "rc-picker/es/interface";
import { DATE_FORMAT, DEFAULT_TZ, EMPTY_STRING, ZERO } from "@/utils/Constants";

type DisabledBeforeDateOpts = {
	withCurrent?: boolean;
	isTime?: boolean;
};

export const disabledBeforeDate = (
	date: Dayjs,
	currentTimeStr: string,
	{ withCurrent = false, isTime = false }: DisabledBeforeDateOpts = {},
): boolean => {
	let currentDate = dayjs(currentTimeStr).tz(DEFAULT_TZ, true);
	let pickerDate = date;

	if (!isTime) {
		currentDate = dayjs(currentDate.format(DATE_FORMAT));
		pickerDate = dayjs(pickerDate.format(DATE_FORMAT));
	}

	if (withCurrent) {
		return pickerDate.diff(currentDate) > ZERO;
	}

	return pickerDate.diff(currentDate) >= ZERO;
};

export const disabledBetweenDate = (
	date: Dayjs,
	firstDateStr: string,
	secondDateStr: string,
	{ withBoth = false }: { withBoth?: boolean } = {},
): boolean => {
	const firstDate = dayjs(dayjs(firstDateStr).tz(DEFAULT_TZ, true).format(DATE_FORMAT));
	const secondDate = dayjs(dayjs(secondDateStr).tz(DEFAULT_TZ, true).format(DATE_FORMAT));
	const pickerDate = dayjs(date.format(DATE_FORMAT));

	if (withBoth) {
		return pickerDate.diff(firstDate) < ZERO || pickerDate.diff(secondDate) > ZERO;
	}

	return pickerDate.diff(firstDate) <= ZERO || pickerDate.diff(secondDate) >= ZERO;
};

const hours = new Array(24).fill(0).map((_, index) => {
	return index;
});
const minutes = new Array(60).fill(0).map((_, index) => {
	return index;
});
const seconds = new Array(60).fill(0).map((_, index) => {
	return index;
});

type DisabledFilterOpts = {
	time?: {
		hour?: number;
		minute?: number;
		second?: number;
	};
	pickerDate: Dayjs;
	currentTimeStr: string;
	withCurrent?: boolean;
};

const disabledFilter = (opts: DisabledFilterOpts): boolean => {
	const { pickerDate, currentTimeStr, time = {}, withCurrent = false } = opts;
	const { hour = ZERO, minute = ZERO, second = ZERO } = time;
	const modifiedDate = pickerDate.hour(hour).minute(minute).second(second).tz(DEFAULT_TZ, true);

	return disabledBeforeDate(modifiedDate, currentTimeStr, {
		withCurrent,
		isTime: true,
	});
};

export const disabledBeforeTime = (
	date: Dayjs,
	currentTimeStr: string,
	{ withCurrent = false }: Omit<DisabledBeforeDateOpts, "isTime">,
): DisabledTimes => {
	const pickerDate = dayjs(date.tz(DEFAULT_TZ, true).toISOString());

	return {
		disabledHours: () => {
			return hours.filter((hour) => {
				return disabledFilter({
					pickerDate,
					currentTimeStr,
					withCurrent,
					time: {
						hour,
					},
				});
			});
		},
		disabledMinutes: (hour: number) => {
			return minutes.filter((minute) => {
				return disabledFilter({
					pickerDate,
					withCurrent,
					currentTimeStr,
					time: {
						hour,
						minute,
					},
				});
			});
		},
		disabledSeconds: (hour: number, minute: number) => {
			return seconds.filter((second) => {
				return disabledFilter({
					pickerDate,
					withCurrent,
					currentTimeStr,
					time: {
						hour,
						minute,
						second,
					},
				});
			});
		},
	};
};

type GetDiffReturn = {
	hours: number;
	mins: number;
	sec: number;
	total: number;
};

export const getDiff = (date: Date, baseDate?: Date): GetDiffReturn => {
	const base = dayjs(baseDate).tz();
	const dateTz = dayjs(date).tz();

	const diffHours = base.diff(dateTz, "hours");
	const diffMin = base.diff(dateTz, "minutes");
	const diffSec = base.diff(dateTz, "seconds");

	const diffTotal = base.diff(dateTz, "date");

	return {
		hours: diffHours,
		mins: diffMin,
		sec: diffSec,
		total: diffTotal,
	};
};

export const dateDiffFormatter = (date?: Date): string => {
	if (!date) {
		return "--";
	}

	const { hours, mins, sec } = getDiff(date);

	return `${
		hours > ZERO
			? `${hours} hour${hours !== 1 ? "s" : EMPTY_STRING}`
			: mins > ZERO
			? `${mins} minute${mins !== 1 ? "s" : EMPTY_STRING}`
			: `${sec} second${sec !== 1 ? "s" : EMPTY_STRING}`
	} ago`;
};

export type AlertTypeByTimeDiffConditionType = (mins: number, hours: number) => boolean;

type GetAlertTypeByTimeDiffOpts = {
	errorCondition: AlertTypeByTimeDiffConditionType;
	warningCondition: AlertTypeByTimeDiffConditionType;
};

export const getAlertTypeByTimeDiff = ({
	date,
	opts,
}: {
	date?: Date;
	opts: GetAlertTypeByTimeDiffOpts;
}): "success" | "info" | "warning" | "error" => {
	const { errorCondition, warningCondition } = opts;

	if (!date) {
		return "error";
	}

	const { hours, mins } = getDiff(date);

	if (errorCondition(mins, hours)) {
		return "error";
	} else if (warningCondition(mins, hours)) {
		return "warning";
	}

	return "success";
};
