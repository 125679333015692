/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FastReservesHeaderData
 */
export interface FastReservesHeaderData {
    /**
     * 
     * @type {number}
     * @memberof FastReservesHeaderData
     */
    running?: number;
    /**
     * 
     * @type {number}
     * @memberof FastReservesHeaderData
     */
    outturn?: number;
    /**
     * 
     * @type {number}
     * @memberof FastReservesHeaderData
     */
    lastPeriod?: number;
    /**
     * 
     * @type {number}
     * @memberof FastReservesHeaderData
     */
    thisPeriod?: number;
    /**
     * 
     * @type {number}
     * @memberof FastReservesHeaderData
     */
    nextPeriod?: number;
}

/**
 * Check if a given object implements the FastReservesHeaderData interface.
 */
export function instanceOfFastReservesHeaderData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FastReservesHeaderDataFromJSON(json: any): FastReservesHeaderData {
    return FastReservesHeaderDataFromJSONTyped(json, false);
}

export function FastReservesHeaderDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FastReservesHeaderData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'running': !exists(json, 'running') ? undefined : json['running'],
        'outturn': !exists(json, 'outturn') ? undefined : json['outturn'],
        'lastPeriod': !exists(json, 'lastPeriod') ? undefined : json['lastPeriod'],
        'thisPeriod': !exists(json, 'thisPeriod') ? undefined : json['thisPeriod'],
        'nextPeriod': !exists(json, 'nextPeriod') ? undefined : json['nextPeriod'],
    };
}

export function FastReservesHeaderDataToJSON(value?: FastReservesHeaderData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'running': value.running,
        'outturn': value.outturn,
        'lastPeriod': value.lastPeriod,
        'thisPeriod': value.thisPeriod,
        'nextPeriod': value.nextPeriod,
    };
}

