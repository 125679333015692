/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebPeriodNivData
 */
export interface WebPeriodNivData {
    /**
     * 
     * @type {string}
     * @memberof WebPeriodNivData
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof WebPeriodNivData
     */
    endTime?: string;
    /**
     * 
     * @type {number}
     * @memberof WebPeriodNivData
     */
    value?: number;
    /**
     * 
     * @type {number}
     * @memberof WebPeriodNivData
     */
    fr?: number;
    /**
     * 
     * @type {number}
     * @memberof WebPeriodNivData
     */
    sp?: number;
}

/**
 * Check if a given object implements the WebPeriodNivData interface.
 */
export function instanceOfWebPeriodNivData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebPeriodNivDataFromJSON(json: any): WebPeriodNivData {
    return WebPeriodNivDataFromJSONTyped(json, false);
}

export function WebPeriodNivDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebPeriodNivData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startTime': !exists(json, 'startTime') ? undefined : json['startTime'],
        'endTime': !exists(json, 'endTime') ? undefined : json['endTime'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'fr': !exists(json, 'fr') ? undefined : json['fr'],
        'sp': !exists(json, 'sp') ? undefined : json['sp'],
    };
}

export function WebPeriodNivDataToJSON(value?: WebPeriodNivData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startTime': value.startTime,
        'endTime': value.endTime,
        'value': value.value,
        'fr': value.fr,
        'sp': value.sp,
    };
}

