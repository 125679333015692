/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorMessage,
  WebBmuInfoOutput,
} from '../models';
import {
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
    WebBmuInfoOutputFromJSON,
    WebBmuInfoOutputToJSON,
} from '../models';

export interface BmUnitInfoRestHandlerApiGetBmuInfoRequest {
    bmu: string;
    lag?: number;
    lead?: number;
}

/**
 * 
 */
export class BmUnitInfoRestHandlerApi extends runtime.BaseAPI {

    /**
     */
    async getAllBmusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/bm-unit-info/all-bmus`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getAllBmus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getAllBmusRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getBmuInfoRaw(requestParameters: BmUnitInfoRestHandlerApiGetBmuInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebBmuInfoOutput>> {
        if (requestParameters.bmu === null || requestParameters.bmu === undefined) {
            throw new runtime.RequiredError('bmu','Required parameter requestParameters.bmu was null or undefined when calling getBmuInfo.');
        }

        const queryParameters: any = {};

        if (requestParameters.bmu !== undefined) {
            queryParameters['bmu'] = requestParameters.bmu;
        }

        if (requestParameters.lag !== undefined) {
            queryParameters['lag'] = requestParameters.lag;
        }

        if (requestParameters.lead !== undefined) {
            queryParameters['lead'] = requestParameters.lead;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/bm-unit-info/bmu-info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebBmuInfoOutputFromJSON(jsonValue));
    }

    /**
     */
    async getBmuInfo(requestParameters: BmUnitInfoRestHandlerApiGetBmuInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebBmuInfoOutput> {
        const response = await this.getBmuInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
