import { use } from "echarts/core";
import { BarChart, LineChart } from "echarts/charts";
import {
	DataZoomComponent,
	GridComponent,
	LegendComponent,
	MarkAreaComponent,
	MarkLineComponent,
	TitleComponent,
	ToolboxComponent,
	TooltipComponent,
	GraphicComponent,
} from "echarts/components";
import { ScatterChart } from "echarts/charts";
import { LabelLayout, UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

export function setGlobalChartsUseCanvasRenderer(): void {
	use([
		CanvasRenderer,
		BarChart,
		LineChart,
		DataZoomComponent,
		GridComponent,
		LegendComponent,
		MarkAreaComponent,
		MarkLineComponent,
		TitleComponent,
		ToolboxComponent,
		TooltipComponent,
		LabelLayout,
		UniversalTransition,
		GraphicComponent,
		ScatterChart,
	] as any);
}
