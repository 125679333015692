import { MutableRefObject, useRef } from "react";
import useEvent, { UseEventHandlerFunc } from "@/hooks/useEvent";

type UseAbortRequestOutput = {
	requestIdRef: MutableRefObject<string | null>;
	abortRequest: UseEventHandlerFunc;
};

const useAbortRequest = (
	abortFn: (requestId: string) => void,
	withAsyncDelay: boolean = true,
): UseAbortRequestOutput => {
	const requestIdRef = useRef<string | null>(null);

	const abortRequest = useEvent(async () => {
		if (requestIdRef.current) {
			abortFn(requestIdRef.current);
			requestIdRef.current = null;
		}

		if (withAsyncDelay) {
			return new Promise((resolve) => {
				setTimeout(() => {
					resolve(true);
				});
			});
		}
	});

	return {
		requestIdRef,
		abortRequest,
	};
};

export default useAbortRequest;
