/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebGraphHeaderData
 */
export interface WebGraphHeaderData {
    /**
     * 
     * @type {Date}
     * @memberof WebGraphHeaderData
     */
    time?: Date;
    /**
     * 
     * @type {number}
     * @memberof WebGraphHeaderData
     */
    period?: number;
    /**
     * 
     * @type {number}
     * @memberof WebGraphHeaderData
     */
    mainPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof WebGraphHeaderData
     */
    niv?: number;
    /**
     * 
     * @type {number}
     * @memberof WebGraphHeaderData
     */
    offer?: number;
    /**
     * 
     * @type {number}
     * @memberof WebGraphHeaderData
     */
    bid?: number;
    /**
     * 
     * @type {number}
     * @memberof WebGraphHeaderData
     */
    bpa?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WebGraphHeaderData
     */
    stor?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WebGraphHeaderData
     */
    rsp?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WebGraphHeaderData
     */
    mipped?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WebGraphHeaderData
     */
    mip?: number;
}

/**
 * Check if a given object implements the WebGraphHeaderData interface.
 */
export function instanceOfWebGraphHeaderData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebGraphHeaderDataFromJSON(json: any): WebGraphHeaderData {
    return WebGraphHeaderDataFromJSONTyped(json, false);
}

export function WebGraphHeaderDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebGraphHeaderData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'time': !exists(json, 'time') ? undefined : (new Date(json['time'])),
        'period': !exists(json, 'period') ? undefined : json['period'],
        'mainPrice': !exists(json, 'mainPrice') ? undefined : json['mainPrice'],
        'niv': !exists(json, 'niv') ? undefined : json['niv'],
        'offer': !exists(json, 'offer') ? undefined : json['offer'],
        'bid': !exists(json, 'bid') ? undefined : json['bid'],
        'bpa': !exists(json, 'bpa') ? undefined : json['bpa'],
        'stor': !exists(json, 'stor') ? undefined : json['stor'],
        'rsp': !exists(json, 'rsp') ? undefined : json['rsp'],
        'mipped': !exists(json, 'mipped') ? undefined : json['mipped'],
        'mip': !exists(json, 'mip') ? undefined : json['mip'],
    };
}

export function WebGraphHeaderDataToJSON(value?: WebGraphHeaderData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'time': value.time === undefined ? undefined : (value.time.toISOString()),
        'period': value.period,
        'mainPrice': value.mainPrice,
        'niv': value.niv,
        'offer': value.offer,
        'bid': value.bid,
        'bpa': value.bpa,
        'stor': value.stor,
        'rsp': value.rsp,
        'mipped': value.mipped,
        'mip': value.mip,
    };
}

