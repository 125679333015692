import { useEffect, useRef } from "react";

type UseEventListener = (
	eventName: string,
	handler: Function,
	element?: any,
	options?: boolean | AddEventListenerOptions,
) => void;

export const useEventListener: UseEventListener = (
	eventName,
	handler,
	element = window,
	options = false,
) => {
	const savedHandler = useRef<any>(null);

	useEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(() => {
		const isSupported = element && element.addEventListener;
		if (!isSupported) return;
		const eventListener = (event: any): void => savedHandler.current(event);
		element.addEventListener(eventName, eventListener, options);
		return () => {
			element.removeEventListener(eventName, eventListener, options);
		};
	}, [eventName, element, options]);
};
