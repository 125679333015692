/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BmuPairPricesData } from './BmuPairPricesData';
import {
    BmuPairPricesDataFromJSON,
    BmuPairPricesDataFromJSONTyped,
    BmuPairPricesDataToJSON,
} from './BmuPairPricesData';

/**
 * 
 * @export
 * @interface BmuPeriodPairsData
 */
export interface BmuPeriodPairsData {
    /**
     * 
     * @type {number}
     * @memberof BmuPeriodPairsData
     */
    period?: number;
    /**
     * 
     * @type {Date}
     * @memberof BmuPeriodPairsData
     */
    date?: Date;
    /**
     * 
     * @type {Array<BmuPairPricesData>}
     * @memberof BmuPeriodPairsData
     */
    pairs?: Array<BmuPairPricesData>;
}

/**
 * Check if a given object implements the BmuPeriodPairsData interface.
 */
export function instanceOfBmuPeriodPairsData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BmuPeriodPairsDataFromJSON(json: any): BmuPeriodPairsData {
    return BmuPeriodPairsDataFromJSONTyped(json, false);
}

export function BmuPeriodPairsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BmuPeriodPairsData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'period': !exists(json, 'period') ? undefined : json['period'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'pairs': !exists(json, 'pairs') ? undefined : ((json['pairs'] as Array<any>).map(BmuPairPricesDataFromJSON)),
    };
}

export function BmuPeriodPairsDataToJSON(value?: BmuPeriodPairsData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'period': value.period,
        'date': value.date === undefined ? undefined : (value.date.toISOString().substr(0,10)),
        'pairs': value.pairs === undefined ? undefined : ((value.pairs as Array<any>).map(BmuPairPricesDataToJSON)),
    };
}

