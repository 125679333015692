/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ForwardTradesPeriodData
 */
export interface ForwardTradesPeriodData {
    /**
     * 
     * @type {string}
     * @memberof ForwardTradesPeriodData
     */
    datePeriod?: string;
    /**
     * 
     * @type {string}
     * @memberof ForwardTradesPeriodData
     */
    minUnflagPrice?: string;
    /**
     * 
     * @type {string}
     * @memberof ForwardTradesPeriodData
     */
    maxUnflagPrice?: string;
    /**
     * 
     * @type {string}
     * @memberof ForwardTradesPeriodData
     */
    minPrice?: string;
    /**
     * 
     * @type {string}
     * @memberof ForwardTradesPeriodData
     */
    maxPrice?: string;
    /**
     * 
     * @type {string}
     * @memberof ForwardTradesPeriodData
     */
    downregVolume?: string;
    /**
     * 
     * @type {string}
     * @memberof ForwardTradesPeriodData
     */
    upregVolume?: string;
    /**
     * 
     * @type {string}
     * @memberof ForwardTradesPeriodData
     */
    netVolume?: string;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ForwardTradesPeriodData
     */
    icTrades?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ForwardTradesPeriodData
     */
    tradeReasons?: { [key: string]: number; };
    /**
     * 
     * @type {boolean}
     * @memberof ForwardTradesPeriodData
     */
    differentVolumes?: boolean;
}

/**
 * Check if a given object implements the ForwardTradesPeriodData interface.
 */
export function instanceOfForwardTradesPeriodData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ForwardTradesPeriodDataFromJSON(json: any): ForwardTradesPeriodData {
    return ForwardTradesPeriodDataFromJSONTyped(json, false);
}

export function ForwardTradesPeriodDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForwardTradesPeriodData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datePeriod': !exists(json, 'datePeriod') ? undefined : json['datePeriod'],
        'minUnflagPrice': !exists(json, 'minUnflagPrice') ? undefined : json['minUnflagPrice'],
        'maxUnflagPrice': !exists(json, 'maxUnflagPrice') ? undefined : json['maxUnflagPrice'],
        'minPrice': !exists(json, 'minPrice') ? undefined : json['minPrice'],
        'maxPrice': !exists(json, 'maxPrice') ? undefined : json['maxPrice'],
        'downregVolume': !exists(json, 'downregVolume') ? undefined : json['downregVolume'],
        'upregVolume': !exists(json, 'upregVolume') ? undefined : json['upregVolume'],
        'netVolume': !exists(json, 'netVolume') ? undefined : json['netVolume'],
        'icTrades': !exists(json, 'icTrades') ? undefined : json['icTrades'],
        'tradeReasons': !exists(json, 'tradeReasons') ? undefined : json['tradeReasons'],
        'differentVolumes': !exists(json, 'differentVolumes') ? undefined : json['differentVolumes'],
    };
}

export function ForwardTradesPeriodDataToJSON(value?: ForwardTradesPeriodData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datePeriod': value.datePeriod,
        'minUnflagPrice': value.minUnflagPrice,
        'maxUnflagPrice': value.maxUnflagPrice,
        'minPrice': value.minPrice,
        'maxPrice': value.maxPrice,
        'downregVolume': value.downregVolume,
        'upregVolume': value.upregVolume,
        'netVolume': value.netVolume,
        'icTrades': value.icTrades,
        'tradeReasons': value.tradeReasons,
        'differentVolumes': value.differentVolumes,
    };
}

