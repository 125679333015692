import "./static/index.css";
import React from "react";
import { createRoot } from "react-dom/client";
import ErrorHandler from "@/handlers/ErrorHandler";
import App from "./components/App/App";
import { setGlobalChartsUseCanvasRenderer } from "@/utils/ChartsUtils";
import { configGlobalDateTimeSettings } from "./utils/DateTimeUtils";

function init(): void {
	ErrorHandler.initErrorsInterceptor();
	configGlobalDateTimeSettings();
	setGlobalChartsUseCanvasRenderer();
	const rootEl = document.getElementById("root");
	if (rootEl) {
		const root = createRoot(rootEl);
		root.render(<App />);
	}
}

if (document.readyState === "interactive") {
	init();
} else {
	document.addEventListener("DOMContentLoaded", init);
}
