import {
	RootPageRouteProps,
	ActivationsPerBMUChartViewRouteProps,
	ActivationsForecastChartViewRouteProps,
	ActivationsChartViewRouteProps,
	NowcastChartViewRouteProps,
	NowcastSummaryViewRouteProps,
	TripsUndergenerationChartRouteProps,
	InterconnectorsChartRouteProps,
	GenerationDemandChartRouteProps,
	GenerationPerTypeChartRouteProps,
	UserMadeDemandChartRouteProps,
	UserMadeWindChartRouteProps,
	BmUnitInfoChartRouteProps,
	NonBmFrRouteProps,
	ForwardTradesProps,
	FpnMelComparisonProps,
	StacksChartProps,
	NotificationsProps,
	ShortTermForecastChartProps,
	PriceComparisonProps,
	WindNSolarChartProps,
	ImbalanceForecastChartProps,
	PhantomModeProps,
	Route404Props,
} from "./RoutesProps";
import type { AppRouteProps } from "@/utils/RouteUtils";

export const AppRoutes: Array<AppRouteProps> = [
	RootPageRouteProps,
	ActivationsPerBMUChartViewRouteProps,
	ActivationsForecastChartViewRouteProps,
	ActivationsChartViewRouteProps,
	NowcastChartViewRouteProps,
	NowcastSummaryViewRouteProps,
	TripsUndergenerationChartRouteProps,
	InterconnectorsChartRouteProps,
	GenerationDemandChartRouteProps,
	GenerationPerTypeChartRouteProps,
	UserMadeDemandChartRouteProps,
	UserMadeWindChartRouteProps,
	BmUnitInfoChartRouteProps,
	NonBmFrRouteProps,
	ForwardTradesProps,
	FpnMelComparisonProps,
	StacksChartProps,
	NotificationsProps,
	ShortTermForecastChartProps,
	PriceComparisonProps,
	WindNSolarChartProps,
	ImbalanceForecastChartProps,
	PhantomModeProps,
	Route404Props,
];
