/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebPriceComparisonPeriodUnit } from './WebPriceComparisonPeriodUnit';
import {
    WebPriceComparisonPeriodUnitFromJSON,
    WebPriceComparisonPeriodUnitFromJSONTyped,
    WebPriceComparisonPeriodUnitToJSON,
} from './WebPriceComparisonPeriodUnit';

/**
 * 
 * @export
 * @interface WebPriceComparisonUnit
 */
export interface WebPriceComparisonUnit {
    /**
     * 
     * @type {string}
     * @memberof WebPriceComparisonUnit
     */
    bmu?: string;
    /**
     * 
     * @type {Array<WebPriceComparisonPeriodUnit>}
     * @memberof WebPriceComparisonUnit
     */
    periods?: Array<WebPriceComparisonPeriodUnit>;
}

/**
 * Check if a given object implements the WebPriceComparisonUnit interface.
 */
export function instanceOfWebPriceComparisonUnit(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebPriceComparisonUnitFromJSON(json: any): WebPriceComparisonUnit {
    return WebPriceComparisonUnitFromJSONTyped(json, false);
}

export function WebPriceComparisonUnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebPriceComparisonUnit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bmu': !exists(json, 'bmu') ? undefined : json['bmu'],
        'periods': !exists(json, 'periods') ? undefined : ((json['periods'] as Array<any>).map(WebPriceComparisonPeriodUnitFromJSON)),
    };
}

export function WebPriceComparisonUnitToJSON(value?: WebPriceComparisonUnit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bmu': value.bmu,
        'periods': value.periods === undefined ? undefined : ((value.periods as Array<any>).map(WebPriceComparisonPeriodUnitToJSON)),
    };
}

