/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebActivationData } from './WebActivationData';
import {
    WebActivationDataFromJSON,
    WebActivationDataFromJSONTyped,
    WebActivationDataToJSON,
} from './WebActivationData';
import type { WebActivationsPeriodData } from './WebActivationsPeriodData';
import {
    WebActivationsPeriodDataFromJSON,
    WebActivationsPeriodDataFromJSONTyped,
    WebActivationsPeriodDataToJSON,
} from './WebActivationsPeriodData';

/**
 * 
 * @export
 * @interface WebActivationsAndPeriodsData
 */
export interface WebActivationsAndPeriodsData {
    /**
     * 
     * @type {Array<WebActivationData>}
     * @memberof WebActivationsAndPeriodsData
     */
    activations?: Array<WebActivationData>;
    /**
     * 
     * @type {Array<WebActivationsPeriodData>}
     * @memberof WebActivationsAndPeriodsData
     */
    periods?: Array<WebActivationsPeriodData>;
}

/**
 * Check if a given object implements the WebActivationsAndPeriodsData interface.
 */
export function instanceOfWebActivationsAndPeriodsData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebActivationsAndPeriodsDataFromJSON(json: any): WebActivationsAndPeriodsData {
    return WebActivationsAndPeriodsDataFromJSONTyped(json, false);
}

export function WebActivationsAndPeriodsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebActivationsAndPeriodsData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activations': !exists(json, 'activations') ? undefined : ((json['activations'] as Array<any>).map(WebActivationDataFromJSON)),
        'periods': !exists(json, 'periods') ? undefined : ((json['periods'] as Array<any>).map(WebActivationsPeriodDataFromJSON)),
    };
}

export function WebActivationsAndPeriodsDataToJSON(value?: WebActivationsAndPeriodsData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activations': value.activations === undefined ? undefined : ((value.activations as Array<any>).map(WebActivationDataToJSON)),
        'periods': value.periods === undefined ? undefined : ((value.periods as Array<any>).map(WebActivationsPeriodDataToJSON)),
    };
}

