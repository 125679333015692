/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebNowcastActivationData } from './WebNowcastActivationData';
import {
    WebNowcastActivationDataFromJSON,
    WebNowcastActivationDataFromJSONTyped,
    WebNowcastActivationDataToJSON,
} from './WebNowcastActivationData';

/**
 * 
 * @export
 * @interface WebNowcastActivationsPerPeriodData
 */
export interface WebNowcastActivationsPerPeriodData {
    /**
     * 
     * @type {number}
     * @memberof WebNowcastActivationsPerPeriodData
     */
    totalBmu?: number;
    /**
     * 
     * @type {Array<WebNowcastActivationData>}
     * @memberof WebNowcastActivationsPerPeriodData
     */
    values?: Array<WebNowcastActivationData>;
}

/**
 * Check if a given object implements the WebNowcastActivationsPerPeriodData interface.
 */
export function instanceOfWebNowcastActivationsPerPeriodData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebNowcastActivationsPerPeriodDataFromJSON(json: any): WebNowcastActivationsPerPeriodData {
    return WebNowcastActivationsPerPeriodDataFromJSONTyped(json, false);
}

export function WebNowcastActivationsPerPeriodDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebNowcastActivationsPerPeriodData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalBmu': !exists(json, 'totalBmu') ? undefined : json['totalBmu'],
        'values': !exists(json, 'values') ? undefined : ((json['values'] as Array<any>).map(WebNowcastActivationDataFromJSON)),
    };
}

export function WebNowcastActivationsPerPeriodDataToJSON(value?: WebNowcastActivationsPerPeriodData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalBmu': value.totalBmu,
        'values': value.values === undefined ? undefined : ((value.values as Array<any>).map(WebNowcastActivationDataToJSON)),
    };
}

