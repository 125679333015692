import React, { useCallback, useLayoutEffect } from "react";
import { useNavigate } from "react-router";
import { setUserDataToStore } from "@/store/appState";
import { useEventListener } from "@/hooks/useEventListener";
import { NOT_AUTHORIZED_EVENT } from "@/utils/Constants";
import { LOGIN_PATH } from "@/utils/SitePaths";

function LoginProcessor(): JSX.Element {
	const navigate = useNavigate();

	useLayoutEffect(() => {
		// load current user data
		setTimeout(() => {
			setUserDataToStore({ isLoading: false, userInfo: {} });
		}, 0);
	}, []);

	const notAuthorizedHandler = useCallback(() => {
		if (!window.location.pathname.includes(LOGIN_PATH)) {
			navigate(LOGIN_PATH);
		}
	}, [navigate]);

	useEventListener(NOT_AUTHORIZED_EVENT, notAuthorizedHandler);

	return <></>;
}

export default LoginProcessor;
