/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MomentGraphData } from './MomentGraphData';
import {
    MomentGraphDataFromJSON,
    MomentGraphDataFromJSONTyped,
    MomentGraphDataToJSON,
} from './MomentGraphData';
import type { WebActivationsForecastNivs } from './WebActivationsForecastNivs';
import {
    WebActivationsForecastNivsFromJSON,
    WebActivationsForecastNivsFromJSONTyped,
    WebActivationsForecastNivsToJSON,
} from './WebActivationsForecastNivs';
import type { WebPeriodNivForecastData } from './WebPeriodNivForecastData';
import {
    WebPeriodNivForecastDataFromJSON,
    WebPeriodNivForecastDataFromJSONTyped,
    WebPeriodNivForecastDataToJSON,
} from './WebPeriodNivForecastData';

/**
 * 
 * @export
 * @interface WebActivationsForecastOutput
 */
export interface WebActivationsForecastOutput {
    /**
     * 
     * @type {{ [key: string]: Array<MomentGraphData>; }}
     * @memberof WebActivationsForecastOutput
     */
    forecasts?: { [key: string]: Array<MomentGraphData>; };
    /**
     * 
     * @type {WebActivationsForecastNivs}
     * @memberof WebActivationsForecastOutput
     */
    pastNivs?: WebActivationsForecastNivs;
    /**
     * 
     * @type {Array<WebPeriodNivForecastData>}
     * @memberof WebActivationsForecastOutput
     */
    nivsPerPeriod?: Array<WebPeriodNivForecastData>;
    /**
     * 
     * @type {string}
     * @memberof WebActivationsForecastOutput
     */
    latestForecast?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WebActivationsForecastOutput
     */
    notAllMels?: boolean;
}

/**
 * Check if a given object implements the WebActivationsForecastOutput interface.
 */
export function instanceOfWebActivationsForecastOutput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebActivationsForecastOutputFromJSON(json: any): WebActivationsForecastOutput {
    return WebActivationsForecastOutputFromJSONTyped(json, false);
}

export function WebActivationsForecastOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebActivationsForecastOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'forecasts': !exists(json, 'forecasts') ? undefined : json['forecasts'],
        'pastNivs': !exists(json, 'pastNivs') ? undefined : WebActivationsForecastNivsFromJSON(json['pastNivs']),
        'nivsPerPeriod': !exists(json, 'nivsPerPeriod') ? undefined : ((json['nivsPerPeriod'] as Array<any>).map(WebPeriodNivForecastDataFromJSON)),
        'latestForecast': !exists(json, 'latestForecast') ? undefined : json['latestForecast'],
        'notAllMels': !exists(json, 'notAllMels') ? undefined : json['notAllMels'],
    };
}

export function WebActivationsForecastOutputToJSON(value?: WebActivationsForecastOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'forecasts': value.forecasts,
        'pastNivs': WebActivationsForecastNivsToJSON(value.pastNivs),
        'nivsPerPeriod': value.nivsPerPeriod === undefined ? undefined : ((value.nivsPerPeriod as Array<any>).map(WebPeriodNivForecastDataToJSON)),
        'latestForecast': value.latestForecast,
        'notAllMels': value.notAllMels,
    };
}

