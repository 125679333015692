import React, { FC, createContext, useState, useMemo } from "react";
import { Helmet as ReactHelmetAsync, HelmetProvider } from "react-helmet-async";
import useEvent from "@/hooks/useEvent";
import type { DefaultComponentWithChildren } from "types/common-app-types";
import { BASE_CABINET_TITLE } from "@/utils/Constants";

export type HelmetContextType = {
	updateTitle: (newTitle: string) => void;
};

export const HelmetContext = createContext({} as HelmetContextType);

const Helmet: FC<DefaultComponentWithChildren> = ({ children }) => {
	const [title, setTitle] = useState(BASE_CABINET_TITLE);

	const updateTitle = useEvent((newTitle: string) => {
		setTitle(newTitle);
	});

	const ctxtValue = useMemo<HelmetContextType>(() => ({ updateTitle }), [updateTitle]);

	return (
		<HelmetProvider>
			<ReactHelmetAsync defaultTitle={BASE_CABINET_TITLE}>
				<title>{title}</title>
				<meta name="description" content={title} />
			</ReactHelmetAsync>
			<HelmetContext.Provider value={ctxtValue}>{children}</HelmetContext.Provider>
		</HelmetProvider>
	);
};

export default Helmet;
