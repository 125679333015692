import styles from "./Spin.module.css";
import React from "react";
import AntdSpin, { SpinProps as AntdSpinProps } from "antd/es/spin";
import type { DefaultComponentWithChildren } from "types/common-app-types";
import { EMPTY_STRING } from "@/utils/Constants";

export type SpinProps = DefaultComponentWithChildren &
	AntdSpinProps & {
		fixedNCentered?: boolean;
	};

function Spin({
	spinning = false,
	fixedNCentered,
	size = "large",
	className = EMPTY_STRING,
	children,
	...rest
}: SpinProps): JSX.Element {
	return (
		<AntdSpin
			className={`${
				fixedNCentered ? styles.spin_fixedNCentered : EMPTY_STRING
			} ${className}`.trimEnd()}
			spinning={spinning}
			size={size}
			{...rest}
		>
			{children}
		</AntdSpin>
	);
}

export default Spin;
