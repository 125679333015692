import { useCallback, useLayoutEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import RestClient from "@/integration/RestClient";
import type { WebHealthCheckData } from "integration-api/models/WebHealthCheckData";
import { FETCH_INTERVAL_VALUE } from "../helpers/utils";
import useDebouncedIsLoading from "@/hooks/useDebouncedIsLoading";
import useAbortRequest from "@/hooks/useAbortRequest";
import useEvent, { UseEventHandlerFunc } from "@/hooks/useEvent";
import { ABORTED_REQUEST, ABORTED_REQUEST_NAME, DEFAULT_IS_LOADING_DELAY } from "@/utils/Constants";

type UseFetchNUpdateDataOutput = {
	fetchNUpdateData: UseEventHandlerFunc;
	showErrorMsg: boolean;
	rawData: WebHealthCheckData[];
	isLoadingDebounced: boolean;
};

const useFetchNUpdateData = (): UseFetchNUpdateDataOutput => {
	const fetchIntervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
	const [showErrorMsg, setShowErrorMsg] = useState<boolean>(false);
	const [rawData, setRawData] = useState<WebHealthCheckData[]>([]);

	const { isLoadingDebounced, setIsLoading } = useDebouncedIsLoading(
		true,
		DEFAULT_IS_LOADING_DELAY,
	);

	const clearFetchInterval = useCallback(() => {
		if (fetchIntervalRef.current) {
			clearInterval(fetchIntervalRef.current);
			fetchIntervalRef.current = null;
		}
	}, []);

	const abortFn = useCallback((requestId: string) => {
		RestClient.healthCheck.abortRequestById(requestId);
	}, []);

	const { requestIdRef, abortRequest } = useAbortRequest(abortFn);

	const fetchNUpdateData = useEvent(async () => {
		await abortRequest();
		clearFetchInterval();
		setIsLoading(true);
		const requestId = uuidv4();
		requestIdRef.current = requestId;
		return RestClient.healthCheck
			.withOptions({ requestId })
			.getResourcesLastUpdates()
			.then((response) => {
				setShowErrorMsg(false);
				setRawData(response);

				fetchIntervalRef.current = setInterval(() => {
					fetchNUpdateData();
				}, FETCH_INTERVAL_VALUE);
			})
			.catch((err: any) => {
				if (err?.message !== ABORTED_REQUEST && err?.name !== ABORTED_REQUEST_NAME) {
					console.error(err);
					setShowErrorMsg(true);
				}
			})
			.finally(() => {
				setIsLoading(false);
				requestIdRef.current = null;
			});
	});

	useLayoutEffect(() => {
		fetchNUpdateData();

		return () => {
			abortRequest();
			clearFetchInterval();
		};
	}, []);

	return {
		rawData,
		isLoadingDebounced,
		fetchNUpdateData,
		showErrorMsg,
	};
};

export default useFetchNUpdateData;
