import styles from "./PageLoadingSkeleton.module.css";
import React from "react";
import Skeleton from "antd/es/skeleton/Skeleton";

function PageLoadingSkeleton(): JSX.Element {
	return (
		<Skeleton
			className={styles.pageLoadingSkeleton}
			title={{ width: "100%" }}
			loading
			active
			paragraph={{ rows: 8, width: "100%" }}
		/>
	);
}

export default PageLoadingSkeleton;
