/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebUserMadeDemandInput
 */
export interface WebUserMadeDemandInput {
    /**
     * 
     * @type {number}
     * @memberof WebUserMadeDemandInput
     */
    lag?: number;
    /**
     * 
     * @type {number}
     * @memberof WebUserMadeDemandInput
     */
    lead?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebUserMadeDemandInput
     */
    dates?: Array<string>;
}

/**
 * Check if a given object implements the WebUserMadeDemandInput interface.
 */
export function instanceOfWebUserMadeDemandInput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebUserMadeDemandInputFromJSON(json: any): WebUserMadeDemandInput {
    return WebUserMadeDemandInputFromJSONTyped(json, false);
}

export function WebUserMadeDemandInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebUserMadeDemandInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lag': !exists(json, 'lag') ? undefined : json['lag'],
        'lead': !exists(json, 'lead') ? undefined : json['lead'],
        'dates': !exists(json, 'dates') ? undefined : json['dates'],
    };
}

export function WebUserMadeDemandInputToJSON(value?: WebUserMadeDemandInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lag': value.lag,
        'lead': value.lead,
        'dates': value.dates,
    };
}

