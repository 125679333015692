/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorMessage,
  PhantomModeUserConfig,
  WebPhantomModeAvailable,
  WebPhantomModeBmus,
} from '../models';
import {
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
    PhantomModeUserConfigFromJSON,
    PhantomModeUserConfigToJSON,
    WebPhantomModeAvailableFromJSON,
    WebPhantomModeAvailableToJSON,
    WebPhantomModeBmusFromJSON,
    WebPhantomModeBmusToJSON,
} from '../models';

export interface PhantomModeRestHandlerApiPostDisabledBmusRequest {
    requestBody: Array<string>;
}

export interface PhantomModeRestHandlerApiSubmitPhantomModeRequest {
    phantomModeUserConfig: PhantomModeUserConfig;
}

/**
 * 
 */
export class PhantomModeRestHandlerApi extends runtime.BaseAPI {

    /**
     */
    async getUserConfigRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PhantomModeUserConfig>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/phantom-mode/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PhantomModeUserConfigFromJSON(jsonValue));
    }

    /**
     */
    async getUserConfig(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PhantomModeUserConfig> {
        const response = await this.getUserConfigRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getUsersBmusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebPhantomModeBmus>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/phantom-mode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebPhantomModeBmusFromJSON(jsonValue));
    }

    /**
     */
    async getUsersBmus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebPhantomModeBmus> {
        const response = await this.getUsersBmusRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async isAvailableRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebPhantomModeAvailable>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/phantom-mode/available`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebPhantomModeAvailableFromJSON(jsonValue));
    }

    /**
     */
    async isAvailable(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebPhantomModeAvailable> {
        const response = await this.isAvailableRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async postDisabledBmusRaw(requestParameters: PhantomModeRestHandlerApiPostDisabledBmusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling postDisabledBmus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/phantom-mode/disable-bmus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postDisabledBmus(requestParameters: PhantomModeRestHandlerApiPostDisabledBmusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postDisabledBmusRaw(requestParameters, initOverrides);
    }

    /**
     */
    async submitPhantomModeRaw(requestParameters: PhantomModeRestHandlerApiSubmitPhantomModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebPhantomModeBmus>> {
        if (requestParameters.phantomModeUserConfig === null || requestParameters.phantomModeUserConfig === undefined) {
            throw new runtime.RequiredError('phantomModeUserConfig','Required parameter requestParameters.phantomModeUserConfig was null or undefined when calling submitPhantomMode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/phantom-mode/config`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PhantomModeUserConfigToJSON(requestParameters.phantomModeUserConfig),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebPhantomModeBmusFromJSON(jsonValue));
    }

    /**
     */
    async submitPhantomMode(requestParameters: PhantomModeRestHandlerApiSubmitPhantomModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebPhantomModeBmus> {
        const response = await this.submitPhantomModeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
