/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FastReserveActsData
 */
export interface FastReserveActsData {
    /**
     * 
     * @type {number}
     * @memberof FastReserveActsData
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FastReserveActsData
     */
    serviceType?: string;
    /**
     * 
     * @type {string}
     * @memberof FastReserveActsData
     */
    deliveryStart?: string;
    /**
     * 
     * @type {number}
     * @memberof FastReserveActsData
     */
    period?: number;
    /**
     * 
     * @type {number}
     * @memberof FastReserveActsData
     */
    ngPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof FastReserveActsData
     */
    elexonPrice?: string;
    /**
     * 
     * @type {number}
     * @memberof FastReserveActsData
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof FastReserveActsData
     */
    forced?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FastReserveActsData
     */
    running?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FastReserveActsData
     */
    mwh?: number;
}

/**
 * Check if a given object implements the FastReserveActsData interface.
 */
export function instanceOfFastReserveActsData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FastReserveActsDataFromJSON(json: any): FastReserveActsData {
    return FastReserveActsDataFromJSONTyped(json, false);
}

export function FastReserveActsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FastReserveActsData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'serviceType': !exists(json, 'serviceType') ? undefined : json['serviceType'],
        'deliveryStart': !exists(json, 'deliveryStart') ? undefined : json['deliveryStart'],
        'period': !exists(json, 'period') ? undefined : json['period'],
        'ngPrice': !exists(json, 'ngPrice') ? undefined : json['ngPrice'],
        'elexonPrice': !exists(json, 'elexonPrice') ? undefined : json['elexonPrice'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'forced': !exists(json, 'forced') ? undefined : json['forced'],
        'running': !exists(json, 'running') ? undefined : json['running'],
        'mwh': !exists(json, 'mwh') ? undefined : json['mwh'],
    };
}

export function FastReserveActsDataToJSON(value?: FastReserveActsData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'serviceType': value.serviceType,
        'deliveryStart': value.deliveryStart,
        'period': value.period,
        'ngPrice': value.ngPrice,
        'elexonPrice': value.elexonPrice,
        'price': value.price,
        'forced': value.forced,
        'running': value.running,
        'mwh': value.mwh,
    };
}

