/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebNowcastFilters
 */
export interface WebNowcastFilters {
    /**
     * 
     * @type {number}
     * @memberof WebNowcastFilters
     */
    lowMainPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof WebNowcastFilters
     */
    highMainPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof WebNowcastFilters
     */
    lowActivatedPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof WebNowcastFilters
     */
    highActivatedPrice?: number;
}

/**
 * Check if a given object implements the WebNowcastFilters interface.
 */
export function instanceOfWebNowcastFilters(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebNowcastFiltersFromJSON(json: any): WebNowcastFilters {
    return WebNowcastFiltersFromJSONTyped(json, false);
}

export function WebNowcastFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebNowcastFilters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lowMainPrice': !exists(json, 'lowMainPrice') ? undefined : json['lowMainPrice'],
        'highMainPrice': !exists(json, 'highMainPrice') ? undefined : json['highMainPrice'],
        'lowActivatedPrice': !exists(json, 'lowActivatedPrice') ? undefined : json['lowActivatedPrice'],
        'highActivatedPrice': !exists(json, 'highActivatedPrice') ? undefined : json['highActivatedPrice'],
    };
}

export function WebNowcastFiltersToJSON(value?: WebNowcastFilters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lowMainPrice': value.lowMainPrice,
        'highMainPrice': value.highMainPrice,
        'lowActivatedPrice': value.lowActivatedPrice,
        'highActivatedPrice': value.highActivatedPrice,
    };
}

