/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WebNivAtMinute } from './WebNivAtMinute';
import {
    WebNivAtMinuteFromJSON,
    WebNivAtMinuteFromJSONTyped,
    WebNivAtMinuteToJSON,
} from './WebNivAtMinute';
import type { WebPeriodNivData } from './WebPeriodNivData';
import {
    WebPeriodNivDataFromJSON,
    WebPeriodNivDataFromJSONTyped,
    WebPeriodNivDataToJSON,
} from './WebPeriodNivData';

/**
 * 
 * @export
 * @interface WebNivsData
 */
export interface WebNivsData {
    /**
     * 
     * @type {Date}
     * @memberof WebNivsData
     */
    lastActivation?: Date;
    /**
     * 
     * @type {Array<WebPeriodNivData>}
     * @memberof WebNivsData
     */
    periodsNiv?: Array<WebPeriodNivData>;
    /**
     * 
     * @type {Array<WebNivAtMinute>}
     * @memberof WebNivsData
     */
    mainSeries?: Array<WebNivAtMinute>;
    /**
     * 
     * @type {Array<WebNivAtMinute>}
     * @memberof WebNivsData
     */
    compensationSeries?: Array<WebNivAtMinute>;
    /**
     * 
     * @type {Array<WebNivAtMinute>}
     * @memberof WebNivsData
     */
    additionSeries?: Array<WebNivAtMinute>;
}

/**
 * Check if a given object implements the WebNivsData interface.
 */
export function instanceOfWebNivsData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebNivsDataFromJSON(json: any): WebNivsData {
    return WebNivsDataFromJSONTyped(json, false);
}

export function WebNivsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebNivsData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastActivation': !exists(json, 'lastActivation') ? undefined : (new Date(json['lastActivation'])),
        'periodsNiv': !exists(json, 'periodsNiv') ? undefined : ((json['periodsNiv'] as Array<any>).map(WebPeriodNivDataFromJSON)),
        'mainSeries': !exists(json, 'mainSeries') ? undefined : ((json['mainSeries'] as Array<any>).map(WebNivAtMinuteFromJSON)),
        'compensationSeries': !exists(json, 'compensationSeries') ? undefined : ((json['compensationSeries'] as Array<any>).map(WebNivAtMinuteFromJSON)),
        'additionSeries': !exists(json, 'additionSeries') ? undefined : ((json['additionSeries'] as Array<any>).map(WebNivAtMinuteFromJSON)),
    };
}

export function WebNivsDataToJSON(value?: WebNivsData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastActivation': value.lastActivation === undefined ? undefined : (value.lastActivation.toISOString()),
        'periodsNiv': value.periodsNiv === undefined ? undefined : ((value.periodsNiv as Array<any>).map(WebPeriodNivDataToJSON)),
        'mainSeries': value.mainSeries === undefined ? undefined : ((value.mainSeries as Array<any>).map(WebNivAtMinuteToJSON)),
        'compensationSeries': value.compensationSeries === undefined ? undefined : ((value.compensationSeries as Array<any>).map(WebNivAtMinuteToJSON)),
        'additionSeries': value.additionSeries === undefined ? undefined : ((value.additionSeries as Array<any>).map(WebNivAtMinuteToJSON)),
    };
}

