/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorMessage,
  WebGenerationDemandData,
  WebGenerationDemandInput,
} from '../models';
import {
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
    WebGenerationDemandDataFromJSON,
    WebGenerationDemandDataToJSON,
    WebGenerationDemandInputFromJSON,
    WebGenerationDemandInputToJSON,
} from '../models';

export interface GenerationDemandRestHandlerApiGetAllGenerationDemandRequest {
    webGenerationDemandInput: WebGenerationDemandInput;
}

/**
 * 
 */
export class GenerationDemandRestHandlerApi extends runtime.BaseAPI {

    /**
     */
    async getAllGenerationDemandRaw(requestParameters: GenerationDemandRestHandlerApiGetAllGenerationDemandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebGenerationDemandData>> {
        if (requestParameters.webGenerationDemandInput === null || requestParameters.webGenerationDemandInput === undefined) {
            throw new runtime.RequiredError('webGenerationDemandInput','Required parameter requestParameters.webGenerationDemandInput was null or undefined when calling getAllGenerationDemand.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/generation-demand`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WebGenerationDemandInputToJSON(requestParameters.webGenerationDemandInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebGenerationDemandDataFromJSON(jsonValue));
    }

    /**
     */
    async getAllGenerationDemand(requestParameters: GenerationDemandRestHandlerApiGetAllGenerationDemandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebGenerationDemandData> {
        const response = await this.getAllGenerationDemandRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
