/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebSumActivationsPerPrice
 */
export interface WebSumActivationsPerPrice {
    /**
     * 
     * @type {number}
     * @memberof WebSumActivationsPerPrice
     */
    startValue?: number;
    /**
     * 
     * @type {number}
     * @memberof WebSumActivationsPerPrice
     */
    endValue?: number;
    /**
     * 
     * @type {number}
     * @memberof WebSumActivationsPerPrice
     */
    price?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WebSumActivationsPerPrice
     */
    unprice?: boolean;
}

/**
 * Check if a given object implements the WebSumActivationsPerPrice interface.
 */
export function instanceOfWebSumActivationsPerPrice(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebSumActivationsPerPriceFromJSON(json: any): WebSumActivationsPerPrice {
    return WebSumActivationsPerPriceFromJSONTyped(json, false);
}

export function WebSumActivationsPerPriceFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebSumActivationsPerPrice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startValue': !exists(json, 'startValue') ? undefined : json['startValue'],
        'endValue': !exists(json, 'endValue') ? undefined : json['endValue'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'unprice': !exists(json, 'unprice') ? undefined : json['unprice'],
    };
}

export function WebSumActivationsPerPriceToJSON(value?: WebSumActivationsPerPrice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startValue': value.startValue,
        'endValue': value.endValue,
        'price': value.price,
        'unprice': value.unprice,
    };
}

