/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MomentGraphData } from './MomentGraphData';
import {
    MomentGraphDataFromJSON,
    MomentGraphDataFromJSONTyped,
    MomentGraphDataToJSON,
} from './MomentGraphData';
import type { WebInteractiveData } from './WebInteractiveData';
import {
    WebInteractiveDataFromJSON,
    WebInteractiveDataFromJSONTyped,
    WebInteractiveDataToJSON,
} from './WebInteractiveData';

/**
 * 
 * @export
 * @interface WebUserMadeDemandOutput
 */
export interface WebUserMadeDemandOutput {
    /**
     * 
     * @type {Array<MomentGraphData>}
     * @memberof WebUserMadeDemandOutput
     */
    baseDemand?: Array<MomentGraphData>;
    /**
     * 
     * @type {WebInteractiveData}
     * @memberof WebUserMadeDemandOutput
     */
    interactiveData?: WebInteractiveData;
    /**
     * 
     * @type {{ [key: string]: Array<MomentGraphData>; }}
     * @memberof WebUserMadeDemandOutput
     */
    rsd?: { [key: string]: Array<MomentGraphData>; };
}

/**
 * Check if a given object implements the WebUserMadeDemandOutput interface.
 */
export function instanceOfWebUserMadeDemandOutput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebUserMadeDemandOutputFromJSON(json: any): WebUserMadeDemandOutput {
    return WebUserMadeDemandOutputFromJSONTyped(json, false);
}

export function WebUserMadeDemandOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebUserMadeDemandOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'baseDemand': !exists(json, 'baseDemand') ? undefined : ((json['baseDemand'] as Array<any>).map(MomentGraphDataFromJSON)),
        'interactiveData': !exists(json, 'interactiveData') ? undefined : WebInteractiveDataFromJSON(json['interactiveData']),
        'rsd': !exists(json, 'rsd') ? undefined : json['rsd'],
    };
}

export function WebUserMadeDemandOutputToJSON(value?: WebUserMadeDemandOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'baseDemand': value.baseDemand === undefined ? undefined : ((value.baseDemand as Array<any>).map(MomentGraphDataToJSON)),
        'interactiveData': WebInteractiveDataToJSON(value.interactiveData),
        'rsd': value.rsd,
    };
}

