/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorMessage,
  WebActivationsAndPeriodsData,
  WebActivationsDebugData,
  WebExtendedBmusLists,
} from '../models';
import {
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
    WebActivationsAndPeriodsDataFromJSON,
    WebActivationsAndPeriodsDataToJSON,
    WebActivationsDebugDataFromJSON,
    WebActivationsDebugDataToJSON,
    WebExtendedBmusListsFromJSON,
    WebExtendedBmusListsToJSON,
} from '../models';

export interface ActivationsPerBmuRestHandlerApiDebugActivationsPerBmuRequest {
    bmu: string;
    time: string;
    dataSource: DebugActivationsPerBmuDataSourceEnum;
}

export interface ActivationsPerBmuRestHandlerApiGetAllActivationsPerBmuRequest {
    lag?: number;
    lead?: number;
    extendedMode?: boolean;
}

export interface ActivationsPerBmuRestHandlerApiSaveUserBmusRequest {
    requestBody: Array<string>;
}

/**
 * 
 */
export class ActivationsPerBmuRestHandlerApi extends runtime.BaseAPI {

    /**
     */
    async debugActivationsPerBmuRaw(requestParameters: ActivationsPerBmuRestHandlerApiDebugActivationsPerBmuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebActivationsDebugData>> {
        if (requestParameters.bmu === null || requestParameters.bmu === undefined) {
            throw new runtime.RequiredError('bmu','Required parameter requestParameters.bmu was null or undefined when calling debugActivationsPerBmu.');
        }

        if (requestParameters.time === null || requestParameters.time === undefined) {
            throw new runtime.RequiredError('time','Required parameter requestParameters.time was null or undefined when calling debugActivationsPerBmu.');
        }

        if (requestParameters.dataSource === null || requestParameters.dataSource === undefined) {
            throw new runtime.RequiredError('dataSource','Required parameter requestParameters.dataSource was null or undefined when calling debugActivationsPerBmu.');
        }

        const queryParameters: any = {};

        if (requestParameters.bmu !== undefined) {
            queryParameters['bmu'] = requestParameters.bmu;
        }

        if (requestParameters.time !== undefined) {
            queryParameters['time'] = requestParameters.time;
        }

        if (requestParameters.dataSource !== undefined) {
            queryParameters['dataSource'] = requestParameters.dataSource;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/activations-per-bmu/debug`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebActivationsDebugDataFromJSON(jsonValue));
    }

    /**
     */
    async debugActivationsPerBmu(requestParameters: ActivationsPerBmuRestHandlerApiDebugActivationsPerBmuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebActivationsDebugData> {
        const response = await this.debugActivationsPerBmuRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAllActivationsPerBmuRaw(requestParameters: ActivationsPerBmuRestHandlerApiGetAllActivationsPerBmuRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebActivationsAndPeriodsData>> {
        const queryParameters: any = {};

        if (requestParameters.lag !== undefined) {
            queryParameters['lag'] = requestParameters.lag;
        }

        if (requestParameters.lead !== undefined) {
            queryParameters['lead'] = requestParameters.lead;
        }

        if (requestParameters.extendedMode !== undefined) {
            queryParameters['extendedMode'] = requestParameters.extendedMode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/activations-per-bmu/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebActivationsAndPeriodsDataFromJSON(jsonValue));
    }

    /**
     */
    async getAllActivationsPerBmu(requestParameters: ActivationsPerBmuRestHandlerApiGetAllActivationsPerBmuRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebActivationsAndPeriodsData> {
        const response = await this.getAllActivationsPerBmuRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getExtendedBmusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebExtendedBmusLists>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/activations-per-bmu/extendedBmus`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebExtendedBmusListsFromJSON(jsonValue));
    }

    /**
     */
    async getExtendedBmus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebExtendedBmusLists> {
        const response = await this.getExtendedBmusRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async saveUserBmusRaw(requestParameters: ActivationsPerBmuRestHandlerApiSaveUserBmusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling saveUserBmus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/activations-per-bmu/extendedBmus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveUserBmus(requestParameters: ActivationsPerBmuRestHandlerApiSaveUserBmusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveUserBmusRaw(requestParameters, initOverrides);
    }

}

/**
 * @export
 */
export const DebugActivationsPerBmuDataSourceEnum = {
    Bmrs: 'BMRS',
    Iris: 'IRIS'
} as const;
export type DebugActivationsPerBmuDataSourceEnum = typeof DebugActivationsPerBmuDataSourceEnum[keyof typeof DebugActivationsPerBmuDataSourceEnum];
