/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorMessage,
  WebShortTermFcOutput,
} from '../models';
import {
    ErrorMessageFromJSON,
    ErrorMessageToJSON,
    WebShortTermFcOutputFromJSON,
    WebShortTermFcOutputToJSON,
} from '../models';

export interface ShortTermFcRestHandlerApiGetForecastsRequest {
    lag: number;
    sp: number;
    dateStr: string;
}

/**
 * 
 */
export class ShortTermFcRestHandlerApi extends runtime.BaseAPI {

    /**
     */
    async getForecastsRaw(requestParameters: ShortTermFcRestHandlerApiGetForecastsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebShortTermFcOutput>> {
        if (requestParameters.lag === null || requestParameters.lag === undefined) {
            throw new runtime.RequiredError('lag','Required parameter requestParameters.lag was null or undefined when calling getForecasts.');
        }

        if (requestParameters.sp === null || requestParameters.sp === undefined) {
            throw new runtime.RequiredError('sp','Required parameter requestParameters.sp was null or undefined when calling getForecasts.');
        }

        if (requestParameters.dateStr === null || requestParameters.dateStr === undefined) {
            throw new runtime.RequiredError('dateStr','Required parameter requestParameters.dateStr was null or undefined when calling getForecasts.');
        }

        const queryParameters: any = {};

        if (requestParameters.lag !== undefined) {
            queryParameters['lag'] = requestParameters.lag;
        }

        if (requestParameters.sp !== undefined) {
            queryParameters['sp'] = requestParameters.sp;
        }

        if (requestParameters.dateStr !== undefined) {
            queryParameters['dateStr'] = requestParameters.dateStr;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/short-term-fc`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebShortTermFcOutputFromJSON(jsonValue));
    }

    /**
     */
    async getForecasts(requestParameters: ShortTermFcRestHandlerApiGetForecastsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebShortTermFcOutput> {
        const response = await this.getForecastsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
