class NumbersUtils {
	/*
	 * Rounding a number to the nearest multiple of a given "target" number.
	 *
	 * roundTo = "up"
	 * 1) num = 35, target = 50 -> res = 50
	 * 2) num = 50, target = 50 -> res = 50
	 * 3) num = 80, target = 50 -> res = 100
	 *
	 * roundTo = "down"
	 * 1) num = 35, target = 50 -> res = 0
	 * 2) num = 50, target = 50 -> res = 50
	 * 3) num = 80, target = 50 -> res = 50
	 * */
	static roundingToMultiples(num: number, target: number, roundTo: "up" | "down"): number {
		if (roundTo === "up") {
			return num % target ? num + target - (num % target) : num;
		}

		return num % target ? num - (num % target) : num;
	}

	/*
	 * Rounding a number to the nearest multiple of a given "target" number.
	 *
	 * roundTo = "up"
	 * 2) num = 50, target = 50 -> res = 100
	 * */
	static safeRoundingToMultiples(num: number, target: number, roundTo: "up" | "down"): number {
		const rounded = this.roundingToMultiples(num, target, roundTo);

		if (rounded === num && roundTo === "up") {
			return rounded + target;
		}

		return rounded;
	}
}

export default NumbersUtils;
