import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Formatter from "./Formatter";
import { DATE_FORMAT, DEFAULT_TZ, ZERO } from "./Constants";
import NumbersUtils from "@/utils/NumbersUtils";

export function configGlobalDateTimeSettings(): void {
	dayjs.extend(utc);
	dayjs.extend(timezone);
	dayjs.locale("en");
	dayjs.tz.setDefault(DEFAULT_TZ);
	/*
		NOTE: dayjs' instance will return wrong timezone if we'll call instance.toDate():
		instance will have a correct time zone (Europe/London) when it's in dayjs format but
		will return js date in user's timezone.
	*/
}

export const getDateStrWithGMTPlusOne = (date: Date = new Date()): string => {
	return Formatter.formatDateToISOStrWithoutSeconds(date);
};

export const roundTimeTo = (
	value: number,
	to: number,
	method: "floor" | "round" | "ceil" = "ceil",
): number => {
	return Math[method](+value / +to) * +to;
};

export const roundDateTimeByDuration = (
	date: Date,
	duration: number,
	method: "floor" | "round" | "ceil" = "ceil",
): Dayjs => {
	return dayjs(Math[method](+date / +duration) * +duration);
};

export const getDateWithGMTPlusOne = (date: Date = new Date()): Date => {
	date.setHours(date.getHours() + 1); // Add 1 hour for GMT+1
	return date;
};

export function getDateStrInGMTTimeZone(
	date: Date = new Date(),
	withSeconds: boolean = false,
): string {
	if (withSeconds) {
		return Formatter.formatDateISOStrWithSeconds(dayjs(date).tz().format());
	}

	return Formatter.formatDateISOStrWithoutSeconds(dayjs(date).tz().format());
}

export const isCurrentDay = (dateString?: string): boolean => {
	const dataDate = dayjs(dateString);
	const currentDate = dayjs().tz();

	return dataDate.format(DATE_FORMAT) === currentDate.format(DATE_FORMAT);
};

export type CheckPointType = "hour" | "minute" | "second" | "millisecond";

/*
 * We reduce time to the nearest multiple.
 * For example, if we want to round the time to the nearest 30 minutes:
 *
 * roundTo: "up"
 * "12:23:34.123" -> "12:30:00.000"
 * "12:30:34.123" -> "13:00:00.000"
 * "12:55:34.123" -> "13:00:00.000"
 *
 * roundTo: "down"
 * "12:23:34.123" -> "12:00:00.000"
 * "12:30:34.123" -> "12:30:00.000"
 * "12:55:34.123" -> "13:30:00.000"
 * */
export const getTimeRoundedToMultiples = (
	time: Dayjs,
	{
		timeCheckStep,
		checkPointType,
		roundTo = "up",
		format,
	}: {
		timeCheckStep: number;
		checkPointType: CheckPointType;
		roundTo?: "up" | "down";
		format?: string;
	},
): string => {
	let targetTime: Dayjs | undefined;

	switch (checkPointType) {
		case "millisecond": {
			const ms = time.get("millisecond");
			targetTime = time.set(
				"millisecond",
				NumbersUtils.safeRoundingToMultiples(ms, timeCheckStep, roundTo),
			);
			break;
		}
		case "second": {
			const sec = time.get("second");
			targetTime = time
				.set("second", NumbersUtils.safeRoundingToMultiples(sec, timeCheckStep, roundTo))
				.set("millisecond", ZERO);
			break;
		}
		case "minute": {
			const min = time.get("minute");
			targetTime = time
				.set("minute", NumbersUtils.safeRoundingToMultiples(min, timeCheckStep, roundTo))
				.set("second", ZERO)
				.set("millisecond", ZERO);
			break;
		}
		case "hour": {
			const hour = time.get("hour");
			targetTime = time
				.set("hour", NumbersUtils.safeRoundingToMultiples(hour, timeCheckStep, roundTo))
				.set("minute", ZERO)
				.set("second", ZERO)
				.set("millisecond", ZERO);
			break;
		}
		default: {
			const ms = time.get("millisecond");
			targetTime = time.set(
				"millisecond",
				NumbersUtils.safeRoundingToMultiples(ms, timeCheckStep, roundTo),
			);
		}
	}

	return targetTime.format(format);
};
