/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebNowcastHealthCheck
 */
export interface WebNowcastHealthCheck {
    /**
     * 
     * @type {string}
     * @memberof WebNowcastHealthCheck
     */
    lastFrequency?: string;
    /**
     * 
     * @type {string}
     * @memberof WebNowcastHealthCheck
     */
    latestAct?: string;
    /**
     * 
     * @type {string}
     * @memberof WebNowcastHealthCheck
     */
    latestNowcast?: string;
}

/**
 * Check if a given object implements the WebNowcastHealthCheck interface.
 */
export function instanceOfWebNowcastHealthCheck(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebNowcastHealthCheckFromJSON(json: any): WebNowcastHealthCheck {
    return WebNowcastHealthCheckFromJSONTyped(json, false);
}

export function WebNowcastHealthCheckFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebNowcastHealthCheck {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastFrequency': !exists(json, 'lastFrequency') ? undefined : json['lastFrequency'],
        'latestAct': !exists(json, 'latestAct') ? undefined : json['latestAct'],
        'latestNowcast': !exists(json, 'latestNowcast') ? undefined : json['latestNowcast'],
    };
}

export function WebNowcastHealthCheckToJSON(value?: WebNowcastHealthCheck | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastFrequency': value.lastFrequency,
        'latestAct': value.latestAct,
        'latestNowcast': value.latestNowcast,
    };
}

