/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebPriceComparisonPeriodUnit
 */
export interface WebPriceComparisonPeriodUnit {
    /**
     * 
     * @type {Date}
     * @memberof WebPriceComparisonPeriodUnit
     */
    periodStart?: Date;
    /**
     * 
     * @type {number}
     * @memberof WebPriceComparisonPeriodUnit
     */
    price?: number;
}

/**
 * Check if a given object implements the WebPriceComparisonPeriodUnit interface.
 */
export function instanceOfWebPriceComparisonPeriodUnit(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebPriceComparisonPeriodUnitFromJSON(json: any): WebPriceComparisonPeriodUnit {
    return WebPriceComparisonPeriodUnitFromJSONTyped(json, false);
}

export function WebPriceComparisonPeriodUnitFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebPriceComparisonPeriodUnit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'periodStart': !exists(json, 'periodStart') ? undefined : (new Date(json['periodStart'])),
        'price': !exists(json, 'price') ? undefined : json['price'],
    };
}

export function WebPriceComparisonPeriodUnitToJSON(value?: WebPriceComparisonPeriodUnit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'periodStart': value.periodStart === undefined ? undefined : (value.periodStart.toISOString()),
        'price': value.price,
    };
}

