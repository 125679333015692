/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StacksOutputGraphData
 */
export interface StacksOutputGraphData {
    /**
     * 
     * @type {number}
     * @memberof StacksOutputGraphData
     */
    startValue?: number;
    /**
     * 
     * @type {number}
     * @memberof StacksOutputGraphData
     */
    endValue?: number;
    /**
     * 
     * @type {number}
     * @memberof StacksOutputGraphData
     */
    price?: number;
}

/**
 * Check if a given object implements the StacksOutputGraphData interface.
 */
export function instanceOfStacksOutputGraphData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StacksOutputGraphDataFromJSON(json: any): StacksOutputGraphData {
    return StacksOutputGraphDataFromJSONTyped(json, false);
}

export function StacksOutputGraphDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): StacksOutputGraphData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startValue': !exists(json, 'startValue') ? undefined : json['startValue'],
        'endValue': !exists(json, 'endValue') ? undefined : json['endValue'],
        'price': !exists(json, 'price') ? undefined : json['price'],
    };
}

export function StacksOutputGraphDataToJSON(value?: StacksOutputGraphData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startValue': value.startValue,
        'endValue': value.endValue,
        'price': value.price,
    };
}

