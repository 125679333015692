import styles from "./SystemErrorNotification.module.css";
import React, { FC, useState } from "react";
import Button from "antd/es/button";
import type { DefaultEmptyVoidFunc } from "types/common-app-types";

interface ISystemErrorNotificationProps {
	message: string;
	onClose?: DefaultEmptyVoidFunc;
}

const SystemErrorNotification: FC<ISystemErrorNotificationProps> = ({ message, onClose }) => {
	const [detailsExpanded, setDetailsExpanded] = useState(false);

	return (
		<div>
			{detailsExpanded ? (
				<textarea className={styles.textarea} disabled value={message}></textarea>
			) : null}
			<div className={styles.btnsContainer}>
				<Button onClick={() => setDetailsExpanded(!detailsExpanded)}>
					{detailsExpanded ? "Hide details" : "Show details"}
				</Button>
				<Button onClick={onClose}>OK</Button>
			</div>
		</div>
	);
};

export default SystemErrorNotification;
