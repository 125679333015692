/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BmuPairPricesData
 */
export interface BmuPairPricesData {
    /**
     * 
     * @type {number}
     * @memberof BmuPairPricesData
     */
    pair?: number;
    /**
     * 
     * @type {number}
     * @memberof BmuPairPricesData
     */
    sellPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof BmuPairPricesData
     */
    buyPrice?: number;
}

/**
 * Check if a given object implements the BmuPairPricesData interface.
 */
export function instanceOfBmuPairPricesData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BmuPairPricesDataFromJSON(json: any): BmuPairPricesData {
    return BmuPairPricesDataFromJSONTyped(json, false);
}

export function BmuPairPricesDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BmuPairPricesData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pair': !exists(json, 'pair') ? undefined : json['pair'],
        'sellPrice': !exists(json, 'sellPrice') ? undefined : json['sellPrice'],
        'buyPrice': !exists(json, 'buyPrice') ? undefined : json['buyPrice'],
    };
}

export function BmuPairPricesDataToJSON(value?: BmuPairPricesData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pair': value.pair,
        'sellPrice': value.sellPrice,
        'buyPrice': value.buyPrice,
    };
}

